<template>
  <div class="body_box">
    <div class="img_box">
      <img src="../../assets/img/fei.png" alt="" />
    </div>
    <h1 class="title">密码重置成功</h1>
    <p class="con">下次请使用新密码进行登录</p>
    <div style="margin-top: 50px;">
      <van-button @click="log" block type="info" native-type="submit">
        返回登录
      </van-button>
    </div>

    <div class="logo">
      <img src="../../assets/img/indexLogo.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //方法集合
  methods: {
    log() {
      this.$router.push({ path: "/login" });
    }
  },
  created() {}
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.body_box {
  height: 100vh;
  background: url("../../assets/img/beijin.png") no-repeat center;
  background-size: 100%;
  overflow: hidden;
  padding: 0 47px;
  .title {
    color: #fff;
    // font-size: 60px;
    font-weight: 100;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  .con {
    color: #a9acbb;
    font-size: 14px;
    color: #fff;
    text-align: center;
  }
  .logo {
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 180px;
      height: 40px;
    }
  }
  .van-button {
    border-radius: 6px;
  }
  .img_box {
    margin-top: 135px;
    text-align: center;
    img {
      width: 200px;
      height: 200px;
    }
  }
}
</style>
