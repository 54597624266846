<template>
  <div class="body_box">
    <div class="title">
      <h1>企业注册</h1>
      <h2 @click="geren">咨询登记</h2>
    </div>

    <van-form @submit="onSubmit" autocomplete="off">
      <div class="con_box">
        <p class="form_title">手机号：</p>
        <van-field
          v-model="applyForm.jsonData.username"
          name="手机号"
          placeholder="手机号"
          :rules="[{ pattern, message: '请输入11位手机号' }]"
        />
        <p class="form_title">姓名：</p>
        <van-field
          v-model="personalForm.name"
          name="姓名"
          placeholder="姓名"
          :rules="[{ pattern: napattern, message: '请输入汉字姓名' }]"
        />

        <p class="form_title">企业名称：</p>
        <van-field
          v-model="applyForm.jsonData.companyName"
          name="企业名称"
          placeholder="企业名称"
          :rules="[
            {
              validator: compattern,
              message: '请输入企业名称不少于4位，最大20位'
            }
          ]"
        />
        <p class="form_title">职位：</p>
        <van-field
          v-model="personalForm.position"
          name="职位"
          placeholder="职位"
          :rules="[
            { required: true, message: '请输入职位' },
            { pattern: pospattern, message: '可输入汉字和英文' }
          ]"
        />
        <p class="form_title">企业/单位地址：</p>
        <van-field
          style="margin-bottom:10px"
          readonly
          clickable
          name="area"
          :value="value"
          placeholder="点击选择省市区"
          @click="showArea = true"
          :rules="[{ required: true, message: '请选择地址' }]"
        />
        <van-field
          v-model="personalForm.companyAddress"
          name="企业地址"
          placeholder="输入详细企业地址"
          :rules="[{ required: true, message: '请输入详细企业地址' }]"
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="onConfirm"
            @cancel="showArea = false"
            :columns-num="2"
          />
        </van-popup>
        <p class="form_title">营业执照/政府机关证明：</p>
        <div class="up">
          <van-field name="uploader">
            <template #input>
              <van-uploader
                v-model="licenseuploader"
                :max-count="1"
                :after-read="licenseafterRead"
              />
            </template>
          </van-field>
        </div>
        <p class="form_title">法人身份证正面扫描件：</p>
        <div class="up">
          <van-field name="uploader">
            <template #input>
              <van-uploader
                v-model="positiveuploader"
                :max-count="1"
                :after-read="positiveafterRead"
              />
            </template>
          </van-field>
        </div>
        <p class="form_title">法人身份证反面扫描件：</p>
        <div class="up">
          <van-field name="uploader">
            <template #input>
              <van-uploader
                v-model="backuploader"
                :max-count="1"
                :after-read="backafterRead"
              />
            </template>
          </van-field>
        </div>
        <p class="form_title">授权函截图上传：</p>
        <div class="up">
          <van-field name="uploader">
            <template #input>
              <van-uploader
                upload-icon="newspaper-o"
                accept="*"
                v-model="shouquanhanuploadr"
                :max-count="1"
                :after-read="shouquanhanafterRead"
              >
              </van-uploader>
            </template>
          </van-field>
          <a
            style="color:#0086fd;font-size: 12px;"
            type="primary"
            href="./tpls/powerOfAttorney.docx"
            download=""
            >授权函模板下载</a
          >
        </div>
        <p class="form_title">密码：</p>
        <van-field
          type="password"
          v-model="applyForm.jsonData.password"
          name="密码"
          placeholder="密码"
          :rules="[
            { pattern: pwpattern, message: '请填写不小于6位的数字和字母' }
          ]"
        />
        <p class="form_title">手机验证码：</p>
        <van-field
          class="passw"
          v-model="applyForm.code"
          name="手机验证码"
          placeholder="手机验证码"
          :rules="[{ required: true, message: '请输入验证码' }]"
        >
          <template #button>
            <van-button
              @click="yanzhen"
              class="van-buttonradius"
              native-type="button"
              :disabled="canClick"
              size="small"
              style="background-color:transparent;color:#0086FD;border-right:0;border-top:0;border-bottom:0;"
              >{{ btn_content }}</van-button
            >
          </template>
        </van-field>
      </div>
      <div style="text-align: center;margin-top:20px">
        <van-checkbox v-model="checked" direction="horizontal">
          已阅读并同意 <span @click="show = true">《用户协议》</span>
        </van-checkbox>
      </div>

      <van-action-sheet
        cancel-text="取消"
        v-model="show"
        title="SmartEarth网站服务协议"
      >
        <div class="content">
          <p>一、提示条款</p>
          <p>
            1、泰瑞数创（北京）科技有限公司（以下简称“泰瑞数创”或“本公司”）在此特别提醒服务使用人，SmartEarth空间遥感综合服务平台由本公司研发，在发布付费需求之前，请认真阅读本《SmartEarth服务交易协议》（以下简称“本协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权使用本协议所涉服务（以下简称“本服务”）。
          </p>
          <p>二、账户的注册、使用与安全</p>
          <p>2.1. 注册的资格</p>
          <p>
            2.1.1.
            您确认，在您完成注册程序实际使用“SmartEarth网站”服务时，您应当是具备完全民事权利能力和完全民事行为能力的法人、机关或其他组织（以下或简称为“您”）。
          </p>
          <p>
            2.1.2.
            您还需确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用“SmartEarth网站”服务。
          </p>
          <p>2.2. 账户注册</p>
          <p>
            2.2.1.当您按照注册页面提示填写信息、阅读并同意本服务条款且完成全部注册程序后，您可获得“SmartEarth网站”账户并成为“SmartEarth网站”的用户。
          </p>
          <p>
            2.2.2.
            您在注册时候设置或确认的手机号码及您设置的密码，将在注册成功后成为您的账户（手机号码及密码合称“账户”）。
          </p>
          <p>2.3. 账户的使用和安全</p>
          <p>
            2.3.1.
            您有权使用您设定的账户登录“SmartEarth网站”，进而使用“SmartEarth网站”提供的其他服务。
          </p>
          <p>
            2.3.2.
            “SmartEarth网站”可能会以邮件、短信或电话等方式通知您服务进展情况以及提示您进行下一步的操作。在服务过程中您应当及时登录到阿里云账户查看和进行交易操作。
          </p>
          <p>
            2.3.3.
            一个“SmartEarth网站”仅能对应唯一的法律主体。除非有法律明文规定、司法裁定或者经“SmartEarth网站”同意的情况下，您不得以任何方式转让、赠与或让他人继承您的“SmartEarth网站”账户。
          </p>
          <p>
            2.3.4.
            您的账户和密码由您自行设置并由您保管，您须对您的“SmartEarth网站”账户和密码保密。您需确保您在每个上网时段结束时，以正确步骤离开网站。
          </p>
          <p>
            2.3.5.
            如发现他人未经授权使用您的“SmartEarth网站”账户和密码，您应立即通知“SmartEarth网站”；“SmartEarth网站”将协助您冻结账户、更改密码或进行其他安全设置；您理解“SmartEarth网站”对您的请求采取行动需要合理时间，“SmartEarth网站”对在采取行动前已经产生的以及由您引发的后果（包括但不限于您的任何损失）不承担任何责任。
          </p>
          <p>
            2.3.6.
            “SmartEarth网站”亦会在网站服务端采取合理的技术措施保障账户的安全。
          </p>
          <p>三、服务内容</p>
          <p>
            本服务的具体内容由本公司根据实际情况提供，主要包括用户的发布需求、需求审核或订单状态查看、服务进程查看、评价及问题投诉、查询历史记录等。
          </p>
          <p>四、定义与释义</p>
          <p>1、企业用户：通过“SmartEarth网站”发布特定服务需求的用户。</p>
          <p>
            2、合作伙伴：通过“SmartEarth网站”报价特定服务需求并最终成交的用户。在“SmartEarth网站”完成特定信息认证的用户，可天然具有企业用户与合作伙伴两种身份属性。
          </p>
          <p>（企业用户及合作伙伴，以下合称“用户”）</p>
          <p>五、服务使用及管理规定</p>
          <p>针对企业用户：</p>
          <p>
            1、企业用户应确保自行发布的任务满足中华人民共和国相关法律法规的要求。
          </p>
          <p>
            2、企业用户应保证发布的全部任务信息是真实、完整、准确、合法、有效的。
          </p>
          <p>
            3、企业用户有权利规定所申请服务的数据标准，并明确服务订单的验收指标。
          </p>
          <p>
            4、对于已接单需求，企业用户负有按照平台评估完成的金额向合作伙伴购买商品的义务。
          </p>
          <p>5、对于已成交需求，企业用户需签订服务合同，约定相关条款。</p>
          <p>6、企业用户必须自行负担因交易而产生的费用。</p>
          <p>针对合作伙伴：</p>
          <p>
            1、合作伙伴须确保向“SmartEarth网站”提交的服务能力认证真实有效，以保证“SmartEarth网站”具有准确服务能力评估的基础。
          </p>
          <p>
            2、合作伙伴在报价需求前，应详细审阅任务需求信息并仔细评估，一经报价，即负有按照约定价向需求商提供服务的义务。
          </p>
          <p>
            3、合作伙伴应理解报价不等同于成交，“SmartEarth网站”遵循合理价原则而非最低价原则，在需求发布价上限范围内选择报价合作伙伴中的更优者。
          </p>
          <p>4、对于已成交需求，合作伙伴需签订履约合同，约定相关条款</p>
          <p>
            5、对于合作伙伴，每一单成交交易，“SmartEarth”将收取成交报价的一定比例作为平台服务费，合作伙伴应确认在报价提交前已充分了解并认可该笔服务费用，否则需放弃报价。该笔服务费用另行协议约定
          </p>
          <p>6、对于合作伙伴使用本服务所发生的应纳税行为，请依法纳税。</p>
          <p>六、关于平台服务费的说明</p>
          <p>
            平台服务费是“SmartEarth网站”针对合作服务商就报价成交的交易所收取的费用。该费用主要用于：
          </p>
          <p>1、支撑“SmartEarth网站”的日常运营建设。</p>
          <p>2、支撑“SmartEarth网站”的服务保障计划。</p>
          <p>3、支撑“SmartEarth网站”的公益服务计划</p>
          <p>七、法律责任</p>
          <p>
            1、用户理解并同意，其基于本服务所发布的需求必须满足中华人民共和国相关法律法规的规定。
          </p>
          <p>
            2、用户理解并同意，其基于本服务所发布的需求客观存在无接单的情况，“SmartEarth网站”或本公司不会因此种情况承担任何责任。
          </p>
          <p>
            3、用户理解并同意，其基于本服务所发布的需求在承接后，不论因任何原因导致服务异常中止或服务未达成，用户与本公司另据签订的合同条款执行。
          </p>
          <p>
            4、用户理解并同意，其基于本服务发布或承接需求时，所涉信息与服务均应保证合法性、真实性。
          </p>
          <p>
            5、用户理解并同意，泰瑞数创有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。
          </p>
          <p>
            6、用户理解并同意，因用户违反本协议约定导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿泰瑞数创与合作公司、关联公司，并使之免受损害。
          </p>
          <p>八、免责声明</p>
          <p>
            1、用户自行承担账户的保管责任，并就其账户项下之一切活动负全部责任。用户应注意网络安全防护，定期更改密码，防止账户泄露，保证个人信息安全。因用户账户丢失或泄露给用户造成的损失，“SmartEarth网站”不承担任何责任。
          </p>
          <p>
            2、用户因归属于自身的原因所造成的服务无效、中止、失败等后果，本公司及“SmartEarth网站”不承担任何责任。
          </p>
          <p>
            3、用户理解并确认，对其使用本服务所存在的风险将完全由其自己承担；因其使用本服务所产生的的一切后果也由其自己承担，除本协议另有约定外，本公司对用户不承担任何责任。
          </p>
          <p>
            4、除本服务中明确约定或另行补充约定的类型担保外，本公司对本服务中未约定的不作任何类型的担保，包括但不限于本服务的及时性、安全性、准确性。对本服务中未约定担保的类型因使用或不能使用本服务所产生的直接、间接、偶然、特殊及后续的损害及风险，本公司不承担任何责任。
          </p>
          <p>
            5、用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定以及其他技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，本公司不承担任何责任。
          </p>
          <p>
            6、在任何情况下，泰瑞数创均不对任何间接性、后果性、偶然性、特殊性或刑罚性的损害，包括因用户使用本服务而遭受的利润损失，承担责任（即使泰瑞数创已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，泰瑞数创对用户承担的全部责任，无论因何原因或何种行为方式，始终不超过用户因使用本服务而支付的费用的50%（如有）。
          </p>
          <p>
            7、用户理解并确认，“SmartEarth网站“所涉服务和提供的功能严格依据中国相关法律法规，任何在使用本服务过程中直接或间接违反法律法规的行为，该后果应由用户承担。如有举证需要，本公司可以向有关部门提供相关数据作为证据。
          </p>
          <p>
            8、“SmartEarth网站”向用户免费提供和赠送的或提供有偿网络服务时附赠的各种产品和服务，因产品或者服务的瑕疵引起的任何损失，“SmartEarth网站”不承担任何法律责任；
          </p>
          <p>
            9、“SmartEarth网站”需要定期或者不定期地进行停机维护，因此类情况造成的正常服务中断，请用户予以理解。“SmartEarth网站”行使修改或中断网络服务的权利，不需对用户或第三方负责。
          </p>
          <p>
            10、因下列情形导致网络服务中断、终止，“SmartEarth网站”不承担法律责任：
          </p>
          <p>
            10.1因互联网接入单位、平台、平台支持单位、短信发送商、移动运营商等电信单位的线路故障、通讯故障造成的信息传递异常。
          </p>
          <p>
            10.2因地震、台风、战争、瘟疫、爆发性和流行性传染病或其他重大疫情、火灾造成的及其他各方不能预见并且对其发生后果不能避免的不可抗力造成的网络服务异常；
          </p>
          <p>10.3因黑客攻击、网络病毒、政府管制出现的网络服务异常。</p>
          <p>九、违约赔偿责任</p>
          <p>
            1、本公司或用户违反本协议的约定即构成违约，违约方应当同守约方承担由此造成的损害赔偿责任。
          </p>
          <p>
            2、用户同意保障和维护本公司及其他用户利益，如因用户违反有关法律、法规或本协议项下的任何条款而给本公司或任何其他第三方造成损失，用户应当承担赔偿责任。
          </p>
          <p>
            3、如因用户违法法律法规规定或本协议约定，利用本公司或“SmartEarth网站”服务从事非法活动的，本公司有权立即终止对其提供服务，注销其账号，并要求其赔偿由此给泰瑞数创及其他第三方造成的损失。
          </p>
          <p>
            4、如用户用虚构服务的方式扰乱“SmartEarth网站”正常的经营秩序，本公司有权利要求用户向本公司公开道歉，赔偿其给本公司造成的损失，并有权对其终止“SmartEarth网站”服务。
          </p>
        </div>
      </van-action-sheet>
      <div style="margin-top: 20px;margin-bottom: 20px;">
        <van-button block type="info" native-type="submit">
          注册
        </van-button>
      </div>
      <div style="">
        <van-button
          @click="$router.push({ path: '/registered' })"
          native-type="button"
          block
          style="background-color: rgba(0, 0, 0, 0.0);color:#fff;"
        >
          返回首页
        </van-button>
      </div>
    </van-form>
    <div class="logo">
      <img src="../../assets/img/indexLogo.png" alt="" />
    </div>
  </div>
</template>

<script>
import areaList from "../../area";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      licenseuploader: [],
      backuploader: [],
      positiveuploader: [],
      shouquanhanuploadr: [],
      value: "",
      showArea: false,
      areaList: {},
      show: false,
      checked: false,
      totalTime: 60,
      canClick: false, //添加canClick
      btn_content: "申请发送",
      username: "",
      password: "",
      pattern: /^[1][1,2,3,4,5,6,7,8,9,0][0-9]{9}$/,
      pwpattern: /^[a-zA-Z0-9]{6,}$/,
      napattern: /^[\u4e00-\u9fa5]+$/,
      pospattern: /^[\u0391-\uFFE5A-Za-z]+$/,
      applyForm: {
        code: "", //手机验证码
        type: 2,
        jsonData: {
          username: "", // 用户手机号
          password: "", // 用户密码
          companyName: "", //企业名称
          data: {}
        }
      },
      personalForm: {
        contacts: "",
        companyAddress: "",
        name: "",
        license: "", //身份证
        position: "", //职位，
        licenseUrl: "", //营业执照
        backUrl: "", //身份信息照片背面
        positiveUrl: "", //身份信息照片
        shouquanhanUrl: "" //授权函
      },
      phone: {},
      application: {
        host: "parallelword@terra-it.cn",

        title: "平行世界",
        content: ""
      },
      positiveUpload: false,
      backUpload: false,
      licenseUpload: false,
      shouquanhanUpload: false
    };
  },
  //方法集合
  methods: {
    async submit() {
      var content = `平行世界有新增注册申请待处理`;
      this.application.content = content;
      const { data: dt } = await this.$http.post(
        "general/sendMail",
        this.application
      );
      if (dt.status !== 200) {
        return this.$message.error(dt.msg);
      }
    },
    //授权函上传
    async shouquanhanafterRead(file) {
      let param = new FormData();
      param.append("file", file.file);
      let config = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      const { data: dt } = await this.$http.post(
        "general/upload",
        param,
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.personalForm.shouquanhanUrl = dt.data.result;
      this.shouquanhanUpload = false;
      this.$toast.success("上传成功");
      // console.log(param.get("file"));
    },
    //身份背上传
    async backafterRead(file) {
      let param = new FormData();
      param.append("file", file.file);
      let config = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      const { data: dt } = await this.$http.post(
        "general/upload",
        param,
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.personalForm.backUrl = dt.data.result;
      this.backUpload = false;
      this.$toast.success("上传成功");
      // console.log(param.get("file"));
    },
    //身份正上传
    async positiveafterRead(file) {
      let param = new FormData();
      param.append("file", file.file);
      let config = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      const { data: dt } = await this.$http.post(
        "general/upload",
        param,
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.personalForm.positiveUrl = dt.data.result;
      this.positiveUpload = false;
      this.$toast.success("上传成功");
      // console.log(param.get("file"));
    },
    //营业执照上传图片
    async licenseafterRead(file) {
      // console.log(file.file);
      // file.status = "uploading";
      // file.message = "上传中...";

      let param = new FormData();
      param.append("file", file.file);
      let config = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      const { data: dt } = await this.$http.post(
        "general/upload",
        param,
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.personalForm.licenseUrl = dt.data.result;
      this.licenseUpload = false;
      this.$toast.success("上传成功");
      // console.log(param.get("file"));
    },
    compattern(val) {
      return val.length >= 4 && val.length < 20;
    },
    //验证码按钮
    async yanzhen() {
      if (this.applyForm.jsonData.username == "") {
        return this.$toast.fail("请填写手机号");
      }

      if (this.canClick) return; //改动的是这两行代码
      this.canClick = true;
      this.btn_content = this.totalTime + "s后重新发送";
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.btn_content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.btn_content = "重新发送验证码";
          this.totalTime = 60;
          this.canClick = false; //这里重新开启
        }
      }, 1000);
      this.phone.phone = this.applyForm.jsonData.username;
      const { data: dt } = await this.$http.post(
        "general/sendCode",
        this.phone
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
    },
    //选择省市
    onConfirm(values) {
      this.value = values
        .filter(item => !!item)
        .map(item => item.name)
        .join("");
      this.showArea = false;
    },
    //跳转个人
    geren() {
      this.$router.push({ path: "/registered" });
    },
    //校验
    validator(val) {
      return /1\d{10}/.test(val);
    },
    //提交表单
    async onSubmit() {
      if (!this.checked) {
        return this.$toast.fail("请阅读后勾选用户协议");
      }

      if (this.licenseUpload) {
        return this.$toast.fail("请上传营业执照/政府机关证明照片");
      }
      if (this.positiveUpload) {
        return this.$toast.fail("请上传身份信息照片");
      }
      if (this.backUpload) {
        return this.$toast.fail("请上传身份信息照片");
      }
      if (this.shouquanhanUpload) {
        return this.$toast.fail("请上传授权函");
      }
      this.personalForm.companyAddress =
        this.value + this.personalForm.companyAddress;
      this.personalForm.contacts = this.applyForm.jsonData.username;
      this.applyForm.jsonData.data = this.personalForm;
      this.applyForm.jsonData.data = JSON.stringify(
        this.applyForm.jsonData.data
      );
      this.applyForm.jsonData = JSON.stringify(this.applyForm.jsonData);
      const { data: dt } = await this.$http.post(
        "user/register",
        this.applyForm
      );

      this.applyForm.jsonData = JSON.parse(this.applyForm.jsonData);
      this.applyForm.jsonData.data = JSON.parse(this.applyForm.jsonData.data);
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }

      if (dt.data === 1) {
        return this.$toast.fail("验证码过期");
      } else if (dt.data === 2) {
        return this.$toast.fail("验证码错误");
      } else if (dt.data === 3) {
        return this.$toast.fail("手机号不是验证的手机号");
      } else if (dt.data === 4) {
        return this.$toast.fail("手机号已注册");
      } else if (dt.data === 5) {
        return this.$toast.fail("企业名称已注册");
      } else {
        this.$toast.success("注册成功");
        this.submit();
        this.$router.push("/login");
      }
    }
  },
  created() {},
  mounted() {
    this.areaList = areaList;
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.body_box {
  min-height: 100vh;
  max-height: auto;
  // height: 200vh;
  background: url("../../assets/img/beijin.png") no-repeat center;
  background-size: 100%;
  overflow: hidden;
  padding: 0 47px;
  .con_box {
    height: 40vh;
    overflow: auto;
  }
  .title {
    color: #fff;
    // font-size: 60px;

    margin-top: 100px;
    margin-bottom: 100px;
    .van-col {
      display: table-cell; /*按照单元格的样式显示元素*/
      vertical-align: bottom; /*底对齐*/
    }
    h1 {
      width: 50vw;
      font-weight: 600;
      display: table-cell; /*按照单元格的样式显示元素*/
      vertical-align: bottom; /*底对齐*/
    }
    h2 {
      width: 50vw;
      font-weight: 100;
      display: table-cell; /*按照单元格的样式显示元素*/
      vertical-align: bottom; /*底对齐*/
      opacity: 0.6;
    }
  }
  .van-field {
    background: rgba(255, 255, 255, 0.2);

    border-radius: 6px;

    margin-bottom: 25px;
  }
  .van-cell:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  .van-cell::after {
    border-bottom: 0px;
  }

  .form_title {
    color: #fff;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .a_box {
    display: flex;
    justify-content: flex-end;

    .right {
      color: #0086fd;

      font-size: 14px;
    }
    .left {
      color: #0086fd;

      font-size: 14px;
      margin-right: auto;
    }
  }
  .van-button {
    border-radius: 6px;
  }
  .van-buttonradius {
    border-radius: 0;
  }

  .logo {
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    img {
      width: 180px;
      height: 40px;
    }
  }
  .content {
    padding: 50px;
  }
  /deep/ .van-checkbox {
    flex-direction: row; /*这里可以不写，flex布局默认方向横向即row*/
    justify-content: center;
    align-items: center;

    .van-checkbox__label {
      color: #fff;
      font-size: 14px;
    }
    span {
      color: #0086fd;
      font-size: 14px;
    }
  }
  .up {
    margin-bottom: 25px;
  }
}
</style>
