<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>数据处理接单详情</p>
    </div>

    <van-form>
      <p class="class_title">
        项目信息
      </p>
      <van-field
        readonly
        v-model="testData.name"
        name="项目名称"
        label="项目名称"
      />
      <!-- <van-field
        readonly
        v-model="editForm.projectBudget"
        name="项目预算"
        label="项目预算"
      /> -->
      <van-field
        readonly
        clickable
        name="calendar"
        :value="editForm.shijianDuan"
        label="时间段"
      />

      <van-field
        readonly
        clickable
        name="area"
        :value="editForm.suozaidi"
        label="项目所在地"
      />

      <p class="class_title">
        数据成果
      </p>
      <van-field
        readonly
        type="textarea"
        autosize
        rows="1"
        name="caijishouduan"
        v-model="editForm.caijishouduan"
      >
      </van-field>
      <div v-if="this.editForm.hasOwnProperty('MonomerModel')">
        <p class="class_title">
          单体模型要求
        </p>
        <van-field
          readonly
          type="textarea"
          autosize
          rows="1"
          name="MonomerModel"
          v-model="editForm.MonomerModel"
        >
        </van-field>
      </div>
      <div v-if="this.editForm.hasOwnProperty('semanticModel')">
        <p class="class_title">
          语义模型要求
        </p>
        <van-field
          readonly
          type="textarea"
          autosize
          rows="1"
          name="semanticModel"
          v-model="editForm.semanticModel"
        >
        </van-field>
      </div>
      <div v-if="this.editForm.hasOwnProperty('BIM')">
        <p class="class_title">
          BIM要求
        </p>
        <van-field
          readonly
          type="textarea"
          autosize
          rows="1"
          name="BIM"
          v-model="editForm.BIM"
        >
        </van-field>
      </div>
      <p class="class_title">
        成果要求
      </p>
      <van-field
        readonly
        name="zuobiaoxi"
        label="坐标系要求"
        v-model="editForm.zuobiaoxi"
      >
      </van-field>
      <van-field
        readonly
        name="chengguojindu"
        label="成果精度"
        v-model="editForm.chengguojindu"
      >
      </van-field>
      <p class="class_title">
        源数据信息
      </p>
      <van-field
        readonly
        v-model="editForm.hangfeifenbianlv"
        name="航飞分辨率"
        label="航飞分辨率"
      />
      <van-field
        readonly
        v-model="editForm.zhaopianshuliang"
        name="照片数量"
        label="照片数量"
      />
      <van-field
        readonly
        clickable
        name="picker"
        :value="feixinqixingxi.feixingqileixin"
        label="飞行器类型"
      />

      <van-field
        readonly
        v-model="feixinqixingxi.feixingqixinhao"
        name="飞行型号"
        label="飞行型号"
      />
      <van-field name="radio" label="控制点有无" v-model="kongzhidian">
      </van-field>

      <van-field
        readonly
        v-if="kongzhidian == '有'"
        v-model="editForm.kongzhidian"
        name="控制点数量"
        label="控制点数量"
      />
      <van-field
        readonly
        v-model="editForm.hangsheyixinghao"
        name="航摄仪型号"
        label="航摄仪型号"
      />
      <van-field
        readonly
        v-model="editForm.xiangfukuandu"
        name="相幅宽度"
        label="相幅宽度"
      />
      <van-field
        readonly
        v-model="editForm.xiangfugaodu"
        name="相幅高度"
        label="相幅高度"
      />
      <van-field
        readonly
        v-model="editForm.chuanganqikuandu"
        name="传感器宽度"
        label="传感器宽度"
      />
      <van-field
        readonly
        v-model="editForm.jintoujiaoju"
        name="镜头焦距"
        label="镜头焦距"
      />
      <van-field
        readonly
        v-model="editForm.jintoushuliang"
        name="镜头数量"
        label="镜头数量"
      />
      <van-field
        readonly
        v-model="editForm.yingxianggeshi"
        name="影像格式"
        label="影像格式"
      />

      <van-field
        readonly
        name="bukongxuqiu"
        label="布控需求"
        v-model="editForm.bukongxuqiu"
      >
      </van-field>
      <p class="class_title">
        付款方式
      </p>
      <van-field
        readonly
        name="付款方式"
        label="付款方式"
        v-model="editForm.paymentMode"
      >
      </van-field>
      <div
        v-if="
          'supplementaryAnnex' in this.editForm &&
            this.editForm.supplementaryAnnex != ''
        "
      >
        <p class="class_title">
          附件下载
        </p>
        <van-cell title="附件下载" is-link @click="download" />
      </div>
      <!-- <p class="class_title" id="ditucaiji">
        测区范围
      </p> -->

      <!-- <van-field
        readonly
        type="number"
        label="中心点经度"
        name="radio"
        v-model="editForm.lng"
      >
      </van-field>
      <van-field
        readonly
        type="number"
        label="中心点纬度"
        name="radio"
        v-model="editForm.lat"
      >
      </van-field>
      <van-field
        readonly
        type="number"
        label="半径"
        name="radio"
        v-model="editForm.radius"
      >
      </van-field> -->

      <!-- <van-field
        readonly
        type="number"
        v-model="editForm.zuoyemianji"
        name="作业区域面积"
        label="作业区域面积"
      /> -->

      <!-- <p class="class_title">
        备注
      </p>
      <van-field
        readonly
        v-model="editForm.BeiZhu"
        name="备注"
        type="textarea"
        autosize
        rows="1"
      /> -->
    </van-form>
    <p class="class_title" id="ditucaiji">
      报价
    </p>
    <van-form @submit="onSubmit">
      <van-field
        :disabled="shurukuangzhuangtai"
        v-model="dingjin"
        name="报价"
        label="报价(单位：￥)"
        placeholder="报价"
        :rules="[{ pattern, message: '不可输入负数且可保留小数点后两位' }]"
      />
      <van-field
        :disabled="shurukuangzhuangtai"
        v-model="quotationRemarks"
        name="报价备注"
        label="报价备注"
        placeholder="报价备注"
      />
      <div style="margin: 16px;">
        <van-button
          :disabled="shurukuangzhuangtai"
          round
          block
          type="info"
          native-type="submit"
        >
          接单报价
        </van-button>
      </div>
      <div style="margin: 16px;">
        <van-button
          @click="quxiaobaojia()"
          :disabled="shurukuangzhuangtai"
          round
          block
          type="danger"
          native-type="botton"
        >
          取消
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import areaList from "../../area";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/,
      shurukuangzhuangtai: true,
      baojiaForm: {
        dingjin: "",
        weikuan: ""
      },
      quotationRemarks: "",
      dingjin: "",
      orderText: {},
      caijishouduan: {},

      detailed: {},
      baojiaData: {
        function: "t_dispatch",
        id: "",
        jsonData: {
          status: ""
        }
      },
      yaoqinbaojiaData: {
        function: "t_dispatch",
        jsonData: {
          status: "",
          orderId: "",

          userId: ""
        }
      },
      yaoqinData: {},
      editForm: {},

      zuobiaoform: {
        center: {
          lng: "",
          lat: ""
        },

        radius: ""
      },

      mapshow: false,
      kongzhidian: "无",
      weixinvalue: "",
      weixinvalue1: "",
      columns: [
        "固定翼（有人机）",
        "直升机（有人机）",
        "固定翼（无人机）",
        "旋翼机（无人机）",
        "直升机（无人机）",
        "复合翼（无人机）"
      ],

      showPicker: false,

      diqu: "",
      showArea: false,
      areaList: {},
      username: "",
      password: "",
      value: "",
      showCalendar: false,
      testData: {},
      orderData: {
        function: "t_order",
        condition: ""
      },
      feixinqixingxi: {}
    };
  },
  //方法集合
  methods: {
    download() {
      window.open(
        this.$http.defaults.baseURL +
          "file" +
          "/" +
          this.editForm.supplementaryAnnex
      );
    },
    quxiaobaojia() {
      this.$dialog
        .confirm({
          title: "确认要取消报价么"
        })
        .then(async () => {
          // eslint-disable-next-line no-prototype-builtins
          if (this.orderText.hasOwnProperty("dispatchId")) {
            this.baojiaData.id = this.orderText.dispatchId;
            this.baojiaData.jsonData.status = 5;
            this.baojiaData.jsonData = JSON.stringify(this.baojiaData.jsonData);
            const { data: dt } = await this.$http.post(
              "general/update",
              this.baojiaData
            );
            if (dt.status !== 200) {
              return this.$toast.fail(dt.msg);
            }
            this.$router.push("/user");
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.orderText.hasOwnProperty("dispatchId") == false) {
            this.yaoqinbaojiaData.jsonData.status = 5;

            this.yaoqinbaojiaData.jsonData = JSON.stringify(
              this.yaoqinbaojiaData.jsonData
            );
            const { data: dt } = await this.$http.post(
              "general/insert",
              this.yaoqinbaojiaData
            );

            if (dt.status !== 200) {
              return this.$toast.fail(dt.msg);
            }
            this.$router.push("/user");
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    onSubmit() {
      this.$dialog
        .confirm({
          title: "确认是否要报价"
        })
        .then(async () => {
          // eslint-disable-next-line no-prototype-builtins
          if (this.orderText.hasOwnProperty("dispatchId")) {
            this.baojiaData.id = this.orderText.dispatchId;

            //旧
            // this.baojiaData.jsonData.data = {};
            // this.baojiaData.jsonData.data.kaishishijian = this.detailed.kaishishijian;
            // this.baojiaData.jsonData.data.baojia = this.dingjin;
            // this.baojiaData.jsonData.data.quotationRemarks = this.quotationRemarks;
            //-----------------------------------------------------------
            //新的
            this.baojiaData.jsonData.data = {
              quotationRemarks: this.quotationRemarks
            };
            this.baojiaData.jsonData.quote_price = this.dingjin;
            this.baojiaData.jsonData.status = 2;

            this.baojiaData.jsonData.data = JSON.stringify(
              this.baojiaData.jsonData.data
            );
            this.baojiaData.jsonData = JSON.stringify(this.baojiaData.jsonData);
            const { data: dt } = await this.$http.post(
              "general/update",
              this.baojiaData
            );
            if (dt.status !== 200) {
              return this.$toast.fail(dt.msg);
            }
            this.$router.push("/user");
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.orderText.hasOwnProperty("dispatchId") == false) {
            this.yaoqinbaojiaData.jsonData.status = 2;

            //新的
            this.yaoqinbaojiaData.jsonData.data = {
              quotationRemarks: this.quotationRemarks
            };
            this.yaoqinbaojiaData.jsonData.quote_price = this.dingjin;
            //旧的
            // this.yaoqinbaojiaData.jsonData.data = {};
            // this.yaoqinbaojiaData.jsonData.data.kaishishijian = this.detailed.kaishishijian;
            // this.yaoqinbaojiaData.jsonData.data.baojia = this.dingjin;
            // this.yaoqinbaojiaData.jsonData.data.quotationRemarks = this.quotationRemarks;
            //-----------------------------------------------------------
            this.yaoqinbaojiaData.jsonData.data = JSON.stringify(
              this.yaoqinbaojiaData.jsonData.data
            );
            this.yaoqinbaojiaData.jsonData = JSON.stringify(
              this.yaoqinbaojiaData.jsonData
            );
            const { data: dt } = await this.$http.post(
              "general/insert",
              this.yaoqinbaojiaData
            );
            if (dt.status !== 200) {
              return this.$toast.fail(dt.msg);
            }
            this.$router.push("/user");
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    async conter() {
      const orderData = sessionStorage.getItem("orderData");

      if (orderData) {
        this.orderText = JSON.parse(orderData);
      } else {
        this.orderText = this.$route.params.data;

        sessionStorage.setItem(
          "orderData",
          JSON.stringify(this.$route.params.data)
        );
      }
      let userId = window.sessionStorage.getItem("userId");

      this.yaoqinbaojiaData.jsonData.userId = JSON.parse(userId);
      this.yaoqinbaojiaData.jsonData.orderId = this.orderText.id;

      if (this.orderText.price !== -1) {
        this.dingjin = this.orderText.price;
        if (typeof this.orderText.dispatchData == "string") {
          this.orderText.dispatchData = JSON.parse(this.orderText.dispatchData);
        }

        if ("dispatchData" in this.orderText) {
          if ("quotationRemarks" in this.orderText.dispatchData) {
            this.quotationRemarks = this.orderText.dispatchData.quotationRemarks;
          }
        }
      }
      if ("dispatchAgain" in this.orderText) {
        this.shurukuangzhuangtai = false;
      }

      this.orderData.condition = `userId=${this.orderText.userId} and id='${this.orderText.id}'`;
      const { data: dt } = await this.$http.post(
        "general/query",
        this.orderData
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }

      this.testData = dt.data[0];
      this.editForm = JSON.parse(this.testData.data);
      if (typeof this.editForm.feixinqixingxi == "string") {
        this.feixinqixingxi = JSON.parse(this.editForm.feixinqixingxi);
      }
      // eslint-disable-next-line no-prototype-builtins
      // if (this.feixinqixingxi.hasOwnProperty("feixingqileixin")) {
      // }
    }
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f2f2f2");
  },

  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  created() {
    this.conter();
  },
  mounted() {
    this.areaList = areaList;
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类

.box {
  background: #f2f2f2;
  padding-bottom: 50px;
}
.title {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
.class_title {
  padding: 20px;
  margin-right: auto;
  //   font-weight: bold;
  color: #666666;
}
.van-checkbox {
  margin-bottom: 10px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.bm-view {
  width: 100%;
  height: 50%;
}
</style>
