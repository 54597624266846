<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>数据处理合作伙伴</p>
    </div>
    <van-cell-group>
      <van-field
        label="软件产品型号"
        autosize
        type="textarea"
        rows="1"
        :value="personalForm.software"
        readonly
      />
      <van-field
        label="电子邮箱"
        autosize
        type="textarea"
        rows="1"
        :value="personalForm.Email"
        readonly
      />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      username: "",
      password: "",
      licenseuploader: [],
      backuploader: [],
      positiveuploader: [],
      shouquanhanuploadr: [],
      personalForm: {
        chulibohui: "",
        software: "",
        Email: ""
      },
      testData: {},
      bohuizhuantai: false
    };
  },
  //方法集合
  methods: {
    async content() {
      let config = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      };
      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.testData = dt.data;
      // eslint-disable-next-line no-prototype-builtins
      if (this.testData.hasOwnProperty("data")) {
        this.testData.data = JSON.parse(this.testData.data);
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("chulibohui")) {
          this.personalForm.chulibohui = this.testData.data.chulibohui;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("software")) {
          this.personalForm.software = this.testData.data.software;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("Email")) {
          this.personalForm.Email = this.testData.data.Email;
        }
      }
    }
  },
  created() {
    this.content();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  min-height: 100vh;
  max-height: auto;
  background: #f2f2f2;
  padding-bottom: 40px;
}
.title {
  padding: 20px;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
</style>
