<template>
  <div class="box">
    <p class="title">密码修改</p>
    <van-form @submit="onSubmit" autocomplete="off">
      <van-field
        label="旧密码"
        type="password"
        v-model="ruleForm.oldPass"
        name="旧密码"
        placeholder="旧密码"
        :rules="[{ pattern, message: '请填写不小于6位的数字和字母' }]"
      />
      <van-field
        label="新密码"
        type="password"
        v-model="ruleForm.password"
        name="新密码"
        placeholder="新密码"
        :rules="[{ pattern, message: '请填写不小于6位的数字和字母' }]"
      />

      <van-field
        label="再次输入密码"
        type="password"
        v-model="ruleForm.checkPass.password"
        name="再次输入密码"
        placeholder="再次输入密码"
        :rules="[{ validator, message: '请输入相同的密码' }]"
      />

      <div style="margin: 16px;">
        <van-button block type="info" round native-type="submit">
          确认修改
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      udate: {
        function: "t_user",
        id: "",
        jsonData: { data: {} }
      },
      ruleForm: {
        oldPass: "",
        password: "",
        checkPass: { password: "" }
      },
      pattern: /^[a-zA-Z0-9]{6,}$/
    };
  },
  //方法集合
  methods: {
    validator(val) {
      return this.ruleForm.password == val;
    },
    async onSubmit() {
      this.udate.jsonData = JSON.stringify(this.ruleForm.checkPass);
      const { data: dt } = await this.$http.post("general/update", this.udate);

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.$router.push({ path: "/userDetails" });
    },
    async conter() {
      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };

      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.udate.id = dt.data.id;
    }
  },
  created() {
    this.conter();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  min-height: 100vh;
  max-height: auto;
  background: #f2f2f2;
}
.title {
  padding: 20px;
  margin-right: auto;
  //   font-weight: bold;
  color: #666666;
}
</style>
