<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>数据采集（无人机）合作伙伴认证</p>
    </div>
    <van-form @submit="onSubmit">
      <van-field
        readonly
        v-if="this.bohuizhuantai"
        v-model="wurenbohui"
        name="驳回原因"
        label="驳回原因"
        placeholder="驳回原因"
        autosize
        type="textarea"
        rows="1"
      />
      <van-field
        :readonly="quxiao"
        v-model="ruleForm.flight"
        name="飞行器类型"
        label="飞行器类型"
        placeholder="飞行器类型"
        autosize
        type="textarea"
        rows="1"
        :rules="[{ required: true, message: '请填写飞行器类型' }]"
      />
      <van-field
        readonly
        clickable
        name="作业范围"
        :value="ruleForm.range"
        label="作业范围"
        placeholder="点击选择作业范围"
        @click="showPicker = true"
        :rules="[{ required: true, message: '请选择作业范围' }]"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>

      <van-field
        :readonly="quxiao"
        v-model="ruleForm.mappingCase"
        name="测绘行业案例"
        label="测绘行业案例"
        placeholder="测绘行业案例"
        autosize
        type="textarea"
        rows="1"
        :rules="[{ required: true, message: '请填写测绘行业案例' }]"
      />
      <van-field name="radio" label="是否有固定空域">
        <template #input>
          <van-radio-group
            :disabled="quxiao"
            v-model="ruleForm.airspace"
            direction="horizontal"
          >
            <van-radio name="是">是</van-radio>
            <van-radio name="否">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        :readonly="quxiao"
        v-if="this.ruleForm.airspace == '是'"
        v-model="ruleForm.airspaceDetails"
        name="固定空域"
        label="固定空域"
        placeholder="固定空域"
        autosize
        type="textarea"
        rows="1"
        :rules="[{ required: true, message: '请填写固定空域' }]"
      />
      <van-field
        :readonly="quxiao"
        v-model="ruleForm.applicableAirspace"
        name="可申请空域"
        label="可申请空域"
        placeholder="可申请空域"
        autosize
        type="textarea"
        rows="1"
        :rules="[{ required: true, message: '请填写可申请空域' }]"
      /><van-field
        :readonly="quxiao"
        v-model="ruleForm.sensor"
        name="传感器"
        label="传感器"
        placeholder="传感器"
        autosize
        type="textarea"
        rows="1"
        :rules="[{ required: true, message: '请填写传感器' }]"
      />

      <van-field
        :readonly="quxiao"
        v-model="ruleForm.Email"
        name="电子邮箱"
        label="电子邮箱"
        placeholder="电子邮箱"
        autosize
        type="textarea"
        rows="1"
        :rules="[{ required: true, message: '请填写电子邮箱' }]"
      />
      <van-field label="测绘资质证书扫描件">
        <template #input>
          <div>
            <van-uploader
              :disabled="quxiao"
              :before-delete="mappingPictureDelete"
              :max-count="4"
              :after-read="mappingafterRead"
              v-model="uavMappingUrl"
            />
          </div>
        </template>
      </van-field>

      <van-field label="适航证上传">
        <template #input>
          <div>
            <van-uploader
              :disabled="quxiao"
              :before-delete="uavPictureDelete"
              :max-count="4"
              :after-read="uavafterRead"
              v-model="uavUrl"
            />
          </div>
        </template>
      </van-field>
      <div style="margin: 16px;" v-if="!this.quxiao">
        <van-button round block type="info" native-type="submit">
          {{ this.bohuizhuantai ? "重新提交" : "提交" }}
        </van-button>
      </div>
      <div style="margin: 16px;" v-if="this.quxiao">
        <van-button
          @click="chehui"
          round
          block
          type="info"
          native-type="button"
        >
          撤回认证
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      navPicture: [],
      mappingPicture: [],
      columns: [
        "0-10平方公里",
        "10-100平方公里",
        "100-1000平方公里",
        "1000平方公里以上"
      ],
      showPicker: false,
      wurenbohui: "",
      ruleForm: {
        airspace: "是",
        mappingCase: "",
        sensor: "",
        applicableAirspace: "",
        airspaceDetails: "",
        flight: "",

        Email: "",
        range: "",
        uavMappingUrl: [],

        uavUrl: []
      },
      testData: {},
      uavMappingUrl: [],

      uavUrl: [],

      application: {
        host: "parallelword@terra-it.cn",

        title: "平行世界",
        content: ""
      },
      bohuizhuantai: false,
      //上传认证
      companydate: {
        type: 2,
        userJsonData: { data: {} }
      },
      uavUpload: false,
      quxiao: false,
      revoke: {
        function: "t_auth",
        ids: ""
      }
    };
  },
  //方法集合
  methods: {
    uavPictureDelete(file) {
      if (file.file) {
        this.navPicture.forEach((e, i) => {
          if (e.name === file.file.name) {
            this.navPicture.splice(i, 1);
          }
        });

        return true;
      } else {
        this.navPicture.forEach((e, i) => {
          if (e.name === file.url) {
            this.navPicture.splice(i, 1);
          }
        });

        return true;
      }
    },
    mappingPictureDelete(file) {
      if (file.file) {
        this.mappingPicture.forEach((e, i) => {
          if (e.name === file.file.name) {
            this.mappingPicture.splice(i, 1);
          }
        });

        return true;
      } else {
        this.mappingPicture.forEach((e, i) => {
          if (e.name === file.url) {
            this.mappingPicture.splice(i, 1);
          }
        });

        return true;
      }
    },
    async chehui() {
      const { data: dt } = await this.$http.post("general/delete", this.revoke);
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.$router.push("/user");
    },
    onConfirm(value) {
      this.ruleForm.range = value;
      this.showPicker = false;
    },
    async mappingafterRead(file) {
      let param = new FormData();
      param.append("file", file.file);
      let config = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      const { data: dt } = await this.$http.post(
        "general/upload",
        param,
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      let picture = {
        name: file.file.name,
        UpName: dt.data.result
      };
      this.mappingPicture.push(picture);
    },

    async uavafterRead(file) {
      let param = new FormData();
      param.append("file", file.file);
      let config = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      const { data: dt } = await this.$http.post(
        "general/upload",
        param,
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      let picture = {
        name: file.file.name,
        UpName: dt.data.result
      };

      this.navPicture.push(picture);
      // this.uavUpload = true;
    },

    async submit() {
      var content = `平行世界有合作伙伴认证待处理`;
      this.application.content = content;
      const { data: dt } = await this.$http.post(
        "general/sendMail",
        this.application
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
    },
    async onSubmit() {
      if (this.navPicture.length == 0) {
        return this.$toast.fail("AOPA，UTC，ASFC，民航局证书，至少上传一个");
      }
      var UAV = {};
      UAV.Email = this.ruleForm.Email;
      UAV.airspace = this.ruleForm.airspace;
      UAV.mappingCase = this.ruleForm.mappingCase;
      UAV.sensor = this.ruleForm.sensor;
      UAV.applicableAirspace = this.ruleForm.applicableAirspace;
      UAV.airspaceDetails = this.ruleForm.airspaceDetails;
      UAV.flight = this.ruleForm.flight;
      UAV.range = this.ruleForm.range;

      if (this.mappingPicture.length != 0) {
        this.mappingPicture.forEach(e => {
          this.ruleForm.uavMappingUrl.push(e.UpName);
        });
        UAV.uavMappingUrl = this.ruleForm.uavMappingUrl.toString();
      }
      if (this.navPicture.length != 0) {
        this.navPicture.forEach(e => {
          this.ruleForm.uavUrl.push(e.UpName);
        });
        UAV.uavUrl = this.ruleForm.uavUrl.toString();
      }

      this.testData.data.UAV = UAV;
      this.testData.data.UAV = JSON.stringify(this.testData.data.UAV);
      this.companydate.userJsonData.data = this.testData.data;
      this.companydate.userJsonData.data = JSON.stringify(
        this.companydate.userJsonData.data
      );
      this.companydate.userJsonData = JSON.stringify(
        this.companydate.userJsonData
      );
      const { data: dt } = await this.$http.post("user/auth", this.companydate);
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.submit();
      this.$router.push("/user");
    },
    async content() {
      let config = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      };
      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.testData = dt.data;
      this.testData.auth.forEach(e => {
        if (e.type == 2) {
          if (e.status == 2) {
            this.bohuizhuangtai = true;
          } else if (e.status == 1) {
            this.quxiao = true;
          }
          this.revoke.ids = e.id;
        }
      });
      console.log(this.testData.auth);
      // eslint-disable-next-line no-prototype-builtins
      if (this.testData.hasOwnProperty("data")) {
        this.testData.data = JSON.parse(this.testData.data);
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("UAV")) {
          if (typeof this.testData.data.UAV == "string") {
            this.testData.data.UAV = JSON.parse(this.testData.data.UAV);
          }

          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.hasOwnProperty("wurenbohui")) {
            this.wurenbohui = this.testData.data.wurenbohui;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.UAV.hasOwnProperty("Email")) {
            this.ruleForm.Email = this.testData.data.UAV.Email;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.UAV.hasOwnProperty("airspace")) {
            this.ruleForm.airspace = this.testData.data.UAV.airspace;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.UAV.hasOwnProperty("mappingCase")) {
            this.ruleForm.mappingCase = this.testData.data.UAV.mappingCase;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.UAV.hasOwnProperty("sensor")) {
            this.ruleForm.sensor = this.testData.data.UAV.sensor;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.UAV.hasOwnProperty("applicableAirspace")) {
            this.ruleForm.applicableAirspace = this.testData.data.UAV.applicableAirspace;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.UAV.hasOwnProperty("airspaceDetails")) {
            this.ruleForm.airspaceDetails = this.testData.data.UAV.airspaceDetails;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.UAV.hasOwnProperty("flight")) {
            this.ruleForm.flight = this.testData.data.UAV.flight;
          }

          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.UAV.hasOwnProperty("range")) {
            this.ruleForm.range = this.testData.data.UAV.range;
          }
          if ("uavMappingUrl" in this.testData.data.UAV) {
            if (this.testData.data.UAV.uavMappingUrl != "") {
              let imgArr = this.testData.data.UAV.uavMappingUrl
                .trim()
                .split(",");

              imgArr.forEach(e => {
                this.uavMappingUrl.push({
                  url: `${this.$http.defaults.baseURL}file/${e}`
                });
                let picture = {
                  name: `${this.$http.defaults.baseURL}file/${e}`,
                  UpName: e
                };
                this.mappingPicture.push(picture);
              });
            }
          }
          if ("uavUrl" in this.testData.data.UAV) {
            if (this.testData.data.UAV.uavUrl != "") {
              let imgArr = this.testData.data.UAV.uavUrl.trim().split(",");

              imgArr.forEach(e => {
                this.uavUrl.push({
                  url: `${this.$http.defaults.baseURL}file/${e}`
                });
                let picture = {
                  name: `${this.$http.defaults.baseURL}file/${e}`,
                  UpName: e
                };
                this.navPicture.push(picture);
              });
            }
          }
        }
      }
    }
  },
  created() {
    this.content();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  min-height: 100vh;
  max-height: auto;
  background: #f2f2f2;
  padding-bottom: 40px;
}
.title {
  padding: 20px;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
</style>
