<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>软件中心</p>
    </div>
    <van-tabs color="#0086FD" v-model="active">
      <van-tab title="软件申请">
        <van-form @submit="onSubmit">
          <van-field
            v-model="ruleForm.Companyname"
            name="公司名称"
            label="公司名称"
            placeholder="公司名称"
            :rules="[{ required: true, message: '请填写公司名称' }]"
          />
          <van-field
            v-model="ruleForm.Industry"
            name="行业"
            label="行业"
            placeholder="行业"
            :rules="[{ required: true, message: '请填写行业' }]"
          />
          <van-field
            v-model="ruleForm.CustomerAddress"
            name="联系地址"
            label="联系地址"
            placeholder="联系地址"
            :rules="[{ required: true, message: '请填写联系地址' }]"
          />
          <van-field
            v-model="ruleForm.ContactPerson"
            name="联系人姓名"
            label="联系人姓名"
            placeholder="联系人姓名"
            :rules="[{ required: true, message: '请填写联系人姓名' }]"
          />
          <van-field
            v-model="ruleForm.PhoneNumber"
            name="联系人电话"
            label="联系人电话"
            placeholder="联系人电话"
            :rules="[{ required: true, message: '请填写联系人电话' }]"
          />
          <van-field
            v-model="ruleForm.MailAddress"
            name="联系人邮箱"
            label="联系人邮箱"
            placeholder="联系人邮箱"
            :rules="[{ required: true, message: '请填写联系人邮箱' }]"
          />
          <van-field
            v-model="ruleForm.MachineCode"
            name="机器码"
            label="机器码"
            placeholder="机器码"
            :rules="[{ required: true, message: '请填写机器码' }]"
          />
          <div style="padding-left:20px">
            <a
              style="color:#0086fd;font-size: 12px;"
              type="primary"
              href="./tpls/jiqitichuma-SmartEarth.exe"
              download=""
              >机器码生成下载</a
            >
          </div>

          <van-field
            name="checkboxGroup"
            label="申请产品"
            :rules="[{ required: true, message: '请选择申请产品' }]"
          >
            <template #input>
              <van-checkbox-group v-model="ruleForm.type">
                <van-checkbox name="TerraExplorer Pro" shape="square"
                  >TerraExplorer Pro</van-checkbox
                >
                <van-checkbox name="PhotoMesh" shape="square"
                  >PhotoMesh</van-checkbox
                >
                <van-checkbox name="TerraBuilder" shape="square"
                  >TerraBuilder</van-checkbox
                >
                <van-checkbox name=" CityBuilder" shape="square">
                  CityBuilder</van-checkbox
                >
                <van-checkbox name="SGS " shape="square">SGS </van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>
          <!-- <van-field
            v-model="ruleForm.type"
            name="申请产品"
            label="申请产品"
            placeholder="申请产品"
            :rules="[{ required: true, message: '请填写申请产品' }]"
          /> -->

          <van-field
            v-model="ruleForm.ReasonApplication"
            name="申请理由"
            label="申请理由"
            placeholder="申请理由"
            :rules="[{ required: true, message: '请填写申请理由' }]"
          />
          <van-field
            v-model="ruleForm.Note"
            name="备注"
            label="备注"
            placeholder="备注"
          />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">
              申请提交
            </van-button>
          </div>
        </van-form>
      </van-tab>
      <van-tab title="软件下载" style="background:#fff">
        <div style="padding:20px;">
          <h3>
            <strong
              ><span style="color: rgb(51, 51, 51);"
                ><span style="font-size: medium;"
                  ><span style="font-family: Verdana;"
                    >SkylineGlobe V7：</span
                  ></span
                ></span
              ></strong
            >
          </h3>

          <br />
          <span style="color: blue; font-size: 15px;"
            ><a
              href="http://help.skylineglobe.cn/upload/SkylineSoft/TB7.0.0.zip"
              ><span style="color: rgb(51, 102, 255);"
                ><span style="font-size: medium;"
                  ><span style="color: rgb(0, 0, 255);">■ </span></span
                ></span
              ><span style="font-size: medium;"
                ><span style="color: rgb(0, 0, 255);"
                  >TerraBuilder v7.0下载</span
                ></span
              ></a
            ><span style="color: rgb(0, 0, 255);"><br /> </span></span
          ><a href="http://help.skylineglobe.cn/upload/SkylineSoft/CB7.0.0.zip"
            ><span style="font-size: medium;"
              ><span style="color: rgb(0, 0, 255);"><br /> </span></span></a
          ><a
            href="http://www.skylineglobe.com/skylineglobe/corporate/download/CB7.0.1.zip"
            ><span style="color: rgb(51, 102, 255);"
              ><span style="font-size: medium;"
                ><span style="color: rgb(0, 0, 255);">■&nbsp;</span></span
              ></span
            ><span style="font-size: medium;"
              ><span style="color: rgb(0, 0, 255);"
                >CityBuilder v7.0.1下载</span
              ></span
            ></a
          ><span style="color: rgb(0, 0, 255);"
            ><a
              href="http://www.skylineglobe.com/skylineglobe/corporate/download/TEPro7.0.1.zip"
              target="_blank"
              ><span style="font-size: medium;"
                ><span style="color: blue;"
                  ><br />
                  <br /> </span></span></a
            ><a
              href="http://help.skylineglobe.cn/upload/SkylineGlobeSoft/TEPro7.0.2.rar"
              target="_blank"
              ><span style="font-size: medium;"
                ><span style="color: blue;"
                  >■ TerraExplorer v7.0.2下载<br />
                  <br /> </span></span></a></span
          ><a
            href="http://help.skylineglobe.cn/upload/SkylineGlobeSoft/TEPro7.0.2.rar"
            target="_blank"
            ><span style="font-size: medium;"
              ><span style="color: blue;"
                >■ TerraExplorer v7.0.2 中文版下载</span
              ></span
            ></a
          ><span style="color: rgb(0, 0, 255);"
            ><a
              href="http://www.skylineglobe.com/skylineglobe/corporate/download/TEPro7.0.1.zip"
              target="_blank"
              ><span style="color: blue; font-size: 15px;"><br /> </span
              ><span style="font-size: medium;"
                ><span style="color: blue;"
                  ><br />
                  ■ SkylineGlobe Server v7.1.1 需要请联系</span
                ></span
              ></a
            ><a href="mailto:support@skylineglobe.cn"
              ><span style="font-size: medium;color:#000"
                >support@skylineglobe.cn</span
              ></a
            ><span style="font-size: medium;"
              ><span style="color: blue;">获取<br /> </span></span
          ></span>
          <h3>
            <span style="font-family: Verdana;"
              ><a
                href="http://www.skylineglobe.com/skylineglobe/corporate/download/TEPro7.0.1.zip"
                target="_blank"
                ><span style="font-size: medium;"
                  ><span style="color: blue;"
                    ><br />
                    <br /> </span></span></a></span
            ><strong
              ><span style="font-family: Verdana;"
                ><span style="color: rgb(51, 51, 51);"
                  ><span style="font-size: medium;"
                    >SkylineGlobe V6.6：</span
                  ></span
                ></span
              ></strong
            >
          </h3>
          <br />
          <a
            href="http://bbs.skylineglobe.cn/forum.php?mod=forumdisplay&amp;fid=36"
            target="_blank"
            ><span style="color: blue; font-size: 15px;">■</span></a
          ><a
            href="http://www.skylineglobe.com/skylineglobe/corporate/download/TEPro6.6.1.zip"
            target="_blank"
            ><span style="color: blue; font-size: 15px;">
              TerraExplorer v6.6.1 下载</span
            ></a
          ><br />
          <br />
          <a
            href="http://bbs.skylineglobe.cn/forum.php?mod=forumdisplay&amp;fid=36"
            target="_blank"
            ><span style="color: blue; font-size: 15px;">■</span></a
          ><a
            href="http://www.skylineglobe.com/skylineglobe/corporate/download/TEPro6.6.1-SC.zip"
            target="_blank"
            ><span style="color: blue; font-size: 15px;">
              TerraExplorer v6.6.1 中文版下载<br />
              <br /> </span
          ></a>
          <div>
            <a
              href="http://www.skylineglobe.com/SkylineGlobe/TerraExplorer/install/SkylineGlobeSetup.exe"
              target="_blank"
              ><span style="color: blue; font-size: 15px;"
                >■ TerraExplorer v6.6.1 view下载</span
              ></a
            >
          </div>
          <br />
          <a
            href="http://www.skylineglobe.com/skylineglobe/corporate/download/TB6.6.1.zip"
            target="_blank"
            ><span style="color: blue; font-size: 15px;"
              >■ TerraBuilder v6.6.1下载</span
            ></a
          ><br />
          <br />
          <a
            href="http://www.skylineglobe.com/skylineglobe/corporate/download/TG6.6.1.zip"
            target="_blank"
            ><span style="color: blue; font-size: 15px;"
              >■ TerraGate v6.6.1下载</span
            ></a
          >
          <br />
          <br />
          <a
            href="http://www.skylineglobe.com/skylineglobe/corporate/download/Fuser6.6.1.zip"
            target="_blank"
            ><span style="color: blue; font-size: 15px;"
              >■ Fuser v6.6.1下载</span
            ></a
          >&nbsp;<br />
          <br />
          <a
            href="http://help.skylineglobe.cn/upload/SkylineSoft/PM6.6.1.zip"
            target="_blank"
            ><span style="color: blue; font-size: 15px;"
              >■ PhotoMesh v6.6.1下载<br /> </span
            ><strong><br /> </strong
          ></a>
          <h3>
            <span style="font-size: medium;"
              ><span style="font-family: Verdana;"
                ><span style="color: rgb(51, 51, 51);"
                  ><strong>移动端</strong></span
                ></span
              ></span
            >
          </h3>
          <br />
          <a
            href="http://help.skylineglobe.cn/upload/apps/SmartEarth%20TE.apk"
            target="_blank"
            ><span style="color: blue; font-size: 15px;"
              >■ Android 下载</span
            ></a
          ><br />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      active: 2,
      ruleForm: {
        Companyname: "",
        Industry: "",
        CustomerAddress: "",
        ContactPerson: "",
        PhoneNumber: "",
        MailAddress: "",
        MachineCode: "",
        type: [],
        ReasonApplication: "",
        Note: ""
      },
      application: {
        host: "parallelword@terra-it.cn",
        title: "软件申请",
        content: ""
      }
    };
  },
  //方法集合
  methods: {
    async onSubmit() {
      var content = `<h3>试用申请单</h3><b>Customer Company Name公司名称:</b> ${
        this.ruleForm.Companyname
      } <br /><b>Industry行业:</b> ${
        this.ruleForm.Industry
      } <br /><b>Customer Address联系地址:</b>  ${
        this.ruleForm.CustomerAddress
      } <br /><b>Contact Person联系人姓名:</b> ${
        this.ruleForm.ContactPerson
      } <br /><b>Phone Number联系人电话:</b> ${
        this.ruleForm.PhoneNumber
      } <br /><b>Mail Address联系人邮箱:</b> ${
        this.ruleForm.MailAddress
      } <br /><b>Machine Code机器码:</b> ${
        this.ruleForm.MachineCode
      } <br /><b>Products申请产品:</b> ${this.ruleForm.type.toString()} <br /><b>申请理由:</b> ${
        this.ruleForm.ReasonApplication
      }<br /><b>备注:</b> ${this.ruleForm.Note}`;
      this.application.content = content;
      const { data: dt } = await this.$http.post(
        "general/sendMail",
        this.application
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }

      this.$toast.success("提交成功");
    }
  },
  created() {}
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  background: #f2f2f2;
  padding-bottom: 50px;
}
.title {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
.van-tabs {
  z-index: 0;
}
/deep/ .van-tabs__nav--line {
  width: 50%;
  background-color: transparent;
}
.van-checkbox {
  margin-bottom: 10px;
}
</style>
