<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>一站式接单详情</p>
    </div>
    <van-form>
      <p class="class_title">
        项目信息
      </p>
      <van-field
        readonly
        v-model="testData.name"
        name="项目名称"
        label="项目名称"
      />
      <!-- <van-field
        readonly
        v-model="editForm.projectBudget"
        name="项目预算"
        label="项目预算"
      /> -->
      <van-field
        readonly
        clickable
        name="calendar"
        :value="editForm.shijianDuan"
        label="获取时间"
      />

      <van-field
        readonly
        clickable
        name="area"
        :value="editForm.suozaidi"
        label="项目所在地"
      />

      <div v-if="qinxie">
        <p class="class_title">
          倾斜摄影
        </p>
        <van-field
          readonly
          v-model="editForm.QinXieSheYin.HangFeiFenBian"
          name="航飞分辨率"
          label="航飞分辨率"
        />
        <van-field
          readonly
          v-model="editForm.QinXieSheYin.HangSheBiLiChi"
          name="航摄比例尺"
          label="航摄比例尺"
        />
      </div>
      <div v-if="hangkong">
        <p class="class_title">
          航空摄影
        </p>
        <van-field
          readonly
          v-model="editForm.HangKongSheYing.HangFeiFenBian"
          name="航飞分辨率"
          label="航飞分辨率"
        />
        <van-field
          readonly
          v-model="editForm.HangKongSheYing.HangSheBiLiChi"
          name="航摄比例尺"
          label="航摄比例尺"
        />
      </div>
      <div v-if="weixin">
        <p class="class_title">
          卫星影像
        </p>
        <van-field
          readonly
          v-model="editForm.WeiXinYinXiang.HangFeiFenBian"
          name="航飞分辨率"
          label="航飞分辨率"
        />
        <van-field
          readonly
          clickable
          name="picker"
          :value="editForm.WeiXinYinXiang.HangSheBiLiChi"
          label="航摄比例尺"
        />
      </div>
      <div v-if="dianyun">
        <p class="class_title">
          航空点云
        </p>
        <van-field
          readonly
          v-model="editForm.HangKongDianYun.MiDu"
          name="平均点密度"
          label="平均点密度"
        />
        <van-field
          readonly
          v-model="editForm.HangKongDianYun.HangSheBiLiChi"
          name="航摄比例尺"
          label="航摄比例尺"
        />
      </div>
      <div v-if="dimian">
        <p class="class_title">
          地面slam
        </p>
        <van-field readonly v-model="slam" name="精度要求" label="精度要求" />
      </div>
      <div v-if="duoguang">
        <p class="class_title">
          多光谱遥感
        </p>
        <van-field
          readonly
          v-model="editForm.DuoGuang.HangFeiFenBian"
          name="航飞分辨率"
          label="航飞分辨率"
        />
        <van-field
          readonly
          v-model="editForm.DuoGuang.HangSheBiLiChi"
          name="航摄比例尺"
          label="航摄比例尺"
        />
      </div>
      <div v-if="hongre">
        <p class="class_title">
          热红外呈像
        </p>
        <van-field
          readonly
          v-model="editForm.HongRe.HangFeiFenBian"
          name="航飞分辨率"
          label="航飞分辨率"
        />
        <van-field
          readonly
          v-model="editForm.HongRe.HangSheBiLiChi"
          name="航摄比例尺"
          label="航摄比例尺"
        />
      </div>
      <p class="class_title">
        重叠度
      </p>
      <van-field
        readonly
        type="number"
        v-model="editForm.hangxiangchongdiedu"
        name="航向重叠度"
        label="航向重叠度"
      />
      <van-field
        readonly
        type="number"
        v-model="editForm.pangxiangchongdiedu"
        name="旁向重叠度"
        label="旁向重叠度"
      />
      <p class="class_title">
        像控要求
      </p>
      <van-field readonly name="radio" v-model="editForm.xiangkongyaoq">
      </van-field>
      <p class="class_title">
        数据成果
      </p>
      <van-field
        readonly
        type="textarea"
        autosize
        rows="1"
        name="caijishouduan"
        v-model="editForm.caijishouduan"
      >
      </van-field>
      <div v-if="this.editForm.hasOwnProperty('MonomerModel')">
        <p class="class_title">
          单体模型要求
        </p>
        <van-field
          readonly
          type="textarea"
          autosize
          rows="1"
          name="MonomerModel"
          v-model="editForm.MonomerModel"
        >
        </van-field>
      </div>
      <div v-if="this.editForm.hasOwnProperty('semanticModel')">
        <p class="class_title">
          语义模型要求
        </p>
        <van-field
          readonly
          type="textarea"
          autosize
          rows="1"
          name="semanticModel"
          v-model="editForm.semanticModel"
        >
        </van-field>
      </div>
      <div v-if="this.editForm.hasOwnProperty('BIM')">
        <p class="class_title">
          BIM要求
        </p>
        <van-field
          readonly
          type="textarea"
          autosize
          rows="1"
          name="BIM"
          v-model="editForm.BIM"
        >
        </van-field>
      </div>
      <p class="class_title">
        成果要求
      </p>
      <van-field
        readonly
        name="zuobiaoxi"
        label="坐标系要求"
        v-model="editForm.zuobiaoxi"
      >
      </van-field>

      <p class="class_title" id="ditucaiji">
        采集区域
      </p>
      <!-- <van-field
        readonly
        label="中心点经度"
        type="number"
        name="radio"
        v-model="editForm.lng"
      >
      </van-field>
      <van-field
        label="中心点纬度"
        readonly
        type="number"
        name="radio"
        v-model="editForm.lat"
      >
      </van-field>
      <van-field
        label="半径"
        readonly
        type="number"
        name="radio"
        v-model="editForm.radius"
      >
      </van-field> -->

      <van-field
        readonly
        name="DiMao"
        label="作业区域地貌"
        v-model="editForm.DiMao"
      >
      </van-field>
      <!-- <van-field
        readonly
        type="number"
        v-model="editForm.MianJi"
        name="作业区域面积"
        label="作业区域面积"
      /> -->
      <van-field
        readonly
        name="KongYu"
        label="空域服务"
        v-model="editForm.KongYu"
      >
      </van-field>
      <p class="class_title">
        付款方式
      </p>
      <van-field
        readonly
        name="付款方式"
        label="付款方式"
        v-model="editForm.paymentMode"
      >
      </van-field>
      <div
        v-if="
          'supplementaryAnnex' in this.editForm &&
            this.editForm.supplementaryAnnex != ''
        "
      >
        <p class="class_title">
          附件下载
        </p>
        <van-cell title="附件下载" is-link @click="download" />
      </div>

      <!-- <p class="class_title">
        备注
      </p>
      <van-field
        v-model="editForm.BeiZhu"
        name="备注"
        placeholder="请输入备注"
        type="textarea"
        autosize
        rows="1"
      /> -->
    </van-form>
    <p class="class_title" id="ditucaiji">
      报价
    </p>
    <van-form @submit="onSubmit">
      <van-field
        :disabled="shurukuangzhuangtai"
        v-model="dingjin"
        name="报价"
        label="报价(单位：￥)"
        placeholder="报价"
        :rules="[{ pattern, message: '不可输入负数且可保留小数点后两位' }]"
      />
      <van-field
        :disabled="shurukuangzhuangtai"
        v-model="quotationRemarks"
        name="报价备注"
        label="报价备注"
        placeholder="报价备注"
      />
      <div style="margin: 16px;">
        <van-button
          :disabled="shurukuangzhuangtai"
          round
          block
          type="info"
          native-type="submit"
        >
          接单报价
        </van-button>
      </div>
      <div style="margin: 16px;">
        <van-button
          @click="quxiaobaojia()"
          :disabled="shurukuangzhuangtai"
          round
          block
          type="danger"
          native-type="botton"
        >
          取消
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import areaList from "../../area";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/,
      shurukuangzhuangtai: true,
      baojiaForm: {
        dingjin: "",
        weikuan: ""
      },
      quotationRemarks: "",
      dingjin: "",
      orderText: {},
      caijishouduan: {},

      detailed: {},
      baojiaData: {
        function: "t_dispatch",
        id: "",
        jsonData: {
          status: ""
        }
      },
      yaoqinbaojiaData: {
        function: "t_dispatch",
        jsonData: {
          status: "",
          orderId: "",

          userId: ""
        }
      },
      yaoqinData: {},
      editForm: {
        MianJi: "",
        DiMao: "山地/高山地",
        xiangkongyaoq: "需要",

        suozaidi: "",
        projectName: "",
        projectBudget: "",
        shijian: "",
        KongYu: "需求方自理",
        BeiZhu: "",

        pangxiangchongdiedu: "",
        hangxiangchongdiedu: ""
      },

      qinxie: false,
      hangkong: false,
      weixin: false,
      dianyun: false,
      dimian: false,
      duoguang: false,
      hongre: false,
      orderData: {
        function: "t_order",
        condition: ""
      },
      testData: {}
    };
  },
  //方法集合
  methods: {
    download() {
      window.open(
        this.$http.defaults.baseURL +
          "file" +
          "/" +
          this.editForm.supplementaryAnnex
      );
    },
    quxiaobaojia() {
      this.$dialog
        .confirm({
          title: "确认要取消报价么"
        })
        .then(async () => {
          // eslint-disable-next-line no-prototype-builtins
          if (this.orderText.hasOwnProperty("dispatchId")) {
            this.baojiaData.id = this.orderText.dispatchId;
            this.baojiaData.jsonData.status = 5;
            this.baojiaData.jsonData = JSON.stringify(this.baojiaData.jsonData);
            const { data: dt } = await this.$http.post(
              "general/update",
              this.baojiaData
            );
            if (dt.status !== 200) {
              return this.$toast.fail(dt.msg);
            }
            this.$router.push("/user");
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.orderText.hasOwnProperty("dispatchId") == false) {
            this.yaoqinbaojiaData.jsonData.status = 5;

            this.yaoqinbaojiaData.jsonData = JSON.stringify(
              this.yaoqinbaojiaData.jsonData
            );
            const { data: dt } = await this.$http.post(
              "general/insert",
              this.yaoqinbaojiaData
            );

            if (dt.status !== 200) {
              return this.$toast.fail(dt.msg);
            }
            this.$router.push("/user");
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    onSubmit() {
      this.$dialog
        .confirm({
          title: "确认是否要报价"
        })
        .then(async () => {
          // eslint-disable-next-line no-prototype-builtins
          if (this.orderText.hasOwnProperty("dispatchId")) {
            this.baojiaData.id = this.orderText.dispatchId;
            // if (this.orderText.dispatchStatus == "可再次报价") {
            //   this.baojiaData.jsonData.data = {};
            //   this.baojiaData.jsonData.data.kaishishijian = this.detailed.kaishishijian;
            //   this.baojiaData.jsonData.data.baojia = this.dingjin;
            //   let backups = {
            //     baojiao: this.orderText.dispatchData.baojia
            //   };
            //   this.baojiaData.jsonData.data.backups = JSON.stringify(backups);
            // } else if (this.orderText.dispatchStatus == "可接单") {
            //   this.baojiaData.jsonData.data = {};
            //   this.baojiaData.jsonData.data.kaishishijian = this.detailed.kaishishijian;
            //   this.baojiaData.jsonData.data.baojia = this.dingjin;
            // }
            //旧
            // this.baojiaData.jsonData.data = {};
            // this.baojiaData.jsonData.data.kaishishijian = this.detailed.kaishishijian;
            // this.baojiaData.jsonData.data.baojia = this.dingjin;
            // this.baojiaData.jsonData.data.quotationRemarks = this.quotationRemarks;
            //-----------------------------------------------------------
            //新的
            this.baojiaData.jsonData.data = {
              quotationRemarks: this.quotationRemarks
            };
            this.baojiaData.jsonData.quote_price = this.dingjin;
            this.baojiaData.jsonData.status = 2;

            this.baojiaData.jsonData.data = JSON.stringify(
              this.baojiaData.jsonData.data
            );
            this.baojiaData.jsonData = JSON.stringify(this.baojiaData.jsonData);
            const { data: dt } = await this.$http.post(
              "general/update",
              this.baojiaData
            );
            if (dt.status !== 200) {
              return this.$toast.fail(dt.msg);
            }
            this.$router.push("/user");
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.orderText.hasOwnProperty("dispatchId") == false) {
            this.yaoqinbaojiaData.jsonData.status = 2;

            // this.yaoqinData.kaishishijian = this.detailed.kaishishijian;
            // if (this.orderText.dispatchStatus == "可再次报价") {
            //   this.yaoqinbaojiaData.jsonData.data = {};
            //   this.yaoqinbaojiaData.jsonData.data.kaishishijian = this.detailed.kaishishijian;

            //   this.yaoqinbaojiaData.jsonData.data.baojia = this.dingjin;
            //   let backups = {
            //     baojiao: this.orderText.dispatchData.baojia
            //   };
            //   this.yaoqinbaojiaData.jsonData.data.backups = JSON.stringify(
            //     backups
            //   );
            // } else if (this.orderText.dispatchStatus == "可接单") {
            //   this.yaoqinbaojiaData.jsonData.data = {};
            //   this.yaoqinbaojiaData.jsonData.data.kaishishijian = this.detailed.kaishishijian;

            //   this.yaoqinbaojiaData.jsonData.data.baojia = this.dingjin;
            // }
            //旧的
            // this.yaoqinbaojiaData.jsonData.data = {};
            // this.yaoqinbaojiaData.jsonData.data.kaishishijian = this.detailed.kaishishijian;
            // this.yaoqinbaojiaData.jsonData.data.baojia = this.dingjin;
            // this.yaoqinbaojiaData.jsonData.data.quotationRemarks = this.quotationRemarks;
            //-----------------------------------------------------------
            //新的
            this.yaoqinbaojiaData.jsonData.data = {
              quotationRemarks: this.quotationRemarks
            };
            this.yaoqinbaojiaData.jsonData.quote_price = this.dingjin;
            this.yaoqinbaojiaData.jsonData.data = JSON.stringify(
              this.yaoqinbaojiaData.jsonData.data
            );
            this.yaoqinbaojiaData.jsonData = JSON.stringify(
              this.yaoqinbaojiaData.jsonData
            );
            const { data: dt } = await this.$http.post(
              "general/insert",
              this.yaoqinbaojiaData
            );
            if (dt.status !== 200) {
              return this.$toast.fail(dt.msg);
            }
            this.$router.push("/user");
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    async conter() {
      const orderData = sessionStorage.getItem("orderData");

      if (orderData) {
        this.orderText = JSON.parse(orderData);
      } else {
        this.orderText = this.$route.params.data;

        sessionStorage.setItem(
          "orderData",
          JSON.stringify(this.$route.params.data)
        );
      }
      let userId = window.sessionStorage.getItem("userId");

      this.yaoqinbaojiaData.jsonData.userId = JSON.parse(userId);
      this.yaoqinbaojiaData.jsonData.orderId = this.orderText.id;

      if (this.orderText.price !== -1) {
        this.dingjin = this.orderText.price;
        if (typeof this.orderText.dispatchData == "string") {
          this.orderText.dispatchData = JSON.parse(this.orderText.dispatchData);
        }
        if ("dispatchData" in this.orderText) {
          if ("quotationRemarks" in this.orderText.dispatchData) {
            this.quotationRemarks = this.orderText.dispatchData.quotationRemarks;
          }
        }
      }

      if ("dispatchAgain" in this.orderText) {
        this.shurukuangzhuangtai = false;
      }
      this.orderData.condition = `userId=${this.orderText.userId} and id='${this.orderText.id}'`;
      const { data: dt } = await this.$http.post(
        "general/query",
        this.orderData
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }

      this.testData = dt.data[0];

      this.editForm = JSON.parse(this.testData.data);

      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("QinXieSheYin")) {
        this.qinxie = true;
        if (typeof this.editForm.QinXieSheYin == "string") {
          this.editForm.QinXieSheYin = JSON.parse(this.editForm.QinXieSheYin);
        }
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("HangKongSheYing")) {
        this.hangkong = true;

        if (typeof this.editForm.HangKongSheYing == "string") {
          this.editForm.HangKongSheYing = JSON.parse(
            this.editForm.HangKongSheYing
          );
        }
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("WeiXinYinXiang")) {
        this.weixin = true;
        if (typeof this.editForm.WeiXinYinXiang == "string") {
          this.editForm.WeiXinYinXiang = JSON.parse(
            this.editForm.WeiXinYinXiang
          );
        }
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("HangKongDianYun")) {
        this.dianyun = true;
        if (typeof this.editForm.HangKongDianYun == "string") {
          this.editForm.HangKongDianYun = JSON.parse(
            this.editForm.HangKongDianYun
          );
        }
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("DuoGuang")) {
        this.duoguang = true;
        if (typeof this.editForm.DuoGuang == "string") {
          this.editForm.DuoGuang = JSON.parse(this.editForm.DuoGuang);
        }
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("slam")) {
        this.dimian = true;
        this.editForm.slam;
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("HongRe")) {
        this.hongre = true;
        if (typeof this.editForm.HongRe == "string") {
          this.editForm.HongRe = JSON.parse(this.editForm.HongRe);
        }
      }
    }
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f2f2f2");
  },

  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  created() {
    this.conter();
  },
  mounted() {
    this.areaList = areaList;
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类

.box {
  background: #f2f2f2;
  padding-bottom: 50px;
}
.title {
  padding: 20px;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
.class_title {
  padding: 20px;
  margin-right: auto;
  //   font-weight: bold;
  color: #666666;
}
.van-checkbox {
  margin-bottom: 10px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.bm-view {
  width: 100%;
  height: 50%;
}
</style>
