<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>数据采集（通航有人机）合作伙伴认证</p>
    </div>
    <van-cell-group>
      <van-field
        label="飞行器类型"
        autosize
        type="textarea"
        rows="1"
        :value="ruleForm.flight"
        readonly
      />
      <van-field
        label="作业范围"
        autosize
        type="textarea"
        rows="1"
        :value="ruleForm.range"
        readonly
      />
      <van-field
        label="测绘行业案例"
        autosize
        type="textarea"
        rows="1"
        :value="ruleForm.mappingCase"
        readonly
      />
      <van-field
        label="固定空域"
        autosize
        type="textarea"
        rows="1"
        :value="ruleForm.airspace"
        readonly
      />
      <van-field
        label="固定空域"
        autosize
        type="textarea"
        rows="1"
        :value="ruleForm.airspaceDetails"
        readonly
      />
      <van-field
        label="可申请空域"
        :value="ruleForm.applicableAirspace"
        readonly
        autosize
        type="textarea"
        rows="1"
      />
      <van-field
        label="传感器"
        autosize
        type="textarea"
        rows="1"
        :value="ruleForm.sensor"
        readonly
      />
      <van-field label="电子邮箱" :value="ruleForm.Email" readonly />

      <van-field label="测绘资质证书" readonly>
        <template #input>
          <van-image
            v-for="(item, index) in mappingUrl"
            :key="index"
            @click="show_before_img"
            width="100"
            height="100"
            :src="item"
          />
        </template>
      </van-field>
      <van-field label="有人机证书" readonly>
        <template #input>
          <van-image
            v-for="(item, index) in mannedUrl"
            :key="index"
            @click="show_before_img"
            width="100"
            height="100"
            :src="item"
          />
        </template>
      </van-field>
    </van-cell-group>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      username: "",
      password: "",
      licenseuploader: [],
      backuploader: [],
      positiveuploader: [],
      shouquanhanuploadr: [],
      ruleForm: {
        yourenbohui: "",
        airspace: "",
        mappingCase: "",
        sensor: "",
        applicableAirspace: "",
        airspaceDetails: "",
        flight: "",
        airworthinessPermit: "",
        Email: "",
        range: ""
      },
      testData: {},
      mappingUrl: [],
      mannedUrl: []
    };
  },
  //方法集合
  methods: {
    //大图预览
    show_before_img() {
      this.instance_before = ImagePreview({
        images: [...this.mappingUrl, ...this.mannedUrl],

        closeable: true
      });
    },
    // show_before_img1() {
    //   this.instance_before = ImagePreview({
    //     images: this.uavUrl,

    //     closeable: true
    //   });
    // },
    async content() {
      let config = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      };
      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.testData = dt.data;
      // eslint-disable-next-line no-prototype-builtins
      if (this.testData.hasOwnProperty("data")) {
        this.testData.data = JSON.parse(this.testData.data);
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("manned")) {
          if (typeof this.testData.data.manned == "string") {
            this.testData.data.manned = JSON.parse(this.testData.data.manned);
          }

          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.hasOwnProperty("yourenbohui")) {
            this.ruleForm.yourenbohui = this.testData.data.yourenbohui;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.manned.hasOwnProperty("Email")) {
            this.ruleForm.Email = this.testData.data.manned.Email;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.manned.hasOwnProperty("airspace")) {
            this.ruleForm.airspace = this.testData.data.manned.airspace;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.manned.hasOwnProperty("mappingCase")) {
            this.ruleForm.mappingCase = this.testData.data.manned.mappingCase;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.manned.hasOwnProperty("sensor")) {
            this.ruleForm.sensor = this.testData.data.manned.sensor;
          }

          if (
            // eslint-disable-next-line no-prototype-builtins
            this.testData.data.manned.hasOwnProperty("applicableAirspace")
          ) {
            this.ruleForm.applicableAirspace = this.testData.data.manned.applicableAirspace;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.manned.hasOwnProperty("airspaceDetails")) {
            this.ruleForm.airspaceDetails = this.testData.data.manned.airspaceDetails;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.manned.hasOwnProperty("flight")) {
            this.ruleForm.flight = this.testData.data.manned.flight;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.manned.hasOwnProperty("airworthinessPermit")) {
            this.ruleForm.airworthinessPermit = this.testData.data.manned.airworthinessPermit;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.manned.hasOwnProperty("range")) {
            this.ruleForm.range = this.testData.data.manned.range;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (this.testData.data.manned.hasOwnProperty("mannedMappingUrl")) {
            if (this.testData.data.manned.mannedMappingUrl != "") {
              let mannedmapping = this.testData.data.manned.mannedMappingUrl
                .trim()
                .split(",");
              mannedmapping.forEach(e => {
                this.mappingUrl.push(
                  this.$http.defaults.baseURL + "file" + "/" + e
                );
              });
            }
          }

          if ("mannedUrl" in this.testData.data.manned) {
            if (this.testData.data.manned.mannedUrl != "") {
              let manned = this.testData.data.manned.mannedUrl
                .trim()
                .split(",");
              manned.forEach(e => {
                this.mannedUrl.push(
                  this.$http.defaults.baseURL + "file" + "/" + e
                );
              });
            }
          }
        }
      }
    }
  },
  created() {
    this.content();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  min-height: 100vh;
  max-height: auto;
  background: #f2f2f2;
  padding-bottom: 40px;
}
.title {
  padding: 20px;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
</style>
