import { render, staticRenderFns } from "./WholeoederDetails.vue?vue&type=template&id=4a719409&scoped=true&"
import script from "./WholeoederDetails.vue?vue&type=script&lang=js&"
export * from "./WholeoederDetails.vue?vue&type=script&lang=js&"
import style0 from "./WholeoederDetails.vue?vue&type=style&index=0&id=4a719409&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a719409",
  null
  
)

export default component.exports