/* eslint-disable no-prototype-builtins */ /* eslint-disable
no-prototype-builtins */
<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>企业认证</p>
    </div>
    <van-cell-group>
      <van-field
        label="未通过理由"
        v-if="this.bohuizhuantai"
        :value="userForm.bohuiyuanyin"
        readonly
      />
      <van-field label="企业名称" :value="userForm.companyName" readonly />
      <van-field label="企业地址" :value="userForm.companyAddress" readonly />
      <van-field label="联系人" :value="userForm.name" readonly />
      <van-field label="联系电话" :value="userForm.contacts" readonly />
      <van-field label="职位" :value="userForm.position" readonly />
      <van-field label="法人身份证" readonly>
        <template #input>
          <van-image
            @click="show_before_img"
            width="100"
            height="100"
            :src="positiveUrl"
          />
        </template>
      </van-field>
      <van-field label="法人身份证" readonly>
        <template #input>
          <van-image
            @click="show_before_img1"
            width="100"
            height="100"
            :src="backUrl"
          />
        </template>
      </van-field>
      <van-field label="营业执照" readonly>
        <template #input>
          <van-image
            @click="show_before_img2"
            width="100"
            height="100"
            :src="licenseUrl"
          />
        </template>
      </van-field>
      <van-field label="授权函" readonly>
        <template #input>
          <a
            :href="shouquanhanxiazai"
            target="_blank"
            download="原授权函"
            type="primary"
            style="color:#0086fd;"
            >点击下载</a
          >
        </template>
      </van-field>
    </van-cell-group>
    <div style="margin: 16px;margin-bottom:0">
      <van-button
        @click="renzheng"
        round
        block
        type="info"
        native-type="submit"
      >
        重新认证
      </van-button>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      username: "",
      password: "",
      licenseuploader: [],
      backuploader: [],
      positiveuploader: [],
      shouquanhanuploadr: [],
      personalForm: {
        contacts: "",
        companyAddress: "",
        name: "",
        license: "", //身份证
        position: "", //职位，
        licenseUpload: "", //营业执照
        backUpload: "", //身份信息照片背面
        positiveUpload: "", //身份信息照片
        shouquanhanUpload: "" //授权函
      },
      testData: {},
      bohuizhuantai: false,
      userForm: {
        contacts: "",
        companyAddress: "",
        name: "",
        position: "",
        bohuiyuanyin: ""
      },
      positiveUrl: "",
      backUrl: "",
      licenseUrl: "",
      shouquanhanxiazai: ""
    };
  },
  //方法集合
  methods: {
    //大图预览
    show_before_img() {
      this.instance_before = ImagePreview({
        images: this.positiveUrl,

        closeable: true
      });
    },
    show_before_img1() {
      this.instance_before = ImagePreview({
        images: this.backUrl,

        closeable: true
      });
    },
    show_before_img2() {
      this.instance_before = ImagePreview({
        images: this.licenseUrl,

        closeable: true
      });
    },
    async conter() {
      let config = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      };
      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.testData = dt.data;
      this.testData.auth.forEach(e => {
        if (e.type == 1) {
          if (e.status == 2) {
            this.bohuizhuantai = true;
          }
        }
      });

      this.userForm.companyName = this.testData.companyName;
      // eslint-disable-next-line no-prototype-builtins
      if (this.testData.hasOwnProperty("data")) {
        this.testData.data = JSON.parse(this.testData.data);

        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("bohuiyuanyin")) {
          this.userForm.bohuiyuanyin = this.testData.data.bohuiyuanyin;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("companyAddress")) {
          this.userForm.companyAddress = this.testData.data.companyAddress;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("name")) {
          this.userForm.name = this.testData.data.name;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("contacts")) {
          this.userForm.contacts = this.testData.data.contacts;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("position")) {
          this.userForm.position = this.testData.data.position;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("positiveUrl")) {
          this.positiveUrl =
            this.$http.defaults.baseURL +
            "file" +
            "/" +
            this.testData.data.positiveUrl;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("backUrl")) {
          this.backUrl =
            this.$http.defaults.baseURL +
            "file" +
            "/" +
            this.testData.data.backUrl;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("licenseUrl")) {
          this.licenseUrl =
            this.$http.defaults.baseURL +
            "file" +
            "/" +
            this.testData.data.licenseUrl;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("shouquanhanUrl")) {
          this.shouquanhanxiazai =
            this.$http.defaults.baseURL +
            "file" +
            "/" +
            this.testData.data.shouquanhanUrl;
        }
      }
    },
    renzheng() {
      this.$router.push({ path: "/enterprisemodify" });
    }
  },
  created() {
    this.conter();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  min-height: 100vh;
  max-height: auto;
  background: #f2f2f2;
  padding-bottom: 40px;
}
.title {
  padding: 20px;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
</style>
