<template>
  <div class="box">
    <van-row type="flex" align="center">
      <van-col span="6" class="title">
        <div class="tiao"></div>
        <p>数据处理</p>
      </van-col>
      <van-col span="6">
        <p
          style="color: #999999;font-size: 14px;padding-left:20px"
          @click="$router.push({ path: '/acquisitionProcessing' })"
        >
          一站式
        </p>
      </van-col>
      <van-col span="6">
        <p
          style="color: #999999;font-size: 14px;"
          @click="$router.push({ path: '/collection' })"
        >
          数据采集
        </p>
      </van-col>
      <van-col span="6">
        <p
          style="color: #999999;font-size: 14px;"
          @click="$router.push({ path: '/solve' })"
        >
          解决方案
        </p>
      </van-col>
    </van-row>
    <van-form @submit="onSubmit">
      <p class="class_title">
        项目信息
      </p>
      <van-field
        v-model="editForm.projectName"
        name="项目名称"
        label="项目名称"
        placeholder="项目名称"
        :rules="[{ required: true, message: '推荐输入“省-市-区-xxx平方公里”' }]"
      />
      <van-field
        v-model="editForm.projectBudget"
        name="项目预算"
        label="项目预算"
        placeholder="项目预算"
        :rules="[{ required: true, message: '请填写项目预算' }]"
      />
      <van-field
        readonly
        clickable
        name="calendar"
        :value="editForm.shijianDuan"
        label="获取时间"
        placeholder="点击选择日期"
        @click="showCalendar = true"
        :rules="[{ required: true, message: '请选择日期' }]"
      />
      <van-calendar type="range" v-model="showCalendar" @confirm="onConfirm" />
      <van-field
        readonly
        clickable
        name="area"
        :value="editForm.suozaidi"
        label="项目所在地"
        placeholder="选择项目所在地"
        @click="showArea = true"
        :rules="[{ required: true, message: '请选择项目所在地' }]"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="diquonConfirm"
          @cancel="showArea = false"
        />
      </van-popup>

      <p class="class_title">
        数据成果
      </p>
      <van-field
        name="caijishouduan"
        :rules="[{ required: true, message: '请选择数据成果' }]"
      >
        <template #input>
          <van-checkbox-group v-model="caijishouduan">
            <van-checkbox name="MESH(倾斜模型)" shape="square"
              >MESH(倾斜模型)</van-checkbox
            >
            <van-checkbox name="TDOM(直正摄影像)" shape="square"
              >TDOM(直正摄影像)</van-checkbox
            >
            <van-checkbox name="DSM(数字表面模型)" shape="square"
              >DSM(数字表面模型)</van-checkbox
            >
            <van-checkbox name="3DSHP(三维矢量数据)" shape="square"
              >3DSHP(三维矢量数据)</van-checkbox
            >
            <van-checkbox name="实景单体模型" shape="square"
              >实景单体模型</van-checkbox
            >
            <van-checkbox name="语义模型" shape="square">语义模型</van-checkbox>
            <van-checkbox name="DEM(数字高程模型)" shape="square"
              >DEM(数字高程模型)</van-checkbox
            >
            <van-checkbox name="DLG(数字线划图)" shape="square"
              >DLG(数字线划图)</van-checkbox
            >
            <van-checkbox name="BIM" shape="square">BIM</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field
        v-if="this.MonomerModelDisplay"
        v-model="MonomerModel"
        name="单体模型要求"
        label="单体模型要求"
        placeholder="单体模型要求"
        type="textarea"
        autosize
        rows="2"
        :rules="[{ required: true, message: '请填写单体模型要求' }]"
      />
      <van-field
        v-if="this.semanticModelDisplay"
        v-model="semanticModel"
        name="语义模型要求"
        label="语义模型要求"
        placeholder="语义模型要求"
        type="textarea"
        autosize
        rows="2"
        :rules="[{ required: true, message: '请填写语义模型要求' }]"
      />
      <van-field
        v-if="this.bimDisplay"
        v-model="BIM"
        name="BIM要求"
        label="BIM要求"
        placeholder="BIM要求"
        type="textarea"
        autosize
        rows="2"
        :rules="[{ required: true, message: '请填写BIM要求' }]"
      />
      <p class="class_title">
        成果要求
      </p>
      <van-field
        name="zuobiaoxi"
        label="坐标系要求"
        :rules="[{ required: true, message: '请选择成果要求' }]"
      >
        <template #input>
          <van-checkbox-group v-model="zuobiaoxi">
            <van-checkbox name="CGCS 2000" shape="square"
              >CGCS 2000</van-checkbox
            >
            <van-checkbox name="WGS 84" shape="square">WGS 84</van-checkbox>
            <van-checkbox name="地方坐坐标系" shape="square"
              >地方坐坐标系</van-checkbox
            >
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field name="chengguojindu" label="成果精度">
        <template #input>
          <van-checkbox-group v-model="chengguojindu">
            <van-checkbox name="1:500" shape="square">1:500</van-checkbox>
            <van-checkbox name="1:1000" shape="square">1:1000</van-checkbox>
            <van-checkbox name="1:2000" shape="square">1:2000</van-checkbox>
            <van-checkbox name="无" shape="square">无</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <p class="class_title">
        源数据信息
      </p>
      <van-field
        v-model="editForm.hangfeifenbianlv"
        name="航飞分辨率"
        label="航飞分辨率"
        placeholder="航飞分辨率"
        :rules="[{ required: true, message: '请填写航飞分辨率' }]"
      />
      <van-field
        v-model="editForm.zhaopianshuliang"
        name="照片数量"
        label="照片数量"
        placeholder="输入照片数量（单位张）"
        :rules="[{ required: true, message: '请输入照片数量' }]"
      />
      <van-field
        readonly
        clickable
        name="picker"
        :value="feixingqileixin"
        label="飞行器类型"
        placeholder="点击选择飞行器类型"
        @click="showPicker = true"
        :rules="[{ required: true, message: '请选择飞行器类型' }]"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="leixingonConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>

      <van-field
        v-model="feixingqixinhao"
        name="飞行型号"
        label="飞行型号"
        placeholder="飞行型号"
        :rules="[{ required: true, message: '请填写飞行型号' }]"
      />
      <van-field name="radio" label="控制点有无">
        <template #input>
          <van-radio-group v-model="kongzhidian" direction="horizontal">
            <van-radio name="无">无</van-radio>
            <van-radio name="有">有</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        v-if="kongzhidian == '有'"
        v-model="editForm.kongzhidian"
        name="控制点数量"
        label="控制点数量"
        placeholder="控制点数量"
        :rules="[{ required: true, message: '请填写控制点数量' }]"
      />
      <van-field
        v-model="editForm.hangsheyixinghao"
        name="航摄仪型号"
        label="航摄仪型号"
        placeholder="航摄仪型号"
        :rules="[{ required: true, message: '请填写航摄仪型号' }]"
      />
      <van-field
        v-model="editForm.xiangfukuandu"
        name="相幅宽度"
        label="相幅宽度"
        placeholder="相幅宽度"
        :rules="[{ required: true, message: '请填写相幅宽度' }]"
      />
      <van-field
        v-model="editForm.xiangfugaodu"
        name="相幅高度"
        label="相幅高度"
        placeholder="相幅高度"
        :rules="[{ required: true, message: '请填写相幅高度' }]"
      />
      <van-field
        v-model="editForm.chuanganqikuandu"
        name="传感器宽度"
        label="传感器宽度"
        placeholder="传感器宽度"
        :rules="[{ required: true, message: '请填写传感器宽度' }]"
      />
      <van-field
        v-model="editForm.jintoujiaoju"
        name="镜头焦距"
        label="镜头焦距"
        placeholder="镜头焦距"
        :rules="[{ required: true, message: '请填写镜头焦距' }]"
      />
      <van-field
        v-model="editForm.jintoushuliang"
        name="镜头数量"
        label="镜头数量"
        placeholder="镜头数量"
        :rules="[{ required: true, message: '请填写镜头数量' }]"
      />
      <van-field
        v-model="editForm.yingxianggeshi"
        name="影像格式"
        label="影像格式"
        placeholder="影像格式"
        :rules="[{ required: true, message: '请填写影像格式' }]"
      />

      <van-field name="bukongxuqiu" label="布控需求">
        <template #input>
          <van-radio-group v-model="editForm.bukongxuqiu">
            <van-radio name="需求方自理">需求方自理</van-radio>
            <van-radio name="由服务商布控并提供控制点"
              >由服务商布控并提供控制点</van-radio
            >
          </van-radio-group>
        </template>
      </van-field>
      <p class="class_title" id="ditucaiji">
        测区范围
      </p>
      <van-field name="radio" label="作业区域" readonly> </van-field>
      <van-field
        type="number"
        name="radio"
        v-model="zuobiaoform.center.lng"
        placeholder="请输入经度"
      >
      </van-field>
      <van-field
        type="number"
        name="radio"
        v-model="zuobiaoform.center.lat"
        placeholder="请输入纬度"
      >
      </van-field>
      <van-field
        type="number"
        name="radio"
        v-model="zuobiaoform.radius"
        placeholder="请输入半径(单位m)"
      >
      </van-field>
      <van-field name="radio">
        <template #input>
          <van-button
            size="small"
            @click="ditu"
            type="info"
            native-type="button"
            >地图选择</van-button
          >
        </template>
      </van-field>

      <van-field
        type="number"
        v-model="editForm.MianJi"
        name="作业区域面积"
        label="作业区域面积"
        placeholder="输入作业区面积（单位km²）"
        :rules="[{ required: true, message: '请填写作业区域面积' }]"
      />

      <p class="class_title">
        备注
      </p>
      <van-field
        v-model="editForm.BeiZhu"
        name="备注"
        placeholder="请输入备注"
        type="textarea"
        autosize
        rows="1"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          申请提交
        </van-button>
      </div>
    </van-form>
    <van-overlay :lock-scroll="true" :show="mapshow" @click="huidao">
      <div class="wrapper">
        <baidu-map
          class="bm-view"
          :center="zuobiaoform.center"
          :zoom="zoom"
          @ready="handler"
        >
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
          <bm-geolocation
            anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
            :showAddressBar="true"
            :autoLocation="true"
          ></bm-geolocation>
          <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
          <bm-circle
            :center="this.zuobiaoform.center"
            :radius="this.zuobiaoform.radius"
            fillColor="#0088ff"
            :fillOpacity="0.2"
            strokeColor="#0088ff"
            :strokeOpacity="0.2"
            :stroke-weight="2"
          ></bm-circle>
        </baidu-map>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import areaList from "../../area";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      feixingqileixin: "",
      feixingqixinhao: "",
      chengguojindu: [],
      zuobiaoxi: [],
      caijishouduan: [],
      editForm: {
        yingxianggeshi: "",
        jintoujiaoju: "",
        jintoushuliang: "",
        xiangfugaodu: "",
        xiangfukuandu: "",
        hangsheyixinghao: "",
        kongzhidian: "无",
        MianJi: "",
        hangfeifenbianlv: "",
        zhaopianshuliang: "",
        suozaidi: "",
        projectName: "",
        projectBudget: "",
        shijianDuan: "",
        bukongxuqiu: "需求方自理",
        BeiZhu: ""
      },
      upform: {
        function: "t_order",
        jsonData: {
          type: "2",
          name: "",
          // createTime: "",
          data: {},
          userId: ""
        }
      },
      locaPoint: null,
      center: { lng: 0, lat: 0 },
      zoom: 3,
      loading: true,
      zuobiaoform: {
        center: {
          lng: "",
          lat: ""
        },

        radius: ""
      },

      mapshow: false,
      kongzhidian: "无",
      weixinvalue: "",
      weixinvalue1: "",
      columns: [
        "固定翼（有人机）",
        "直升机（有人机）",
        "固定翼（无人机）",
        "旋翼机（无人机）",
        "直升机（无人机）",
        "复合翼（无人机）"
      ],

      showPicker: false,

      diqu: "",
      showArea: false,
      areaList: {},
      username: "",
      password: "",
      value: "",
      showCalendar: false,

      application: {
        host: "parallelword@terra-it.cn",
        title: "平行世界",
        content: ""
      },
      MonomerModel: "",
      semanticModel: "",
      BIM: "",
      bimDisplay: false,
      MonomerModelDisplay: false,
      semanticModelDisplay: false
    };
  },
  watch: {
    caijishouduan(newVal) {
      newVal.forEach(element => {
        if (element == "实景单体模型") {
          this.MonomerModelDisplay = true;
        }
        if (element == "语义模型") {
          this.semanticModelDisplay = true;
        }
        if (element == "BIM") {
          this.bimDisplay = true;
        }
      });
      if (newVal.indexOf("实景单体模型") == -1) {
        this.MonomerModelDisplay = false;
      }
      if (newVal.indexOf("语义模型") == -1) {
        this.semanticModelDisplay = false;
      }
      if (newVal.indexOf("BIM") == -1) {
        this.bimDisplay = false;
      }
    }
  },
  //方法集合
  methods: {
    huidao() {
      document.getElementById("ditucaiji").scrollIntoView();
      this.mapshow = false;
    },
    ditu() {
      document.getElementById("app").scrollIntoView();

      this.mapshow = true;
    },
    handler({ BMap, map }) {
      var _that = this;
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(
        r => {
          this.zuobiaoform.center = {
            lng: r.point.lng,
            lat: r.point.lat
          };
          this.locaPoint = r.point;
          // this.loading = false;
          // console.log(r);

          // _this.center.lng = r.point.lng;
          // _this.center.lat = r.point.lat;
          // alert("您的位置：" + r.point.lng + "," + r.point.lat);
        },
        {
          // 指示浏览器获取高精度的位置，默认为false
          enableHighAcuracy: true
        }
      );

      this.zoom = 15;
      var points = [];

      map.addEventListener("click", e => {
        map.clearOverlays();
        var point = new BMap.Point(e.point.lng, e.point.lat);
        points.push(new BMap.Point(e.point.lng, e.point.lat));
        if (points.length == 1) {
          var marker = new BMap.Marker(point);
          //给覆盖物添加右键菜单
          var removeMarker = function() {
            map.clearOverlays();
            points.length = 0;
          };
          //创建右键菜单
          var markerMenu = new BMap.ContextMenu();
          markerMenu.addItem(
            new BMap.MenuItem("删除", removeMarker.bind(marker))
          );
          map.addOverlay(marker);
          marker.addContextMenu(markerMenu);
        } else if (points.length == 2) {
          var radLat1 = (points[0].lat * Math.PI) / 180.0;
          var radLat2 = (points[1].lat * Math.PI) / 180.0;
          var a = radLat1 - radLat2;
          var b =
            (points[0].lng * Math.PI) / 180.0 -
            (points[1].lng * Math.PI) / 180.0;
          var s =
            2 *
            Math.asin(
              Math.sqrt(
                Math.pow(Math.sin(a / 2), 2) +
                  Math.cos(radLat1) *
                    Math.cos(radLat2) *
                    Math.pow(Math.sin(b / 2), 2)
              )
            );
          s = s * 6378137.0; // 取WGS84标准参考椭球中的地球长半径(单位:m)
          s = Math.round(s * 10000) / 10000;
          var circle = new BMap.Circle(points[0], s, {
            strokeColor: "#0088ff",
            fillColor: "#0088ff",
            strokeWeight: 2,
            strokeOpacity: 0.2,
            fillOpacity: 0.2
          }); //创建圆
          map.addOverlay(circle);
          // console.log(Object.prototype.toString.call(circle.getRadius()));
          _that.zuobiaoform.radius = circle.xa;
          _that.zuobiaoform.center = circle.point;
          // _that.zuobiaoform.radius = circle.getCenter();
          // _that.zuobiaoform.center = circle.getRadius();
          points.length = 0;
        }
      });
    },

    leixingonConfirm(value) {
      this.feixingqixinhao = value;
      this.showPicker = false;
    },
    diquonConfirm(values) {
      this.editForm.suozaidi = values
        .filter(item => !!item)
        .map(item => item.name)
        .join("");
      this.showArea = false;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.editForm.shijianDuan = `${this.formatDate(
        start
      )} , ${this.formatDate(end)}`;
      this.showCalendar = false;
    },
    async onSubmit() {
      let feixinqixingxi = {
        feixingqileixin: this.feixingqileixin,
        feixingqixinhao: this.feixingqixinhao
      };
      if (this.MonomerModelDisplay) {
        this.editForm.MonomerModel = this.MonomerModel;
      }
      if (this.semanticModelDisplay) {
        this.editForm.semanticModel = this.semanticModel;
      }
      if (this.bimDisplay) {
        this.editForm.BIM = this.BIM;
      }
      this.editForm.chengguojindu = this.chengguojindu.toString();
      this.editForm.lat = this.zuobiaoform.center.lat;
      this.editForm.lng = this.zuobiaoform.center.lng;
      this.editForm.zuobiaobanjin = this.zuobiaoform.radius;
      this.editForm.zuobiaoxi = this.zuobiaoxi.toString();
      this.editForm.caijishouduan = this.caijishouduan.toString();
      this.editForm.feixinqixingxi = JSON.stringify(feixinqixingxi);
      this.upform.jsonData.name = this.editForm.projectName;
      this.upform.jsonData.data = this.editForm;
      this.upform.jsonData.data = JSON.stringify(this.upform.jsonData.data);
      this.upform.jsonData = JSON.stringify(this.upform.jsonData);
      const { data: dt } = await this.$http.post("order/insert", this.upform);
      // console.log(dt);
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.submit();
      this.$router.push("/user");
    },
    async submit() {
      var content = `平行世界有新增订单申请待处理`;
      this.application.content = content;
      const { data: dt } = await this.$http.post(
        "general/sendMail",
        this.application
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
    },
    async conter() {
      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };

      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      //   dt.data.data = JSON.parse(dt.data.data);
      this.upform.jsonData.userId = dt.data.id;
    }
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f2f2f2");
  },

  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  created() {
    this.conter();
  },
  mounted() {
    this.areaList = areaList;
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类

.box {
  background: #f2f2f2;
  padding-bottom: 50px;
}
.title {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
.class_title {
  padding: 20px;
  margin-right: auto;
  //   font-weight: bold;
  color: #666666;
}
.van-checkbox {
  margin-bottom: 10px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.bm-view {
  width: 100%;
  height: 50%;
}
.van-radio {
  margin-bottom: 10px;
}
</style>
