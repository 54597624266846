<template>
  <div class="body_box">
    <h1 class="title">忘记密码</h1>
    <van-form @submit="onSubmit" autocomplete="off">
      <p class="form_title">手机号：</p>
      <van-field
        v-model="code.phone"
        name="手机号"
        placeholder="手机号"
        :rules="[{ pattern, message: '请输入11位手机号' }]"
      />
      <p class="form_title">手机验证码：</p>
      <van-field
        class="passw"
        v-model="code.code"
        name="手机验证码"
        placeholder="手机验证码"
      >
        <template #button>
          <van-button
            native-type="submit"
            :disabled="canClick"
            size="small"
            style="background-color:transparent;color:#0086FD;border-right:0;border-top:0;border-bottom:0;"
            >{{ btn_content }}</van-button
          >
        </template>
      </van-field>

      <div style="margin-top: 30px;margin-bottom: 30px;">
        <van-button
          class="van-buttonradius"
          @click="countDown"
          block
          type="info"
          native-type="button"
        >
          确认
        </van-button>
      </div>
    </van-form>
    <div>
      <van-button
        @click="$router.push({ path: '/registered' })"
        class="van-buttonradius"
        block
        style="background-color: rgba(0, 0, 0, 0.0);color:#fff;"
      >
        返回首页
      </van-button>
    </div>
    <div class="logo">
      <img src="../../assets/img/indexLogo.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      totalTime: 60,
      canClick: false, //添加canClick
      btn_content: "申请发送",
      username: "",
      password: "",
      pattern: /^[1][1,2,3,4,5,6,7,8,9,0][0-9]{9}$/,
      pwpattern: /\d{6}/,
      phone: {},
      code: {
        code: "",
        phone: ""
      }
    };
  },
  //方法集合
  methods: {
    async countDown() {
      if (this.code.phone == "" || this.code.code == "") {
        return this.$toast.fail("请填写手机号和验证码");
      }

      const { data: dt } = await this.$http.post(
        "general/checkCode",
        this.code
      );
      // console.log(dt);
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }

      if (dt.data === 1) {
        return this.$toast.fail("验证码过期");
      } else if (dt.data === 2) {
        return this.$toast.fail("验证码错误");
      }
      window.sessionStorage.setItem("username", this.code.phone);
      this.$router.push({
        path: "changePassword"
      });
    },
    validator(val) {
      return /1\d{10}/.test(val);
    },
    async onSubmit() {
      if (this.canClick) return;
      this.canClick = true;
      this.btn_content = this.totalTime + "s后重新发送";
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.btn_content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.btn_content = "重新发送验证码";
          this.totalTime = 60;
          this.canClick = false; //这里重新开启
        }
      }, 1000);
      this.phone.phone = this.code.phone;
      const { data: dt } = await this.$http.post(
        "general/sendCode",
        this.phone
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
    }
  },
  created() {}
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类

.body_box {
  height: 100vh;
  background: url("../../assets/img/beijin.png") no-repeat center;
  background-size: 100%;
  overflow: hidden;
  padding: 0 47px;
  .title {
    color: #fff;
    // font-size: 60px;
    font-weight: 100;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  // .van-field__control {
  //   background-color: rgba(255, 255, 255, 0.4) !important;
  // }
  .van-field {
    background: rgba(255, 255, 255, 0.2) !important;

    border-radius: 6px;

    margin-bottom: 40px;
  }
  .van-field:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  .van-cell::after {
    border-bottom: 0px;
  }
  .passw {
    margin-bottom: 40px;
  }
  .form_title {
    color: #fff;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .van-button {
    border-radius: 0px;
  }
  .van-buttonradius {
    border-radius: 6px;
  }
  .logo {
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 180px;
      height: 40px;
    }
  }
}
</style>
