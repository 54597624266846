<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>数据采集订单详情</p>
    </div>

    <van-form>
      <van-field
        v-if="this.testData.status == 2"
        readonly
        v-model="editForm.bohuiyuanyin"
        name="驳回原因"
        label="驳回原因"
      />
      <p class="class_title">
        项目信息
      </p>
      <van-field
        readonly
        v-model="testData.name"
        name="项目名称"
        label="项目名称"
      />
      <van-field
        readonly
        v-model="editForm.projectBudget"
        name="项目预算"
        label="项目预算"
      />
      <van-field
        readonly
        clickable
        name="calendar"
        :value="editForm.shijianDuan"
        label="时间段"
      />

      <van-field
        readonly
        clickable
        name="area"
        :value="editForm.suozaidi"
        label="项目所在地"
      />

      <div v-if="qinxie">
        <p class="class_title">
          倾斜摄影
        </p>
        <van-field
          readonly
          v-model="editForm.QinXieSheYin.HangFeiFenBian"
          name="航飞分辨率"
          label="航飞分辨率"
        />
        <van-field
          readonly
          v-model="editForm.QinXieSheYin.HangSheBiLiChi"
          name="航摄比例尺"
          label="航摄比例尺"
        />
      </div>
      <div v-if="hangkong">
        <p class="class_title">
          航空摄影
        </p>
        <van-field
          readonly
          v-model="editForm.HangKongSheYing.HangFeiFenBian"
          name="航飞分辨率"
          label="航飞分辨率"
        />
        <van-field
          readonly
          v-model="editForm.HangKongSheYing.HangSheBiLiChi"
          name="航摄比例尺"
          label="航摄比例尺"
        />
      </div>
      <div v-if="weixin">
        <p class="class_title">
          卫星影像
        </p>
        <van-field
          readonly
          v-model="editForm.WeiXinYinXiang.HangFeiFenBian"
          name="航飞分辨率"
          label="航飞分辨率"
        />
        <van-field
          readonly
          clickable
          name="picker"
          :value="editForm.WeiXinYinXiang.HangSheBiLiChi"
          label="航摄比例尺"
        />
      </div>
      <div v-if="dianyun">
        <p class="class_title">
          航空点云
        </p>
        <van-field
          readonly
          v-model="editForm.HangKongDianYun.MiDu"
          name="平均点密度"
          label="平均点密度"
        />
        <van-field
          readonly
          v-model="editForm.HangKongDianYun.HangSheBiLiChi"
          name="航摄比例尺"
          label="航摄比例尺"
        />
      </div>
      <div v-if="dimian">
        <p class="class_title">
          地面slam
        </p>
        <van-field
          readonly
          v-model="editForm.slam"
          name="精度要求"
          label="精度要求"
        />
      </div>
      <div v-if="duoguang">
        <p class="class_title">
          多光谱遥感
        </p>
        <van-field
          readonly
          v-model="editForm.DuoGuang.HangFeiFenBian"
          name="航飞分辨率"
          label="航飞分辨率"
        />
        <van-field
          readonly
          v-model="editForm.DuoGuang.HangSheBiLiChi"
          name="航摄比例尺"
          label="航摄比例尺"
        />
      </div>
      <div v-if="hongre">
        <p class="class_title">
          热红外呈像
        </p>
        <van-field
          readonly
          v-model="editForm.HongRe.HangFeiFenBian"
          name="航飞分辨率"
          label="航飞分辨率"
        />
        <van-field
          readonly
          v-model="editForm.HongRe.HangSheBiLiChi"
          name="航摄比例尺"
          label="航摄比例尺"
        />
      </div>
      <p class="class_title">
        重叠度
      </p>
      <van-field
        readonly
        type="number"
        v-model="editForm.hangxiangchongdiedu"
        name="航向重叠度"
        label="航向重叠度"
      />
      <van-field
        readonly
        type="number"
        v-model="editForm.pangxiangchongdiedu"
        name="旁向重叠度"
        label="旁向重叠度"
      />
      <p class="class_title">
        像控要求
      </p>
      <van-field readonly name="radio" v-model="editForm.xiangkongyaoq">
      </van-field>

      <p class="class_title">
        成果要求
      </p>
      <van-field
        readonly
        name="chengguoyanqiu"
        v-model="editForm.chengguoyanqiu"
      >
      </van-field>
      <p class="class_title" id="ditucaiji">
        采集区域
      </p>

      <van-field
        label="中心点经度"
        readonly
        type="number"
        name="radio"
        v-model="editForm.lng"
      >
      </van-field>
      <van-field
        label="中心点纬度"
        readonly
        type="number"
        name="radio"
        v-model="editForm.lat"
      >
      </van-field>
      <van-field
        label="半径"
        readonly
        type="number"
        name="radio"
        v-model="editForm.radius"
      >
      </van-field>

      <van-field
        readonly
        name="DiMao"
        label="作业区域地貌"
        v-model="editForm.DiMao"
      >
      </van-field>
      <van-field
        readonly
        type="number"
        v-model="editForm.MianJi"
        name="作业区域面积"
        label="作业区域面积"
      />
      <van-field
        readonly
        name="KongYu"
        label="空域服务"
        v-model="editForm.KongYu"
      >
      </van-field>
      <p class="class_title">
        备注
      </p>
      <van-field
        readonly
        v-model="editForm.BeiZhu"
        name="备注"
        type="textarea"
        autosize
        rows="1"
      />
    </van-form>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      editForm: {
        MianJi: "",
        DiMao: "山地/高山地",
        xiangkongyaoq: "需要",

        suozaidi: "",
        projectName: "",
        projectBudget: "",
        shijian: "",
        KongYu: "需求方自理",
        BeiZhu: "",

        pangxiangchongdiedu: "",
        hangxiangchongdiedu: ""
      },

      qinxie: false,
      hangkong: false,
      weixin: false,
      dianyun: false,
      dimian: false,
      duoguang: false,
      hongre: false,
      orderData: {
        function: "t_order",
        condition: ""
      },
      testData: {}
    };
  },
  //方法集合
  methods: {
    async conter() {
      this.orderData.condition = `userId=${this.$route.query.userId} and id='${this.$route.query.id}'`;
      const { data: dt } = await this.$http.post(
        "general/query",
        this.orderData
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }

      this.testData = dt.data[0];
      this.editForm = JSON.parse(this.testData.data);

      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("QinXieSheYin")) {
        this.qinxie = true;
        if (typeof this.editForm.QinXieSheYin == "string") {
          this.editForm.QinXieSheYin = JSON.parse(this.editForm.QinXieSheYin);
        }
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("HangKongSheYing")) {
        this.hangkong = true;

        if (typeof this.editForm.HangKongSheYing == "string") {
          this.editForm.HangKongSheYing = JSON.parse(
            this.editForm.HangKongSheYing
          );
        }
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("WeiXinYinXiang")) {
        this.weixin = true;
        if (typeof this.editForm.WeiXinYinXiang == "string") {
          this.editForm.WeiXinYinXiang = JSON.parse(
            this.editForm.WeiXinYinXiang
          );
        }
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("HangKongDianYun")) {
        this.dianyun = true;
        if (typeof this.editForm.HangKongDianYun == "string") {
          this.editForm.HangKongDianYun = JSON.parse(
            this.editForm.HangKongDianYun
          );
        }
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("DuoGuang")) {
        this.duoguang = true;
        if (typeof this.editForm.DuoGuang == "string") {
          this.editForm.DuoGuang = JSON.parse(this.editForm.DuoGuang);
        }
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("slam")) {
        this.dimian = true;
        this.editForm.slam;
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.editForm.hasOwnProperty("HongRe")) {
        this.hongre = true;
        if (typeof this.editForm.HongRe == "string") {
          this.editForm.HongRe = JSON.parse(this.editForm.HongRe);
        }
      }
      // let config = {
      //   headers: {
      //     "Content-Type": "application/x-www-form-urlencoded"
      //   }
      // };

      // const { data: dt } = await this.$http.post(
      //   "user/getUserInfo",
      //   "",
      //   config
      // );

      // if (dt.status !== 200) {
      //   return this.$toast.fail(dt.msg);
      // }
      // //   dt.data.data = JSON.parse(dt.data.data);
      // this.upform.jsonData.userId = dt.data.id;
    }
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f2f2f2");
  },

  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  created() {
    this.conter();
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类

.box {
  background: #f2f2f2;
  padding-bottom: 50px;
}
.title {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
.class_title {
  padding: 20px;
  margin-right: auto;
  //   font-weight: bold;
  color: #666666;
}
.van-checkbox {
  margin-bottom: 10px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.bm-view {
  width: 100%;
  height: 50%;
}
</style>
