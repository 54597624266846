<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>邀请码</p>
    </div>

    <van-form @submit="onSubmit">
      <van-field
        type="number"
        v-model="invitationForm.invitation"
        name="邀请码输入"
        label="邀请码输入"
        placeholder="邀请码输入"
        :rules="[{ required: true, message: '请填写邀请码' }]"
      />

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          查询
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      invitationForm: {
        invitation: ""
      },
      yaoqinData: {
        column:
          "t_order.*,t_dispatch.id dispatchId,t_dispatch.status dispatchStatus,t_dispatch.quote_price price,t_dispatch.data dispatchData",
        // column:
        //   "t_order.*,t_dispatch.id dispatchId,t_dispatch.status dispatchStatus,t_dispatch.data dispatchData",
        function: "",
        condition: ""
      },
      testData: {},
      id: ""
    };
  },
  //方法集合
  methods: {
    async onSubmit() {
      this.yaoqinData.condition = `t_order.id=${this.invitationForm.invitation} and t_order.userId!=${this.id}`;
      const { data: dt } = await this.$http.post(
        "general/query",
        this.yaoqinData
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      if (dt.data.length == 0) {
        return this.$toast.fail("未查询到订单");
      }
      let arr = dt.data[0];

      var time = arr.dispatchEndTime
        .replace(/-/g, "/")
        .substring(0, arr.dispatchEndTime.length - 2);
      if ("dispatchStatus" in arr) {
        if (arr.dispatchStatus == 1 || arr.dispatchStatus == 6) {
          if (Date.parse(time) > new Date()) {
            arr.dispatchAgain = true;
          }
        }
      } else {
        if (Date.parse(time) > new Date()) {
          arr.dispatchAgain = true;
        }
      }
      // if (arr.dispatchStatus == 1) {
      //   arr.dispatchStatus = "可接单";
      // } else if (arr.dispatchStatus == 2) {
      //   arr.dispatchStatus = "已报价";
      // } else if (arr.dispatchStatus == 3) {
      //   arr.dispatchStatus = "报价失败";
      // } else if (arr.dispatchStatus == 4) {
      //   arr.dispatchStatus = "已接单";
      // } else if (arr.dispatchStatus == 5) {
      //   arr.dispatchStatus = "已取消";
      // } else if (arr.dispatchStatus == 6) {
      //   arr.dispatchStatus = "可接单";
      //   arr.dispatchAgain = 6;
      // }
      // if (Date.parse(arr.dispatchEndTime) > new Date()) {
      //   arr.dispatchAgain = 6;
      // }
      // console.log(arr);
      if (arr.type == 2) {
        this.$router.push({
          name: "ReceivingDealWith",
          params: {
            data: arr
          }
        });
      }
      if (arr.type == 1) {
        this.$router.push({
          name: "ReceivingCollection",
          params: {
            data: arr
          }
        });
      }
      if (arr.type == 3) {
        this.$router.push({
          name: "ReceivingWholeoe",
          params: {
            data: arr
          }
        });
      }
    },
    async getRouterData() {
      let config = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      };
      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.testData = dt.data;
      this.id = this.testData.id;

      this.yaoqinData.function = `t_order left join t_dispatch on t_dispatch.orderId = t_order.id and t_dispatch.userId=${this.id}`;
    }
  },
  created() {
    this.getRouterData();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  min-height: 100vh;
  max-height: auto;
  background: #f2f2f2;
}
.title {
  padding: 20px;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
</style>
