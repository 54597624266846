import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/global.css";
//ui
import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);
import "./rem";
// 引入axios并配置
import axios from "./http";
Vue.prototype.$http = axios;
//滚动
import scroll from "vue-seamless-scroll";
Vue.use(scroll);
//引用百度地图
import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
  ak: "GGgeneOhjoNUxBGHUvDWgYtvfDafvbTS"
});
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from, next) => {
  setTimeout(() => {
    // 此处粘贴百度统计复制过来的代码
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?03d3e05ee5683ab17e5b3e084c7f3aba";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }, 0);
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
