<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>数据处理合作伙伴</p>
    </div>
    <van-form @submit="onSubmit">
      <van-field
        readonly
        v-if="this.bohuizhuantai"
        v-model="chulibohui"
        name="驳回原因"
        label="驳回原因"
        placeholder="驳回原因"
        autosize
        type="textarea"
        rows="1"
      />
      <van-field
        :readonly="quxiao"
        v-model="personalForm.software"
        name="软件产品型号"
        label="软件产品型号"
        placeholder="软件产品型号"
        :rules="[{ required: true, message: '请填写软件产品型号' }]"
        autosize
        type="textarea"
        rows="1"
      />
      <van-field
        :readonly="quxiao"
        v-model="personalForm.Email"
        name="电子邮箱"
        label="电子邮箱"
        placeholder="电子邮箱"
        :rules="[{ required: true, message: '请填写电子邮箱' }]"
        autosize
        type="textarea"
        rows="1"
      />
      <div style="margin: 16px;" v-if="!this.quxiao">
        <van-button round block type="info" native-type="submit">
          {{ this.bohuizhuantai ? "重新提交" : "提交" }}
        </van-button>
      </div>
      <div style="margin: 16px;" v-if="this.quxiao">
        <van-button
          @click="chehui"
          round
          block
          type="info"
          native-type="button"
        >
          撤回认证
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      username: "",
      password: "",
      licenseuploader: [],
      backuploader: [],
      positiveuploader: [],
      shouquanhanuploadr: [],
      chulibohui: "",
      personalForm: {
        software: "",
        Email: ""
      },
      testData: {},
      bohuizhuantai: false,
      application: {
        host: "parallelword@terra-it.cn",
        title: "平行世界",
        content: ""
      },
      //上传认证
      companydate: {
        type: 3,
        userJsonData: { data: {} }
      },
      quxiao: false,
      revoke: {
        function: "t_auth",
        ids: ""
      }
    };
  },
  //方法集合
  methods: {
    async chehui() {
      const { data: dt } = await this.$http.post("general/delete", this.revoke);
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.$router.push("/user");
    },
    async submit() {
      var content = `平行世界有合作伙伴认证待处理`;
      this.application.content = content;
      const { data: dt } = await this.$http.post(
        "general/sendMail",
        this.application
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
    },
    async onSubmit() {
      this.testData.data.software = this.personalForm.software;
      this.testData.data.Email = this.personalForm.Email;
      this.companydate.userJsonData.data = this.testData.data;
      this.companydate.userJsonData.data = JSON.stringify(
        this.companydate.userJsonData.data
      );
      this.companydate.userJsonData = JSON.stringify(
        this.companydate.userJsonData
      );
      const { data: dt } = await this.$http.post("user/auth", this.companydate);
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.submit();
      this.$router.push("/user");
    },
    async content() {
      let config = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      };
      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.testData = dt.data;
      this.testData.auth.forEach(e => {
        if (e.type == 3) {
          if (e.status == 2) {
            this.bohuizhuantai = true;
          } else if (e.status == 1) {
            this.quxiao = true;
          }
          this.revoke.ids = e.id;
        }
      });
      // eslint-disable-next-line no-prototype-builtins
      if (this.testData.hasOwnProperty("data")) {
        this.testData.data = JSON.parse(this.testData.data);
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("chulibohui")) {
          this.chulibohui = this.testData.data.chulibohui;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("software")) {
          // console.log(this.testData.data.software);
          this.personalForm.software = this.testData.data.software;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("Email")) {
          this.personalForm.Email = this.testData.data.Email;
        }
      }
    }
  },
  created() {
    this.content();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  min-height: 100vh;
  max-height: auto;
  background: #f2f2f2;
  padding-bottom: 40px;
}
.title {
  padding: 20px;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
</style>
