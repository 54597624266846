<template>
  <div class="box">
    <van-row type="flex" align="center">
      <van-col span="6" class="title">
        <div class="tiao"></div>
        <p>解决方案</p>
      </van-col>
      <van-col span="6">
        <p
          style="color: #999999;font-size: 14px;padding-left:20px"
          @click="$router.push({ path: '/acquisitionProcessing' })"
        >
          一站式
        </p>
      </van-col>
      <van-col span="6">
        <p
          style="color: #999999;font-size: 14px;"
          @click="$router.push({ path: '/collection' })"
        >
          数据采集
        </p>
      </van-col>
      <van-col span="6">
        <p
          style="color: #999999;font-size: 14px;"
          @click="$router.push({ path: '/dealWith' })"
        >
          数据处理
        </p>
      </van-col>
    </van-row>
    <van-form @submit="onSubmit">
      <p class="class_title">
        方案类型
      </p>
      <van-field
        name="fanganleix"
        :rules="[{ required: true, message: '至少选择一个方案类型' }]"
      >
        <template #input>
          <van-checkbox-group v-model="fanganleix" direction="horizontal">
            <van-checkbox name="智慧城市" shape="square">智慧城市</van-checkbox>
            <van-checkbox name="公共安全" shape="square">公共安全</van-checkbox>
            <van-checkbox name="应急管理" shape="square">应急管理</van-checkbox>
            <van-checkbox name="自然资源" shape="square">自然资源</van-checkbox>
            <van-checkbox name="智慧水利" shape="square">智慧水利</van-checkbox>
            <van-checkbox name="智慧电力" shape="square">智慧电力</van-checkbox>
            <van-checkbox name="智慧能源" shape="square">智慧能源</van-checkbox>
            <van-checkbox name="智慧交通" shape="square">智慧交通</van-checkbox>
            <van-checkbox name="智慧工程" shape="square">智慧工程</van-checkbox>
            <van-checkbox name="智慧工厂" shape="square">智慧工厂</van-checkbox>
            <van-checkbox name="自动驾驶" shape="square">自动驾驶</van-checkbox>
            <van-checkbox name="智慧电信" shape="square">智慧电信</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <p class="class_title">
        需求说明
      </p>

      <van-field
        v-model="editForm.xiangmuyusuan"
        name="项目预算"
        label="项目预算"
        placeholder="项目预算"
        :rules="[{ required: true, message: '请填写项目预算' }]"
      />
      <van-field
        readonly
        clickable
        name="calendar"
        :value="editForm.xiangmuzhouqi"
        label="项目周期"
        placeholder="点击选择日期"
        @click="showCalendar = true"
      />
      <van-calendar type="range" v-model="showCalendar" @confirm="onConfirm" />
      <van-field
        readonly
        clickable
        name="area"
        :value="editForm.shengshi"
        label="项目所在地"
        placeholder="选择项目所在地"
        @click="showArea = true"
        :rules="[{ required: true, message: '请选择项目所在地' }]"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="diquonConfirm"
          @cancel="showArea = false"
        />
      </van-popup>

      <van-field
        type="number"
        v-model="editForm.zuoyemianji"
        name="作业面积"
        label="作业面积"
        placeholder="输入作业面积（单位km²）"
        :rules="[{ required: true, message: '请填写作业面积' }]"
      />

      <p class="class_title">
        备注
      </p>
      <van-field
        v-model="editForm.yaoqiu"
        name="备注"
        placeholder="请输入备注"
        type="textarea"
        autosize
        rows="1"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          申请提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import areaList from "../../area";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      areaList: {},
      showCalendar: false,
      showArea: false,
      fanganleix: [],
      editForm: {
        zuoyemianji: "",

        xiangmuzhouqi: "",

        xiangmuyusuan: "",
        shijianDuan: "",

        yaoqiu: ""
      },
      upform: {
        function: "t_order",
        jsonData: {
          type: 4,
          data: {},
          userId: ""
        }
      },

      application: {
        host: "parallelword@terra-it.cn",
        title: "平行世界",
        content: ""
      }
    };
  },
  //方法集合
  methods: {
    diquonConfirm(values) {
      this.editForm.shengshi = values
        .filter(item => !!item)
        .map(item => item.name)
        .join("");
      this.showArea = false;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.editForm.shijianDuan = `${this.formatDate(
        start
      )} , ${this.formatDate(end)}`;
      this.showCalendar = false;
    },
    async onSubmit() {
      this.editForm.fanganleix = this.fanganleix.toString();
      if (this.editForm.xiangmuzhouqi == "") {
        this.editForm.xiangmuzhouqi = "无";
      }
      this.upform.jsonData.name = this.editForm.projectName;
      this.upform.jsonData.data = this.editForm;
      this.upform.jsonData.data = JSON.stringify(this.upform.jsonData.data);
      this.upform.jsonData = JSON.stringify(this.upform.jsonData);
      const { data: dt } = await this.$http.post("order/insert", this.upform);
      // console.log(dt);
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.submit();
      this.$router.push("/user");
    },
    async submit() {
      var content = `平行世界有新增订单申请待处理`;
      this.application.content = content;
      const { data: dt } = await this.$http.post(
        "general/sendMail",
        this.application
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
    },
    async conter() {
      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };

      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      //   dt.data.data = JSON.parse(dt.data.data);
      this.upform.jsonData.userId = dt.data.id;
    }
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f2f2f2");
  },

  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  created() {
    this.conter();
  },
  mounted() {
    this.areaList = areaList;
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类

.box {
  background: #f2f2f2;
  padding-bottom: 50px;
}
.title {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
.class_title {
  padding: 20px;
  margin-right: auto;
  //   font-weight: bold;
  color: #666666;
}
.van-checkbox {
  margin-bottom: 10px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.bm-view {
  width: 100%;
  height: 50%;
}
</style>
