<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>数据处理订单详情</p>
    </div>

    <van-form>
      <van-field
        v-if="this.testData.status == 2"
        readonly
        v-model="editForm.bohuiyuanyin"
        name="驳回原因"
        label="驳回原因"
      />
      <p class="class_title">
        项目信息
      </p>
      <van-field
        readonly
        v-model="testData.name"
        name="项目名称"
        label="项目名称"
      />
      <van-field
        readonly
        v-model="editForm.projectBudget"
        name="项目预算"
        label="项目预算"
      />
      <van-field
        readonly
        clickable
        name="calendar"
        :value="editForm.shijianDuan"
        label="时间段"
      />

      <van-field
        readonly
        clickable
        name="area"
        :value="editForm.suozaidi"
        label="项目所在地"
      />

      <p class="class_title">
        数据成果
      </p>
      <van-field
        readonly
        type="textarea"
        autosize
        rows="1"
        name="caijishouduan"
        v-model="editForm.caijishouduan"
      >
      </van-field>
      <div v-if="this.editForm.hasOwnProperty('MonomerModel')">
        <p class="class_title">
          单体模型要求
        </p>
        <van-field
          readonly
          type="textarea"
          autosize
          rows="1"
          name="MonomerModel"
          v-model="editForm.MonomerModel"
        >
        </van-field>
      </div>
      <div v-if="this.editForm.hasOwnProperty('semanticModel')">
        <p class="class_title">
          语义模型要求
        </p>
        <van-field
          readonly
          type="textarea"
          autosize
          rows="1"
          name="semanticModel"
          v-model="editForm.semanticModel"
        >
        </van-field>
      </div>
      <div v-if="this.editForm.hasOwnProperty('BIM')">
        <p class="class_title">
          BIM要求
        </p>
        <van-field
          readonly
          type="textarea"
          autosize
          rows="1"
          name="BIM"
          v-model="editForm.BIM"
        >
        </van-field>
      </div>
      <p class="class_title">
        成果要求
      </p>
      <van-field
        readonly
        name="zuobiaoxi"
        label="坐标系要求"
        v-model="editForm.zuobiaoxi"
      >
      </van-field>
      <van-field
        readonly
        name="chengguojindu"
        label="成果精度"
        v-model="editForm.chengguojindu"
      >
      </van-field>
      <p class="class_title">
        源数据信息
      </p>
      <van-field
        readonly
        v-model="editForm.hangfeifenbianlv"
        name="航飞分辨率"
        label="航飞分辨率"
      />
      <van-field
        readonly
        v-model="editForm.zhaopianshuliang"
        name="照片数量"
        label="照片数量"
      />
      <van-field
        readonly
        clickable
        name="picker"
        :value="feixinqixingxi.feixingqileixin"
        label="飞行器类型"
      />

      <van-field
        readonly
        v-model="feixinqixingxi.feixingqixinhao"
        name="飞行型号"
        label="飞行型号"
      />
      <van-field name="radio" label="控制点有无" v-model="kongzhidian">
      </van-field>

      <van-field
        readonly
        v-if="kongzhidian == '有'"
        v-model="editForm.kongzhidian"
        name="控制点数量"
        label="控制点数量"
      />
      <van-field
        readonly
        v-model="editForm.hangsheyixinghao"
        name="航摄仪型号"
        label="航摄仪型号"
      />
      <van-field
        readonly
        v-model="editForm.xiangfukuandu"
        name="相幅宽度"
        label="相幅宽度"
      />
      <van-field
        readonly
        v-model="editForm.xiangfugaodu"
        name="相幅高度"
        label="相幅高度"
      />
      <van-field
        readonly
        v-model="editForm.chuanganqikuandu"
        name="传感器宽度"
        label="传感器宽度"
      />
      <van-field
        readonly
        v-model="editForm.jintoujiaoju"
        name="镜头焦距"
        label="镜头焦距"
      />
      <van-field
        readonly
        v-model="editForm.jintoushuliang"
        name="镜头数量"
        label="镜头数量"
      />
      <van-field
        readonly
        v-model="editForm.yingxianggeshi"
        name="影像格式"
        label="影像格式"
      />

      <van-field
        readonly
        name="bukongxuqiu"
        label="布控需求"
        v-model="editForm.bukongxuqiu"
      >
      </van-field>
      <p class="class_title" id="ditucaiji">
        测区范围
      </p>

      <van-field
        readonly
        type="number"
        label="中心点经度"
        name="radio"
        v-model="editForm.lng"
      >
      </van-field>
      <van-field
        readonly
        type="number"
        label="中心点纬度"
        name="radio"
        v-model="editForm.lat"
      >
      </van-field>
      <van-field
        readonly
        type="number"
        label="半径"
        name="radio"
        v-model="editForm.radius"
      >
      </van-field>

      <van-field
        readonly
        type="number"
        v-model="editForm.zuoyemianji"
        name="作业区域面积"
        label="作业区域面积"
      />

      <p class="class_title">
        备注
      </p>
      <van-field
        readonly
        v-model="editForm.BeiZhu"
        name="备注"
        type="textarea"
        autosize
        rows="1"
      />
    </van-form>
  </div>
</template>

<script>
import areaList from "../../area";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      editForm: {},

      zuobiaoform: {
        center: {
          lng: "",
          lat: ""
        },

        radius: ""
      },

      mapshow: false,
      kongzhidian: "无",
      weixinvalue: "",
      weixinvalue1: "",
      columns: [
        "固定翼（有人机）",
        "直升机（有人机）",
        "固定翼（无人机）",
        "旋翼机（无人机）",
        "直升机（无人机）",
        "复合翼（无人机）"
      ],

      showPicker: false,

      diqu: "",
      showArea: false,
      areaList: {},
      username: "",
      password: "",
      value: "",
      showCalendar: false,
      testData: {},
      orderData: {
        function: "t_order",
        condition: ""
      },
      feixinqixingxi: {}
    };
  },
  //方法集合
  methods: {
    async conter() {
      this.orderData.condition = `userId=${this.$route.query.userId} and id='${this.$route.query.id}'`;
      const { data: dt } = await this.$http.post(
        "general/query",
        this.orderData
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }

      this.testData = dt.data[0];
      this.editForm = JSON.parse(this.testData.data);
      if (typeof this.editForm.feixinqixingxi == "string") {
        this.feixinqixingxi = JSON.parse(this.editForm.feixinqixingxi);
      }
    }
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f2f2f2");
  },

  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  created() {
    this.conter();
  },
  mounted() {
    this.areaList = areaList;
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类

.box {
  background: #f2f2f2;
  padding-bottom: 50px;
}
.title {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
.class_title {
  padding: 20px;
  margin-right: auto;
  //   font-weight: bold;
  color: #666666;
}
.van-checkbox {
  margin-bottom: 10px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.bm-view {
  width: 100%;
  height: 50%;
}
</style>
