<template>
  <div class="box">
    <div class="tit_box">
      <p class="title">基本信息</p>
      <van-button size="small" @click="jiben">编辑</van-button>
    </div>

    <van-cell-group>
      <van-field :value="details.name" center readonly label="姓名">
      </van-field>
      <van-field :value="details.position" center readonly label="职位">
      </van-field>

      <van-field :value="details.license" center readonly label="身份证号">
      </van-field>
    </van-cell-group>
    <p class="title">安全信息</p>
    <van-cell-group>
      <van-field :value="userData.companyName" center readonly label="企业名称">
        <template #button>
          <van-button @click="qiye" size="small">编辑</van-button>
        </template>
      </van-field>

      <van-field :value="userData.username" center readonly label="账号">
        <template #button>
          <van-button @click="user" size="small">编辑</van-button>
        </template>
      </van-field>
      <van-field value="1234567" type="password" center readonly label="密码">
        <template #button>
          <van-button @click="passw" size="small">编辑</van-button>
        </template>
      </van-field>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      userData: {},
      details: {}
    };
  },
  //方法集合
  methods: {
    qiye() {
      this.$router.push({ path: "/companyName" });
    },
    jiben() {
      this.$router.push({ path: "/basic" });
    },
    user() {
      this.$router.push({ path: "/username" });
    },
    passw() {
      this.$router.push({ path: "/password" });
    },

    async conter() {
      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };

      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      //   dt.data.data = JSON.parse(dt.data.data);
      this.userData = dt.data;
      this.details = JSON.parse(this.userData.data);
    }
  },
  created() {
    this.conter();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  min-height: 100vh;
  max-height: auto;
  background: #f2f2f2;
}
.tit_box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  p {
    margin-right: auto;
  }
}
.title {
  padding: 20px;
  margin-right: auto;
  //   font-weight: bold;
  color: #666666;
}
.van-button {
  color: #2f9ffd;
  border: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
}
</style>
