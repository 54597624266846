<!--  -->
<template>
  <div class="body_box">
    <h1 class="title">用户登录</h1>
    <van-form @submit="onSubmit" autocomplete="off">
      <p class="form_title">手机号：</p>
      <van-field
        v-model="loginForm.username"
        name="手机号"
        placeholder="手机号"
        :rules="[{ pattern, message: '请输入11位手机号' }]"
      />
      <p class="form_title">密码：</p>
      <van-field
        class="passw"
        v-model="loginForm.password"
        type="password"
        name="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div class="a_box">
        <router-link to="/registered" class="left">注册</router-link>
        <router-link to="/forgetPassword" class="right">忘记密码</router-link>
        <!-- <a href="" class="left">注册</a>
        <a href="">忘记密码</a> -->
      </div>
      <div style="margin-top: 30px;margin-bottom: 30px;">
        <van-button block type="info" native-type="submit">
          登录
        </van-button>
      </div>
      <div style="">
        <van-button
          @click="$router.push({ path: '/registered' })"
          native-type="button"
          block
          style="background-color: rgba(0, 0, 0, 0.0);color:#fff;"
        >
          返回首页
        </van-button>
      </div>
    </van-form>
    <div class="logo">
      <img src="../../assets/img/indexLogo.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loginForm: {
        username: "", // 用户名称
        password: "" // 用户密码
      },

      pattern: /^[1][1,2,3,4,5,6,7,8,9,0][0-9]{9}$/,
      pwpattern: /\d{6}/
    };
  },
  //方法集合
  methods: {
    async onSubmit() {
      const { data: dt } = await this.$http.post("user/login", this.loginForm);
      // console.log(dt);

      if (dt.data.status == 1) {
        return this.$toast.fail("账号或密码错误");
      }
      if (dt.data.status == 0) {
        return this.$toast.fail("登陆名不存在");
      }
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }

      window.sessionStorage.setItem("userId", dt.data.data);
      this.$router.push({ path: "/index" });
    }
  },
  created() {}
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.body_box {
  height: 100vh;
  background: url("../../assets/img/beijin.png") no-repeat center;
  background-size: 100%;
  overflow: hidden;
  padding: 0 47px;
  .title {
    color: #fff;
    // font-size: 60px;
    font-weight: 100;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .van-field {
    background: rgba(255, 255, 255, 0.2);

    border-radius: 6px;

    margin-bottom: 40px;
  }
  .van-cell:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  .van-cell::after {
    border-bottom: 0px;
  }
  .passw {
    margin-bottom: 40px;
  }
  .form_title {
    color: #fff;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .a_box {
    display: flex;
    justify-content: flex-end;

    .right {
      color: #0086fd;

      font-size: 24px;
    }
    .left {
      color: #0086fd;

      font-size: 24px;
      margin-right: auto;
    }
  }
  .van-button {
    border-radius: 6px;
  }
  .logo {
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 180px;
      height: 40px;
    }
  }
}
</style>
