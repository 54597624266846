import { render, staticRenderFns } from "./AcquisitionProcessing.vue?vue&type=template&id=4c6fa0ab&scoped=true&"
import script from "./AcquisitionProcessing.vue?vue&type=script&lang=js&"
export * from "./AcquisitionProcessing.vue?vue&type=script&lang=js&"
import style0 from "./AcquisitionProcessing.vue?vue&type=style&index=0&id=4c6fa0ab&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c6fa0ab",
  null
  
)

export default component.exports