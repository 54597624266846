<template>
  <div id="app">
    <loading v-show="LOADING"></loading>
    <router-view />
    <div class="service">
      <a
        target="_blank"
        href="https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97d52153902442cae61eb7b26b1c2076783896df28e9825d213063317b58f878db0dd90fc4ef44e23084797473abe9eb84"
      >
        <img src="./assets/img/fuwutubiao.png" alt="" />
      </a>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Loading from "./views/loading.vue";
export default {
  name: "App",
  computed: {
    ...mapState(["LOADING"])
  },
  components: {
    Loading
  }
};
</script>
<style lang="less">
.service {
  width: 96px;
  height: 96px;
  position: fixed;
  bottom: 20%;
  right: 30px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
