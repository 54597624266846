<template>
  <div class="box">
    <p class="title">账号修改</p>
    <van-form @submit="onSubmit" autocomplete="off">
      <van-field
        label="新手机号"
        v-model="code.phone"
        name="新手机号"
        placeholder="新手机号"
        :rules="[{ pattern, message: '请输入11位手机号' }]"
      />

      <van-field
        label="验证码"
        class="passw"
        v-model="code.code"
        name="手机验证码"
        placeholder="手机验证码"
      >
        <template #button>
          <van-button
            native-type="submit"
            :disabled="canClick"
            size="small"
            style="background-color:transparent;color:#0086FD;border-right:0;border-top:0;border-bottom:0;"
            >{{ btn_content }}</van-button
          >
        </template>
      </van-field>

      <div style="margin: 16px;">
        <van-button
          round
          @click="countDown"
          block
          type="info"
          native-type="button"
        >
          确认
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      pattern: /^[1][1,2,3,4,5,6,7,8,9,0][0-9]{9}$/,
      code: { code: "", phone: "" },
      totalTime: 60,
      canClick: false, //添加canClick
      btn_content: "申请发送",
      udate: {
        function: "t_user",
        id: "",
        jsonData: { data: {} }
      }
    };
  },
  //方法集合
  methods: {
    async updated() {
      let username = {};
      username.username = this.code.phone;
      this.udate.jsonData = JSON.stringify(username);
      const { data: dt } = await this.$http.post("general/update", this.udate);
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.$router.push({ path: "/userDetails" });
    },
    async countDown() {
      const { data: dt } = await this.$http.post(
        "general/checkCode",
        this.code
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }

      if (dt.data === 1) {
        return this.$toast.fail("验证码过期");
      } else if (dt.data === 2) {
        return this.$toast.fail("验证码错误");
      }
      this.updated();
    },
    async onSubmit() {
      if (this.canClick) return;
      this.canClick = true;
      this.btn_content = this.totalTime + "s后重新发送";
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.btn_content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.btn_content = "重新发送验证码";
          this.totalTime = 60;
          this.canClick = false; //这里重新开启
        }
      }, 1000);
      this.phone.phone = this.code.phone;
      const { data: dt } = await this.$http.post(
        "general/sendCode",
        this.phone
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
    },
    async conter() {
      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };

      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.udate.id = dt.data.id;
    }
  },
  created() {
    this.conter();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  min-height: 100vh;
  max-height: auto;
  background: #f2f2f2;
}
.title {
  padding: 20px;
  margin-right: auto;
  //   font-weight: bold;
  color: #666666;
}
</style>
