import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/login/Login.vue";
import Registered from "../views/login/Registered.vue";
import ChangePassword from "../views/login/ChangePassword.vue";
import ForgetPassword from "../views/login/ForgetPassword.vue";
import ModifySuccess from "../views/login/ModifySuccess.vue";
import CompanyRegistered from "../views/login/CompanyRegistered.vue";
import Index from "../views/Index.vue";
import User from "../views/User.vue";
import UserDetails from "../views/users/UserDetails.vue";
import Enterprise from "../views/certification/Enterprise.vue";
import Enterprisemodify from "../views/certification/Enterprisemodify.vue";
import DataAcquisitionDrone from "../views/certification/DataAcquisitionDrone.vue";
import dataProcessing from "../views/certification/dataProcessing.vue";
import DataAcquisitionManned from "../views/certification/DataAcquisitionManned.vue";
import DataAcquisitionDronemodify from "../views/certification/DataAcquisitionDronemodify.vue";
import DataAcquisitionMannedmodify from "../views/certification/DataAcquisitionMannedmodify.vue";
import dataProcessingmodify from "../views/certification/dataProcessingmodify.vue";
import Basic from "../views/users/Basic.vue";
import CompanyName from "../views/users/CompanyName.vue";
import username from "../views/users/username.vue";
import password from "../views/users/password.vue";
import AcquisitionProcessing from "../views/order/AcquisitionProcessing.vue";
import Collection from "../views/order/Collection.vue";
import DealWith from "../views/order/DealWith.vue";
import Solve from "../views/order/Solve.vue";
import CollectionoederDetails from "../views/order/CollectionoederDetails.vue";
import WithorderDetails from "../views/order/WithorderDetails.vue";
import WholeoederDetails from "../views/order/WholeoederDetails.vue";
import ReceivingCollection from "../views/order/ReceivingCollection.vue";
import ReceivingWholeoe from "../views/order/ReceivingWholeoe.vue";
import ReceivingDealWith from "../views/order/ReceivingDealWith.vue";
import SoftwareDownload from "../views/SoftwareDownload.vue";
import TaiRuiCollege from "../views/TaiRuiCollege.vue";
import invitation from "../views/order/invitation.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/registered",
    name: "Registered",
    component: Registered
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: ChangePassword
  },
  {
    path: "/forgetPassword",
    name: "ForgetPassword",
    component: ForgetPassword
  },
  {
    path: "/modifySuccess",
    name: "ModifySuccess",
    component: ModifySuccess
  },
  {
    path: "/companyRegistered",
    name: "CompanyRegistered",
    component: CompanyRegistered
  },

  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "Index",
        component: Index
      },
      {
        path: "/user",
        name: "User",
        component: User
      },
      {
        path: "/userDetails",
        name: "UserDetails",
        component: UserDetails
      },
      {
        path: "/enterprise",
        name: "Enterprise",
        component: Enterprise
      },
      {
        path: "/enterprisemodify",
        name: "Enterprisemodify",
        component: Enterprisemodify
      },
      {
        path: "/dataAcquisitionDrone",
        name: "DataAcquisitionDrone",
        component: DataAcquisitionDrone
      },
      {
        path: "/dataProcessing",
        name: "dataProcessing",
        component: dataProcessing
      },
      {
        path: "/dataAcquisitionManned",
        name: "DataAcquisitionManned",
        component: DataAcquisitionManned
      },
      {
        path: "/dataProcessingmodify",
        name: "dataProcessingmodify",
        component: dataProcessingmodify
      },
      {
        path: "/dataAcquisitionMannedmodify",
        name: "DataAcquisitionMannedmodify",
        component: DataAcquisitionMannedmodify
      },
      {
        path: "/dataAcquisitionDronemodify",
        name: "DataAcquisitionDronemodify",
        component: DataAcquisitionDronemodify
      },
      {
        path: "/basic",
        name: "Basic",
        component: Basic
      },
      {
        path: "/companyName",
        name: "CompanyName",
        component: CompanyName
      },
      {
        path: "/username",
        name: "username",
        component: username
      },
      {
        path: "/password",
        name: "password",
        component: password
      },
      {
        path: "/acquisitionProcessing",
        name: "AcquisitionProcessing",
        component: AcquisitionProcessing
      },
      {
        path: "/collection",
        name: "Collection",
        component: Collection
      },
      {
        path: "/dealWith",
        name: "DealWith",
        component: DealWith
      },
      {
        path: "/solve",
        name: "Solve",
        component: Solve
      },
      {
        path: "/collectionoederDetails",
        name: "CollectionoederDetails",
        component: CollectionoederDetails
      },

      {
        path: "/wholeoederDetails",
        name: "WholeoederDetails",
        component: WholeoederDetails
      },
      {
        path: "/withorderDetails",
        name: "WithorderDetails",
        component: WithorderDetails
      },
      {
        path: "/receivingCollection",
        name: "ReceivingCollection",
        component: ReceivingCollection
      },
      {
        path: "/receivingDealWith",
        name: "ReceivingDealWith",
        component: ReceivingDealWith
      },
      {
        path: "/receivingWholeoe",
        name: "ReceivingWholeoe",
        component: ReceivingWholeoe
      },
      {
        path: "/softwareDownload",
        name: "SoftwareDownload",
        component: SoftwareDownload
      },
      {
        path: "/taiRuiCollege",
        name: "TaiRuiCollege",
        component: TaiRuiCollege
      },
      {
        path: "/invitation",
        name: "invitation",
        component: invitation
      }
    ]
  }
];

const router = new VueRouter({
  routes
});
// 路由守卫设置
router.beforeEach(function(to, from, next) {
  var token = window.sessionStorage.getItem("userId");
  if (token === null && to.path !== "/login") {
    if (
      to.path.includes("/changePassword") ||
      to.path.includes("/companyRegistered") ||
      to.path.includes("/forgetPassword") ||
      to.path.includes("/modifySuccess") ||
      to.path.includes("/registered") ||
      to.path.includes("/index") ||
      to.path.includes("/companyRegistered")
    ) {
      next();
    } else {
      return next("/login");
    }
  }
  next(); // 放行
});
export default router;
