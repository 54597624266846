<!--  -->
<template>
  <div>
    <van-nav-bar id="reset" @click-left="onClickLeft">
      <template #left>
        <img class="logo" src="../assets/img/logo.png" alt="" />
      </template>
      <template #right>
        <van-dropdown-menu id="quchu">
          <van-dropdown-item title="" ref="item">
            <template #title>
              <van-icon class="nav" name="wap-nav" size="24" />
            </template>
            <van-cell
              class="daohang"
              @click="onConfirm"
              title="个人中心"
              is-link
              to="user"
            />
            <van-cell
              class="daohang"
              @click="chuangjian"
              title="创建订单"
              is-link
            />

            <van-cell
              class="daohang"
              @click="xueyuan"
              title="泰瑞学院"
              is-link
            />
            <van-cell
              class="daohang"
              @click="ruanjian"
              title="软件中心"
              is-link
            />
            <van-cell
              class="daohang"
              @click="onConfirm"
              title="SmartEarth"
              is-link
              url="http://www.smartearth.cn/"
            />
          </van-dropdown-item>
        </van-dropdown-menu>
      </template>
    </van-nav-bar>
    <router-view />
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      token: window.sessionStorage.getItem("userId"),
      testData: {}
    };
  },
  //方法集合
  methods: {
    ruanjian() {
      if (this.token == null) {
        return this.$dialog
          .confirm({
            title: "当前未登录，请前往登录"
          })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      }
      this.$router.push("/softwareDownload");
      this.$refs.item.toggle();
    },
    xueyuan() {
      if (this.token == null) {
        return this.$dialog
          .confirm({
            title: "当前未登录，请前往登录"
          })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      }
      this.$router.push("/taiRuiCollege");
      this.$refs.item.toggle();
    },
    chuangjian() {
      if (this.token == null) {
        return this.$dialog
          .confirm({
            title: "当前未登录，请前往登录"
          })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      }
      if (this.testData.type == 0) {
        return this.$dialog
          .confirm({
            title: "当前登录状态为个人账号，请注册企业账号"
          })
          .then(() => {
            this.$router.push("/companyRegistered");
          })
          .catch(() => {});
      }

      this.$router.push("/acquisitionProcessing");
      this.$refs.item.toggle();
    },

    //登陆传输data
    async getRouterData() {
      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };

      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }

      this.testData = dt.data;

      this.testData.data = JSON.parse(this.testData.data);
      // console.log(this.testData);
      // // eslint-disable-next-line no-prototype-builtins
      // if (this.testData.hasOwnProperty("data")) {
      //   // eslint-disable-next-line no-prototype-builtins
      //   if (this.testData.data.hasOwnProperty("userhead")) {
      //     this.imgUrl =
      //       this.$http.defaults.baseURL +
      //       "file" +
      //       "/" +
      //       this.testData.data.userhead;
      //   }
      // }

      // if (this.testData.type == 0) {

      // }
    },
    onClickLeft() {
      this.$router.push("/index");
    },
    onClickRight() {},
    onConfirm() {
      this.$refs.item.toggle();
    }
  },
  created() {
    if (this.token !== null) {
      this.getRouterData();
    }
  },
  mounted() {
    this.$router.afterEach(() => {
      window.scrollTo(0, 0);
    });
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.van-nav-bar {
  background: #01152d;
}
#reset /deep/ .van-ellipsis {
  color: #fff !important;
}
#reset /deep/ .nav {
  color: #fff !important;
}
// #reset /deep/ .van-nav-bar__text {
//   color: #fff !important;
// }
#quchu {
  height: 100%;
  background: #01152d !important;
  /deep/ .van-dropdown-item {
    height: 100%;
  }
  /deep/ .van-dropdown-menu__bar {
    background-color: #01152d !important;
    box-shadow: 0 0 0;
    height: 100%;
  }
  .van-icon {
    color: #969799;
  }
}
/deep/ .daohang {
  text-align: left;
  background: #01152d;
  color: #fff;
}
/deep/ .van-dropdown-menu__title:after {
  content: none;
}
.logo {
  height: 40px;
}
/deep/.van-hairline--bottom::after {
  content: none;
}
</style>
