<template>
  <div class="body_box">
    <h1 class="title">忘记密码</h1>
    <van-form @submit="onSubmit" autocomplete="off">
      <p class="form_title">新密码：</p>
      <van-field
        type="password"
        v-model="password"
        name="新密码"
        placeholder="新密码"
        :rules="[{ pattern, message: '请填写不小于6位的数字和字母' }]"
      />
      <p class="form_title">重新输入：</p>
      <van-field
        type="password"
        v-model="checkPass"
        name="重新输入"
        placeholder="重新输入"
        :rules="[{ validator, message: '请输入相同的密码' }]"
      />

      <div style="margin-top: 30px;margin-bottom: 30px;">
        <van-button block type="info" native-type="submit">
          确认修改
        </van-button>
      </div>
    </van-form>
    <div class="logo">
      <img src="../../assets/img/indexLogo.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      checkPass: "",
      password: "",
      pattern: /^[a-zA-Z0-9]{6,}$/,
      passform: { password: "", username: "" }
    };
  },
  //方法集合
  methods: {
    validator(val) {
      return this.password == val;
    },
    async onSubmit() {
      console.log(window.sessionStorage.getItem("username"));
      this.passform.username = window.sessionStorage.getItem("username");
      this.passform.password = this.checkPass;
      const { data: dt } = await this.$http.post(
        "user/updatePassword",
        this.passform
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.$router.push("/modifySuccess");
    }
  },
  created() {}
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.body_box {
  height: 100vh;
  background: url("../../assets/img/beijin.png") no-repeat center;
  background-size: 100%;
  overflow: hidden;
  padding: 0 47px;
  .title {
    color: #fff;
    // font-size: 60px;
    font-weight: 100;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .van-field {
    background: rgba(255, 255, 255, 0.2);

    border-radius: 6px;

    margin-bottom: 40px;
  }
  .van-cell:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  .van-cell::after {
    border-bottom: 0px;
  }
  .passw {
    margin-bottom: 40px;
  }
  .form_title {
    color: #fff;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .van-button {
    border-radius: 6px;
  }
  .logo {
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 180px;
      height: 40px;
    }
  }
}
</style>
