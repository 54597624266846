<template>
  <div class="box">
    <p class="title">基本信息修改</p>
    <van-form @submit="onSubmit">
      <van-field
        v-model="details.name"
        name="姓名"
        label="姓名"
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="details.position"
        name="职位"
        label="职位"
        placeholder="职位"
        :rules="[{ required: true, message: '请填写职位' }]"
      />
      <van-field
        v-model="details.license"
        name="身份证号"
        label="身份证号"
        placeholder="身份证号"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          保存
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      position: "",
      license: "",
      name: "",
      udate: {
        function: "t_user",
        id: "",
        jsonData: { data: {} }
      },
      testData: {},
      details: {}
    };
  },
  //方法集合
  methods: {
    async onSubmit() {
      this.testData.data = this.details;
      //   this.testData.data.position = this.details.position;
      //   this.testData.data.license = this.details.license;
      //   this.testData.data.name = this.details.name;
      this.udate.jsonData.data = this.testData.data;
      this.udate.jsonData.data = JSON.stringify(this.udate.jsonData.data);
      this.udate.jsonData = JSON.stringify(this.udate.jsonData);
      const { data: dt } = await this.$http.post("general/update", this.udate);

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.$router.push({ path: "/userDetails" });
    },
    async conter() {
      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };

      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      //   dt.data.data = JSON.parse(dt.data.data);
      this.testData = dt.data;
      this.details = JSON.parse(this.testData.data);
      this.udate.id = this.testData.id;
    }
  },
  created() {
    this.conter();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  min-height: 100vh;
  max-height: auto;
  background: #f2f2f2;
}
.title {
  padding: 20px;
  margin-right: auto;
  //   font-weight: bold;
  color: #666666;
}
</style>
