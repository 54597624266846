import { render, staticRenderFns } from "./SoftwareDownload.vue?vue&type=template&id=2f60815e&scoped=true&"
import script from "./SoftwareDownload.vue?vue&type=script&lang=js&"
export * from "./SoftwareDownload.vue?vue&type=script&lang=js&"
import style0 from "./SoftwareDownload.vue?vue&type=style&index=0&id=2f60815e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f60815e",
  null
  
)

export default component.exports