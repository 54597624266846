<template>
  <div class="box">
    <p class="title">企业名称修改</p>
    <van-form @submit="onSubmit">
      <van-field
        v-model="companyName.companyName"
        name="企业名称"
        label="企业名称"
        placeholder="企业名称"
        :rules="[{ required: true, message: '请填写企业名称' }]"
      />

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          保存
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      companyName: { companyName: "" }
    };
  },
  //方法集合
  methods: {
    async conter() {
      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };

      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      //   dt.data.data = JSON.parse(dt.data.data);
      this.companyName.companyName = dt.data.companyName;
    },
    async onSubmit() {
      const { data: dt } = await this.$http.post(
        "user/updateCompanyName",
        this.companyName
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      if (dt.data == 1) {
        return this.$toast.fail("企业名称已存在");
      }
      this.$router.push({ path: "/userDetails" });
    }
  },
  created() {
    this.conter();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  min-height: 100vh;
  max-height: auto;
  background: #f2f2f2;
}
.title {
  padding: 20px;
  margin-right: auto;
  //   font-weight: bold;
  color: #666666;
}
</style>
