<template>
  <div class="">
    <div class="map">
      <img src="../assets/img/ditu.png" alt="" />
      <div class="list">
        <h4>已完成项目</h4>
        <div style="margin-bottom: 10px;">
          <span class="mingc">区域</span><span class="mingc">面积</span
          ><span class="mingc">日期</span>
        </div>
        <vue-seamless-scroll
          :class-option="optionHover"
          :data="listData"
          class="seamless-warp"
        >
          <ul class="item">
            <li v-for="(item, i) in listData" :key="i">
              <span class="mingc1">{{ item.name }}</span
              ><span class="mingc1">{{ item.mianji }}</span
              ><span class="mingc1">{{ item.shige }}</span>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    <div class="title">
      <img src="../assets/img/womenfuwu.png" alt="" />
    </div>
    <div class="conter">
      <div class="con_box">
        <van-row type="flex" justify="space-between">
          <van-col
            style="text-align: left;padding-left:10px;padding-top:10px;"
            span="12"
          >
            <img
              style="width:60%"
              src="../assets/img/3eed4220dd346c5bf60c286d66d3f8a.png"
              alt=""
            />
          </van-col>

          <van-col
            style="text-align: right;padding-right:10px;padding-top:20px;"
            span="12"
          >
            <span @click="caiji" style="color:#0099ff;font-size: 12px;">
              立即申请
            </span>
          </van-col>
        </van-row>
        <van-row
          align="center"
          type="flex"
          justify="space-between"
          style="padding-top:10px"
        >
          <van-col span="8" style="padding-bottom:10px">
            <img style="width:100%" src="../assets/img/juxing6.png" alt="" />
          </van-col>

          <van-col style="font-size: 12px;padding-right:10px" span="16">
            泰瑞数据采集业务连接1000+合作伙伴，覆盖全国238个城市，具备甲级航空数据测绘资质及训练有素的人员队伍。
          </van-col>
        </van-row>
      </div>
      <div class="con_box">
        <van-row align="center" type="flex" justify="space-between">
          <van-col
            style="text-align: left;padding-left:10px;padding-top:10px;"
            span="12"
          >
            <img
              style="width:60%"
              src="../assets/img/62280300fb2d7c2b7c384b41012035e.png"
              alt=""
            />
          </van-col>

          <van-col
            style="text-align: right;padding-right:10px;padding-top:20px;"
            span="12"
          >
            <span @click="chuli" style="color:#0099ff;font-size: 12px;">
              立即申请
            </span>
          </van-col>
        </van-row>
        <van-row type="flex" justify="space-between" style="padding-top:10px">
          <van-col span="8" style="padding-bottom:10px">
            <img style="width:100%" src="../assets/img/juxing8.png" alt="" />
          </van-col>

          <van-col style="font-size: 12px;padding-right:10px" span="16">
            专业计算中心遍布全国多地，拥有上千台计算节点。数据产品包括城市三维数据、地形三维数据、工业场景数据、自动驾驶场景数据等。
          </van-col>
        </van-row>
      </div>
      <div class="con_box">
        <van-row align="center" type="flex" justify="space-between">
          <van-col
            style="text-align: left;padding-left:10px;padding-top:10px;"
            span="12"
          >
            <img
              style="width:60%"
              src="../assets/img/fe8d152123cec7f5ca05dd60e0fc390.png"
              alt=""
            />
          </van-col>

          <van-col
            style="text-align: right;padding-right:10px;padding-top:20px;"
            span="12"
          >
            <span @click="jiejuefangan" style="color:#0099ff;font-size: 12px;">
              立即申请
            </span>
          </van-col>
        </van-row>
        <van-row type="flex" justify="space-between" style="padding-top:10px">
          <van-col span="8" style="padding-bottom:10px">
            <img style="width:100%" src="../assets/img/juxing7.png" alt="" />
          </van-col>

          <van-col style="font-size: 12px;padding-right:10px" span="16">
            面向城市数字孪生、工业数字孪生、出行数字孪生三个方向，覆盖城市综合治理、社区、交通、应急、水务等30余个细分行业。
          </van-col>
        </van-row>
      </div>
      <div class="con_box">
        <van-row align="center" type="flex" justify="space-between">
          <van-col
            style="text-align: left;padding-left:10px;padding-top:10px;"
            span="12"
          >
            <img
              style="width:60%"
              src="../assets/img/ac798c124aaf5a16f18c535c3b3d34a.png"
              alt=""
            />
          </van-col>

          <van-col
            style="text-align: right;padding-right:10px;padding-top:20px;"
            span="12"
          >
            <span @click="yizhanshi" style="color:#0099ff;font-size: 12px;">
              立即申请
            </span>
          </van-col>
        </van-row>
        <van-row type="flex" justify="space-between" style="padding-top:10px">
          <van-col span="8" style="padding-bottom:10px">
            <img style="width:100%" src="../assets/img/juxing5.png" alt="" />
          </van-col>

          <van-col style="font-size: 12px;padding-right:10px" span="16">
            本服务可享受从数据采集、数据生产和应用服务的一站式服务。涉及智慧城市、交通、应急、水务等30多个行业，全程无忧服务到底。
          </van-col>
        </van-row>
      </div>
      <div class="con_box">
        <van-row align="center" type="flex" justify="space-between">
          <van-col
            style="text-align: left;padding-left:10px;padding-top:10px;"
            span="12"
          >
            <img
              style="width:60%"
              src="../assets/img/6157e0f68389b550929be1d941693d5.png"
              alt=""
            />
          </van-col>

          <van-col
            style="text-align: right;padding-right:10px;padding-top:20px;"
            span="12"
          >
            <!-- <router-link style="color:#0099ff;font-size: 12px;" to="/"
              >立即申请</router-link
            > -->
            <span style="color:#8f8f8f;font-size: 12px;" @click="kaifa"
              >立即申请</span
            >
          </van-col>
        </van-row>
        <van-row type="flex" justify="space-between" style="padding-top:10px">
          <van-col span="8" style="padding-bottom:10px">
            <img style="width:100%" src="../assets/img/juxing4.png" alt="" />
          </van-col>

          <van-col style="font-size: 12px;padding-right:10px" span="16">
            与100余各军地空域及有关主管部门保持紧密的沟通与协作，为您提供快速、便捷的空域申请与数据合法化服务。
            （筹备中）
          </van-col>
        </van-row>
      </div>
      <div class="con_box">
        <van-row align="center" type="flex" justify="space-between">
          <van-col
            style="text-align: left;padding-left:10px;padding-top:10px;"
            span="12"
          >
            <img
              style="width:60%"
              src="../assets/img/d8e905190dae059684780f7d4d3b8f7.png"
              alt=""
            />
          </van-col>

          <van-col
            style="text-align: right;padding-right:10px;padding-top:20px;"
            span="12"
          >
            <!-- <router-link style="color:#0099ff;font-size: 12px;" to="/"
              >立即申请</router-link
            > -->
            <span style="color:#8f8f8f;font-size: 12px;" @click="kaifa"
              >立即申请</span
            >
          </van-col>
        </van-row>
        <van-row type="flex" justify="space-between" style="padding-top:10px">
          <van-col span="8" style="padding-bottom:10px">
            <img style="width:100%" src="../assets/img/juxing2.png" alt="" />
          </van-col>

          <van-col style="font-size: 12px;padding-right:10px" span="16">
            集合多家保险品牌推出覆盖大飞机及无人机航测的多种航空保险服务，为企业客户提供一站式保险解决方案。（筹备中）
          </van-col>
        </van-row>
      </div>
      <div class="con_box">
        <van-row align="center" type="flex" justify="space-between">
          <van-col
            style="text-align: left;padding-left:10px;padding-top:10px;"
            span="12"
          >
            <img
              style="width:60%"
              src="../assets/img/fc3335d8b8bc7a89ccd1cb48d811bd6.png"
              alt=""
            />
          </van-col>

          <van-col
            style="text-align: right;padding-right:10px;padding-top:20px;"
            span="12"
          >
            <router-link
              style="color:#0099ff;font-size: 12px;"
              to="/taiRuiCollege"
              >立即申请</router-link
            >
          </van-col>
        </van-row>
        <van-row type="flex" justify="space-between" style="padding-top:10px">
          <van-col span="8" style="padding-bottom:10px">
            <img style="width:100%" src="../assets/img/juxing1.png" alt="" />
          </van-col>

          <van-col style="font-size: 12px;padding-right:10px" span="16">
            推出SmartEarth数字孪生系列软件推出直播课，集合资深技术专家，推出面向智慧城市、工业互联网、智能驾驶三大领域的精品小班课。
          </van-col>
        </van-row>
      </div>
      <div class="con_box">
        <van-row align="center" type="flex" justify="space-between">
          <van-col
            style="text-align: left;padding-left:10px;padding-top:10px;"
            span="12"
          >
            <img
              style="width:60%"
              src="../assets/img/edd7003864b257baae9a38bbd84a4f9.png"
              alt=""
            />
          </van-col>

          <van-col
            style="text-align: right;padding-right:10px;padding-top:20px;"
            span="12"
          >
            <router-link
              style="color:#0099ff;font-size: 12px;"
              to="/softwareDownload"
              >立即申请</router-link
            >
          </van-col>
        </van-row>
        <van-row type="flex" justify="space-between" style="padding-top:10px">
          <van-col span="8" style="padding-bottom:10px">
            <img style="width:100%" src="../assets/img/juxing3.png" alt="" />
          </van-col>

          <van-col style="font-size: 12px;padding-right:10px" span="16">
            SmartEarth时空大数据重建工具，可以进行实景三维重建、单体化、语义化等。可视化管理支持模型的检索、定位、浏览等操作。
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="hezuo">
      <div class="tit">
        <img src="../assets/img/hezuohuoban.png" alt="" />
      </div>
      <div class="qiye">
        <img src="../assets/img/dibuLOGO.5489b7e8.png" alt="" />
      </div>
    </div>
    <div class="footer">
      <ul class="list--unstyled">
        <li>
          {{ COPYRIGHT }}
          <a target="_blank" href="./tpls/license.jpg">营业执照 </a>
        </li>
        <li>
          <span>地址：北京市海淀区奥北科技园领智中心B座5层</span>
        </li>
        <li>
          <span style="margin-right:5px">邮编:100192 |</span>
          <span style="margin-left:5px;margin-right:5px"
            >电话：(010)64845922&nbsp;&nbsp;64846766 |</span
          >
          <span style="margin-left:5px;margin-right:5px"
            >传真：(010)64845989 |</span
          >
          <span style="margin-left:5px;">E-mail:info@terra-it.cn </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      testData: {},
      username: "",

      listData: [],
      token: window.sessionStorage.getItem("userId"),
      COPYRIGHT: ""
    };
  },
  computed: {
    optionHover() {
      return {
        hoverStop: false,
        step: 0.5
      };
    }
  },
  //方法集合
  methods: {
    yizhanshi() {
      if (this.token == null) {
        return this.$dialog
          .confirm({
            title: "当前未登录，请前往登录"
          })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      }
      if (this.testData.type == 0) {
        return this.$dialog
          .confirm({
            title: "当前登录状态为个人账号，请注册企业账号"
          })
          .then(() => {
            this.$router.push("/companyRegistered");
          })
          .catch(() => {});
      }
      this.$router.push("/acquisitionProcessing");
    },
    jiejuefangan() {
      if (this.token == null) {
        return this.$dialog
          .confirm({
            title: "当前未登录，请前往登录"
          })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      }
      if (this.testData.type == 0) {
        return this.$dialog
          .confirm({
            title: "当前登录状态为个人账号，请注册企业账号"
          })
          .then(() => {
            this.$router.push("/companyRegistered");
          })
          .catch(() => {});
      }
      this.$router.push("/solve");
    },
    chuli() {
      if (this.token == null) {
        return this.$dialog
          .confirm({
            title: "当前未登录，请前往登录"
          })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      }
      if (this.testData.type == 0) {
        return this.$dialog
          .confirm({
            title: "当前登录状态为个人账号，请注册企业账号"
          })
          .then(() => {
            this.$router.push("/companyRegistered");
          })
          .catch(() => {});
      }
      this.$router.push("/dealWith");
    },
    caiji() {
      if (this.token == null) {
        return this.$dialog
          .confirm({
            title: "当前未登录，请前往登录"
          })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      }
      if (this.testData.type == 0) {
        return this.$dialog
          .confirm({
            title: "当前登录状态为个人账号，请注册企业账号"
          })
          .then(() => {
            this.$router.push("/companyRegistered");
          })
          .catch(() => {});
      }

      this.$router.push("/collection");
    },
    //登陆传输data
    async getRouterData() {
      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };

      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );

      this.testData = dt.data;

      this.testData.data = JSON.parse(this.testData.data);
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      // console.log(this.testData);
      // // eslint-disable-next-line no-prototype-builtins
      // if (this.testData.hasOwnProperty("data")) {
      //   // eslint-disable-next-line no-prototype-builtins
      //   if (this.testData.data.hasOwnProperty("userhead")) {
      //     this.imgUrl =
      //       this.$http.defaults.baseURL +
      //       "file" +
      //       "/" +
      //       this.testData.data.userhead;
      //   }
      // }

      // if (this.testData.type == 0) {

      // }
    },
    kaifa() {
      this.$toast("此功能正在快马加鞭的开发中，敬请期待");
    }
  },
  created() {
    if (this.token !== null) {
      this.getRouterData();
    }
    this.COPYRIGHT = COPYRIGHT;
    this.listData = PROJECT_JSON;
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.map {
  position: relative;
  img {
    width: 100%;
  }
  .list {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 366px;
    height: 90%;
    background: rgba(0, 30, 80, 0.5);
    border: 1px solid rgba(0, 100, 200, 0.5);

    h4 {
      color: #fff;
      font-weight: 500;
      margin: 10px;
      margin-left: 30px;
      margin-top: 30px;
    }
  }
  .seamless-warp {
    overflow: hidden;
    height: 50%;
  }
  .mingc {
    display: inline-block;
    color: #fff;
    font-size: 24px;
    // font-weight: 600;
    width: 33.3%;
    text-align: center;
  }
  .mingc1 {
    display: inline-block;
    color: #fff;
    // eslint-disable-next-line no-prototype-builtins
    font-size: 24px;

    width: 33.3%;
    text-align: center;
  }
}
.title {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  img {
    width: 80%;
  }
}
.conter {
  padding: 30px;
  .con_box {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 30px;
  }
}
.hezuo {
  background: #021c36;
  padding-top: 40px;
  padding-bottom: 40px;
  .tit {
    text-align: center;
    img {
      width: 80%;
    }
  }
  .qiye {
    margin-top: 20px;
    text-align: center;
    img {
      width: 80%;
    }
  }
}
.footer {
  background: #000;
  padding: 30px;
  li {
    color: #fff;
    font-size: 12px;
    margin-bottom: 10px;
  }
}
</style>
