<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>企业认证修改</p>
    </div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="personalForm.companyName"
        disabled
        name="企业名称"
        label="企业名称"
        placeholder="企业名称"
        :rules="[{ required: true, message: '请填写企业名称' }]"
      />
      <van-field
        v-model="personalForm.companyAddress"
        name="企业地址"
        label="企业地址"
        placeholder="企业地址"
        :rules="[{ required: true, message: '请填写企业地址' }]"
      />
      <van-field
        v-model="personalForm.name"
        name="联系人"
        label="联系人"
        placeholder="联系人"
        :rules="[{ required: true, message: '请填写联系人' }]"
      />
      <van-field
        v-model="personalForm.contacts"
        name="联系电话"
        label="联系电话"
        placeholder="联系电话"
        :rules="[{ required: true, message: '请填写联系电话' }]"
      />
      <van-field
        v-model="personalForm.position"
        name="职位"
        label="职位"
        placeholder="职位"
        :rules="[{ required: true, message: '请填写职位' }]"
      />
      <van-field name="uploader" label="营业执照">
        <template #input>
          <van-uploader
            v-model="licenseuploader"
            :max-count="1"
            :after-read="licenseafterRead"
          />
        </template>
      </van-field>
      <van-field name="uploader" label="身份证正面">
        <template #input>
          <van-uploader
            v-model="positiveuploader"
            :max-count="1"
            :after-read="positiveafterRead"
          />
        </template>
      </van-field>
      <van-field name="uploader" label="身份证反面">
        <template #input>
          <van-uploader
            v-model="backuploader"
            :max-count="1"
            :after-read="backafterRead"
          />
        </template>
      </van-field>
      <van-field name="uploader" label="授权函">
        <template #input>
          <van-uploader
            upload-icon="newspaper-o"
            accept="*"
            v-model="shouquanhanuploadr"
            :max-count="1"
            :after-read="shouquanhanafterRead"
          >
          </van-uploader>
        </template>
      </van-field>
      <div style="margin: 16px;margin-bottom:0">
        <van-button round block type="info" native-type="submit">
          重新提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      username: "",
      password: "",
      licenseuploader: [],
      backuploader: [],
      positiveuploader: [],
      shouquanhanuploadr: [],
      personalForm: {
        contacts: "",
        companyAddress: "",
        name: "",
        position: "" //职位，
      },
      positiveUrl: "",
      backUrl: "",
      licenseUrl: "",
      shouquanhanxiazai: "",
      testData: {},
      application: {
        host: "parallelword@terra-it.cn",

        title: "平行世界",
        content: ""
      },
      //上传认证
      companydate: {
        type: 1,
        userJsonData: { data: {} }
      }
    };
  },
  //方法集合
  methods: {
    //删除图片
    // pictureDelete(file) {
    //   if (file.file) {
    //     this.prj_property.forEach(e => {
    //       if (e.name === file.file.name) {
    //         this.prj_property.splice(e, 1);
    //       }
    //     });

    //     return true;
    //   } else {
    //     this.prj_property.forEach(e => {
    //       if (e.name === file.url) {
    //         this.prj_property.splice(e, 1);
    //       }
    //     });

    //     return true;
    //   }
    // },
    async conter() {
      let config = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      };
      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.testData = dt.data;
      this.testData.auth.forEach(e => {
        if (e.type == 1) {
          if (e.status == 2) {
            this.bohuizhuantai = true;
          }
        }
      });

      this.personalForm.companyName = this.testData.companyName;
      // eslint-disable-next-line no-prototype-builtins
      if (this.testData.hasOwnProperty("data")) {
        this.testData.data = JSON.parse(this.testData.data);

        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("bohuiyuanyin")) {
          this.personalForm.bohuiyuanyin = this.testData.data.bohuiyuanyin;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("companyAddress")) {
          this.personalForm.companyAddress = this.testData.data.companyAddress;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("name")) {
          this.personalForm.name = this.testData.data.name;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("contacts")) {
          this.personalForm.contacts = this.testData.data.contacts;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("position")) {
          this.personalForm.position = this.testData.data.position;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("positiveUrl")) {
          this.positiveuploader.push({
            url:
              this.$http.defaults.baseURL +
              "file" +
              "/" +
              this.testData.data.positiveUrl
          });
          // this.positiveuploader =
          //   this.$http.defaults.baseURL +
          //   "file" +
          //   "/" +
          //   this.testData.data.positiveUrl;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("backUrl")) {
          this.backuploader.push({
            url:
              this.$http.defaults.baseURL +
              "file" +
              "/" +
              this.testData.data.backUrl
          });
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("licenseUrl")) {
          this.licenseuploader.push({
            url:
              this.$http.defaults.baseURL +
              "file" +
              "/" +
              this.testData.data.licenseUrl
          });
        }
        // eslint-disable-next-line no-prototype-builtins
        // if (this.testData.data.hasOwnProperty("shouquanhanUrl")) {
        //   this.shouquanhanuploadr =
        //     this.$http.defaults.baseURL +
        //     "file" +
        //     "/" +
        //     this.testData.data.shouquanhanUrl;
        // }
      }
    },
    //授权函上传
    async shouquanhanafterRead(file) {
      let param = new FormData();
      param.append("file", file.file);
      let config = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      const { data: dt } = await this.$http.post(
        "general/upload",
        param,
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.shouquanhanUrl = dt.data.result;
      this.$toast.success("上传成功");
      // console.log(param.get("file"));
    },
    //身份背上传
    async backafterRead(file) {
      let param = new FormData();
      param.append("file", file.file);
      let config = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      const { data: dt } = await this.$http.post(
        "general/upload",
        param,
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.backUrl = dt.data.result;
      this.$toast.success("上传成功");
      // console.log(param.get("file"));
    },
    //身份正上传
    async positiveafterRead(file) {
      let param = new FormData();
      param.append("file", file.file);
      let config = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      const { data: dt } = await this.$http.post(
        "general/upload",
        param,
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.positiveUrl = dt.data.result;
      this.$toast.success("上传成功");
      // console.log(param.get("file"));
    },
    //营业执照上传图片
    async licenseafterRead(file) {
      // console.log(file.file);
      // file.status = "uploading";
      // file.message = "上传中...";

      let param = new FormData();
      param.append("file", file.file);
      let config = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      const { data: dt } = await this.$http.post(
        "general/upload",
        param,
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.licenseUrl = dt.data.result;
      this.$toast.success("上传成功");
      // console.log(param.get("file"));
    },
    async onSubmit() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.testData.hasOwnProperty("data")) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.testData.data.hasOwnProperty("beifen")) {
          this.testData.data.beifen = JSON.parse(this.testData.data.beifen);
          // delete this.testData.data.beifen;
          // this.testData.data.beifen = {};
          // this.testData.data.beifen = this.testData.data;
          this.testData.data.beifen.companyAddress = this.personalForm.companyAddress;
          this.testData.data.beifen.name = this.personalForm.name;
          this.testData.data.beifen.contacts = this.personalForm.contacts;
          this.testData.data.beifen.position = this.personalForm.position;
          this.testData.data.beifen.positiveUrl = this.positiveUrl;
          this.testData.data.beifen.backUrl = this.backUrl;
          this.testData.data.beifen.licenseUrl = this.licenseUrl;
          this.testData.data.beifen.shouquanhanUrl = this.shouquanhanUrl;
        } else {
          this.testData.data.beifen = {};
          this.testData.data.beifen.companyAddress = this.personalForm.companyAddress;
          this.testData.data.beifen.name = this.personalForm.name;
          this.testData.data.beifen.contacts = this.personalForm.contacts;
          this.testData.data.beifen.position = this.personalForm.position;
          this.testData.data.beifen.positiveUrl = this.positiveUrl;
          this.testData.data.beifen.backUrl = this.backUrl;
          this.testData.data.beifen.licenseUrl = this.licenseUrl;
          this.testData.data.beifen.shouquanhanUrl = this.shouquanhanUrl;
        }
      }
      this.companydate.userJsonData.data = this.testData.data;
      this.companydate.userJsonData.data.beifen = JSON.stringify(
        this.companydate.userJsonData.data.beifen
      );
      this.companydate.userJsonData.data = JSON.stringify(
        this.companydate.userJsonData.data
      );
      this.companydate.userJsonData = JSON.stringify(
        this.companydate.userJsonData
      );
      const { data: dt } = await this.$http.post("user/auth", this.companydate);

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      this.submit();
      this.$toast.success("提交成功");
      this.$router.push("/user");
    },
    async submit() {
      var content = `平行世界有合作伙伴认证待处理`;
      this.application.content = content;
      const { data: dt } = await this.$http.post(
        "general/sendMail",
        this.application
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
    }
  },
  created() {
    this.conter();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  min-height: 100vh;
  max-height: auto;
  background: #f2f2f2;
  padding-bottom: 40px;
}
.title {
  padding: 20px;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
</style>
