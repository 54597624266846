<template>
  <div class="box">
    <div class="head">
      <div class="user_box">
        <van-row type="flex" align="center">
          <van-col span="7">
            <div class="img_box">
              <img :src="this.userhead" />
            </div>
          </van-col>

          <van-col span="17">
            <van-row type="flex" align="center" style="margin-bottom:10px;">
              <van-col
                style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"
                span="20"
              >
                <span style="font-size: 24px;">{{ userData.companyName }}</span>
              </van-col>

              <van-col span="4" style="text-align: right;">
                <router-link
                  style="color:#0099ff;font-size: 12px;"
                  to="/userDetails"
                  >编辑</router-link
                >
              </van-col>
            </van-row>

            <van-row>
              <van-col span="10">
                <p class="nei1">{{ details.position }}</p>
                <p class="nei2">{{ userData.username }}</p>
              </van-col>

              <van-col span="14">
                <p class="nei1">{{ details.name }}</p>
                <p class="nei2">{{ RenZhen }}</p>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </div>
    </div>
    <van-tabs color="#0086FD" v-model="active" style="margin-top:30px;">
      <van-tab title="我要发单">
        <p class="title">企业认证</p>
        <div class="renzhen" @click="qiyerenzheng">
          <div
            style=" display: flex;justify-content: flex-end;align-items:center;"
          >
            <span class="title1">企业认证</span>
            <span class="title2">{{ RenZhenZhuangTai }}</span>
          </div>
          <p class="title3"><i>·</i> 可申请数据采集、数据处理服务</p>
          <!-- <p class="title3"><i>·</i> 可承接数据采集订单</p> -->
        </div>
        <div
          style="display: flex;justify-content: flex-end;align-items:center;padding-right:20px"
        >
          <p class="title">发单列表</p>
        </div>
        <div class="routerbox">
          <router-link
            style="color: #0086fd;font-size: 14px;"
            to="/acquisitionProcessing"
            >＋ 新建订单</router-link
          >
        </div>
        <van-list
          v-model="billingloading"
          :finished="billingfinished"
          finished-text="没有更多了"
          @load="billingonLoad"
        >
          <div v-for="(item, i) in billing" :key="i" style="margin-bottom:20px">
            <van-cell :title="item.name" :label="'编号：' + item.id">
              <div class="neirong3">
                <p>{{ item.status }}</p>
                <p>{{ item.createTime }}</p>
              </div>
            </van-cell>
            <van-row>
              <van-col span="12">
                <van-button
                  @click="fadandel(item)"
                  style="color:#FF0000;border-right: 1px solid #F3F3F3;"
                  icon="delete"
                  type="primary"
                  >删除</van-button
                >
              </van-col>
              <van-col span="12">
                <van-button
                  @click="fadanDetails(item)"
                  style="color:#0086FD"
                  icon="newspaper-o"
                  type="primary"
                  >查看</van-button
                >
              </van-col>
            </van-row>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="我要接单">
        <p class="title">合作伙伴认证</p>
        <div class="renzhen_box clearfix">
          <div class="renzhen" @click="wurenjirenzheng">
            <div
              style=" display: flex;justify-content: flex-end;align-items:center;"
            >
              <span class="title1">无人机合作伙伴</span>
              <span class="title2">{{ CaiJiWuRenJi }}</span>
            </div>
            <p class="title3"><i>·</i> 可申请本网提供的所有服务</p>
            <p class="title3"><i>·</i> 可承接无人机数据采集订单</p>
          </div>
          <div class="renzhen" @click="yourenjirenzheng">
            <div
              style=" display: flex;justify-content: flex-end;align-items:center;"
            >
              <span class="title1">有人机合作伙伴</span>
              <span class="title2">{{ CaiJiYouRenJi }}</span>
            </div>
            <p class="title3"><i>·</i> 可申请本网提供的所有服务</p>
            <p class="title3"><i>·</i> 可承接有人机数据采集订单</p>
          </div>
          <div class="renzhen" @click="chulirenzheng">
            <div
              style=" display: flex;justify-content: flex-end;align-items:center;"
            >
              <span class="title1">数据处理合作伙伴</span>
              <span class="title2">{{ ChuLiZhuangTai }}</span>
            </div>
            <p class="title3"><i>·</i> 可申请本网提供的所有服务</p>
            <p class="title3"><i>·</i> 可承接数据处理订单</p>
          </div>
        </div>

        <div
          style="display: flex;justify-content: flex-end;align-items:center;padding-right:20px"
        >
          <p class="title">接单列表</p>
        </div>
        <div class="routerbox">
          <router-link style="color: #0086fd;font-size: 14px;" to="invitation"
            >邀请码输入</router-link
          >
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="ordersonLoad"
        >
          <div v-for="(item, i) in orders" :key="i" style="margin-bottom:20px">
            <van-cell :title="item.name" :label="'编号：' + item.id">
              <div class="neirong3">
                <p>{{ item.dispatchStatus }}</p>
                <p>{{ item.createTime }}</p>
              </div>
            </van-cell>
            <van-row>
              <van-col span="12">
                <van-button
                  @click="jiedandel(item)"
                  style="color:#FF0000;border-right: 1px solid #F3F3F3;"
                  icon="delete"
                  type="primary"
                  >删除</van-button
                >
              </van-col>
              <van-col span="12">
                <van-button
                  @click="jiedanDetails(item)"
                  style="color:#0086FD"
                  icon="newspaper-o"
                  type="primary"
                  >查看</van-button
                >
              </van-col>
            </van-row>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
    <div style="margin: 16px;">
      <van-button class="tuichu" round block type="danger" @click="tuichu">
        退出登录
      </van-button>
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      qiyezhuangtaizhong: false,
      active: 2,
      list: [],
      loading: false,
      billingloading: false,
      finished: false,
      billingfinished: false,
      userData: {},
      details: {},
      RenZhen: "",
      RenZhenZhuangTai: "未认证",
      CaiJiYouRenJi: "未认证",
      CaiJiWuRenJi: "未认证",
      ChuLiZhuangTai: "未认证",
      bianji: true,
      userhead: require("../assets/img/touxiang.png"),
      billing: [],
      orders: [],
      reqParams: {
        function: "t_order,t_user",
        condition: "",
        column:
          "t_order.*,t_user.username,t_user.companyName,t_user.data userData"
      },
      ordersData: {
        function: "t_dispatch,t_order",
        column:
          "t_order.*,t_dispatch.id dispatchId,t_dispatch.status dispatchStatus,t_dispatch.quote_price price,t_dispatch.data dispatchData",
        // column:
        //   "t_order.*,t_dispatch.id dispatchId,t_dispatch.status dispatchStatus,t_dispatch.data dispatchData",
        condition: ""
      },
      update: {
        function: "t_order",
        ids: ""
      },
      update1: {
        function: "t_dispatch",
        ids: ""
      }
    };
  },
  //方法集合
  methods: {
    tuichu() {
      this.$dialog
        .confirm({
          title: "确认退出登录"
        })
        .then(async () => {
          // on confirm
          window.sessionStorage.removeItem("userId");
          // window.sessionStorage.removeItem("tempData");
          this.$router.push("/index");
        })
        .catch(() => {
          // on cancel
        });
    },
    jiedanDetails(data) {
      if (data.type == "数据处理服务") {
        this.$router.push({
          name: "ReceivingDealWith",
          params: {
            data: data
          }
        });
      }
      if (data.type == "数据采集服务") {
        this.$router.push({
          name: "ReceivingCollection",
          params: {
            data: data
          }
        });
      }
      if (data.type == "一站式") {
        this.$router.push({
          name: "ReceivingWholeoe",
          params: {
            data: data
          }
        });
      }
    },
    jiedandel(row) {
      if (row.dispatchStatus == "可接单") {
        return this.$toast.fail("订单当前状态不可删除如有问题联系13811918203");
      }
      if (row.dispatchStatus == "已报价") {
        return this.$toast.fail("订单当前状态不可删除如有问题联系13811918203");
      }
      if (row.dispatchStatus == "已接单") {
        return this.$toast.fail("订单当前状态不可删除如有问题联系13811918203");
      }
      this.$dialog
        .confirm({
          title: "确认要删除订单吗"
        })
        .then(async () => {
          // on confirm
          this.update1.ids = row.id;
          const { data: dt } = await this.$http.post(
            "general/delete",
            this.update1
          );
          if (dt.status !== 200) {
            return this.$toast.fail(dt.msg);
          }
          this.ordersonLoad();
        })
        .catch(() => {
          // on cancel
        });
    },
    fadandel(row) {
      if (row.status == "审核中") {
        return this.$toast("订单正在审核中如果想删除订单请联系13811918203");
      }
      if (row.status == "已接单") {
        return this.$toast("订单当前状态不可删除如有问题联系13811918203");
      }
      this.$dialog
        .confirm({
          title: "确认要删除订单吗"
        })
        .then(async () => {
          // on confirm
          this.update.ids = row.id;
          const { data: dt } = await this.$http.post(
            "general/delete",
            this.update
          );
          if (dt.status !== 200) {
            return this.$toast.fail(dt.msg);
          }
          this.billingonLoad();
        })
        .catch(() => {
          // on cancel
        });
    },
    fadanDetails(data) {
      if (data.type == "数据处理服务") {
        this.$router.push({
          path: "/withorderDetails",
          query: {
            userId: data.userId,
            id: data.id
          }
        });
      }
      if (data.type == "数据采集服务") {
        this.$router.push({
          path: "/collectionoederDetails",
          query: {
            userId: data.userId,
            id: data.id
          }
        });
      }
      if (data.type == "一站式") {
        this.$router.push({
          path: "/wholeoederDetails",
          query: {
            userId: data.userId,
            id: data.id
          }
        });
      }
    },
    async conter() {
      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };

      const { data: dt } = await this.$http.post(
        "user/getUserInfo",
        "",
        config
      );

      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      //   dt.data.data = JSON.parse(dt.data.data);
      this.userData = dt.data;
      this.details = JSON.parse(this.userData.data);

      if (this.details.userhead) {
        this.userhead =
          this.$http.defaults.baseURL + "file" + "/" + this.details.userhead;
      }
      if (this.userData.type == 0) {
        this.RenZhen = "个人账号";
      }
      if (this.userData.type == 1) {
        this.RenZhen = "企业用户";
      }
      if (this.userData.type == 6) {
        this.RenZhen = "数据采集合作伙伴";
      }
      if (this.userData.type == 5) {
        this.RenZhen = "一站式合作伙伴";
      }
      if (this.userData.type == 2) {
        this.RenZhen = "数据采集合作伙伴(无人机)";
      }
      if (this.userData.type == 3) {
        this.RenZhen = "数据处理合作伙伴";
      }
      if (this.userData.type == 4) {
        this.RenZhen = "数据采集合作伙伴(有人机)";
      }
      if (this.userData.type == 7) {
        this.RenZhen = "一站式合作伙伴";
      }
      if (this.userData.type == 9) {
        this.RenZhen = "一站式合作伙伴";
      }
      this.userData.auth.forEach(e => {
        if (e.type == 1) {
          if (e.status == 1) {
            this.qiyezhuangtaizhong = true;
            this.RenZhen = "企业用户（审核中）";
            this.RenZhenZhuangTai = "认证中";
          }
          if (e.status == 2) {
            this.qiyezhuangtaizhong = true;
            this.RenZhen = "企业用户（未通过）";
            this.RenZhenZhuangTai = "认证驳回";
          }
          if (e.status == 3) {
            this.RenZhenZhuangTai = "认证完成";
          }
        }
        if (e.type == 2) {
          if (e.status == 1) {
            this.CaiJiWuRenJi = "认证中";
          }
          if (e.status == 2) {
            this.CaiJiWuRenJi = "认证驳回";
          }
          if (e.status == 3) {
            this.CaiJiWuRenJi = "认证完成";
          }
        }
        if (e.type == 4) {
          if (e.status == 1) {
            this.CaiJiYouRenJi = "认证中";
          }
          if (e.status == 2) {
            this.CaiJiYouRenJi = "认证驳回";
          }
          if (e.status == 3) {
            this.CaiJiYouRenJi = "认证完成";
          }
        }

        if (e.type == 3) {
          if (e.status == 1) {
            this.ChuLiZhuangTai = "认证中";
          }
          if (e.status == 2) {
            this.ChuLiZhuangTai = "认证驳回";
          }
          if (e.status == 3) {
            this.ChuLiZhuangTai = "认证完成";
          }
        }
      });

      if (this.RenZhen == "数据采集合作伙伴") {
        this.CaiJiWuRenJi = "认证完成";
        this.CaiJiYouRenJi = "认证完成";
      }
    },
    chulirenzheng() {
      if (this.qiyezhuangtaizhong) {
        return this.$toast.fail("请先完成企业认证，通过后可认证合作伙伴");
      }
      if (this.ChuLiZhuangTai == "认证完成") {
        this.$router.push({ path: "/dataProcessing" });
      } else {
        this.$router.push({ path: "/dataProcessingmodify" });
      }
    },
    yourenjirenzheng() {
      if (this.qiyezhuangtaizhong) {
        return this.$toast.fail("请先完成企业认证，通过后可认证合作伙伴");
      }
      if (this.CaiJiYouRenJi == "认证完成") {
        this.$router.push({ path: "/dataAcquisitionManned" });
      } else {
        this.$router.push({ path: "/dataAcquisitionMannedmodify" });
      }
    },
    wurenjirenzheng() {
      if (this.qiyezhuangtaizhong) {
        return this.$toast.fail("请先完成企业认证，通过后可认证合作伙伴");
      }
      if (this.CaiJiWuRenJi == "认证完成") {
        this.$router.push({ path: "/dataAcquisitionDrone" });
      } else {
        this.$router.push({ path: "/DataAcquisitionDronemodify" });
      }
    },
    qiyerenzheng() {
      this.$router.push({ path: "/enterprise" });
    },
    async billingonLoad() {
      this.reqParams.condition = `t_order.userId=t_user.id and (t_order.type=1 or t_order.type=2 or t_order. type=3) and t_user.id=${window.sessionStorage.getItem(
        "userId"
      )} order by createTime desc`;
      const { data: dt } = await this.$http.post(
        "general/query",
        this.reqParams
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      // this.total = Math.ceil(dt.data.total / this.reqParams.pageSize);

      this.billing = dt.data;

      // 修改data中的表格数据  驱动视图改变

      // console.log(this.tableData);
      this.billing.forEach(e => {
        if (e.type == 1) {
          e.type = "数据采集服务";
        } else if (e.type == 2) {
          e.type = "数据处理服务";
        } else if (e.type == 3) {
          e.type = "一站式";
        }
        if (e.status == 1) {
          e.status = "审核中";
        } else if (e.status == 2) {
          e.status = "未通过";
        } else if (e.status == 3) {
          e.status = "审核中";
        } else if (e.status == 4) {
          e.status = "审核中";
        } else if (e.status == 5) {
          e.status = "已接单";
        }
        // if (e.status == 9) {
        //   this.tableData.splice(a, 1, this.tableData.pop());
        //   console.log(this.tableData);
        // }
        e.createTime = e.createTime.substring(0, 10);
      });
      // 加载状态结束
      this.billingloading = false;
      this.billingfinished = true;
    },
    async ordersonLoad() {
      this.ordersData.condition = `t_dispatch.orderId=t_order.id and t_dispatch.userId=${window.sessionStorage.getItem(
        "userId"
      )} order by createTime desc`;
      const { data: dt } = await this.$http.post(
        "general/query",
        this.ordersData
      );
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      // this.total = Math.ceil(dt.data.total / this.reqParams.pageSize);

      this.orders = dt.data;

      // 修改data中的表格数据  驱动视图改变

      // console.log(this.tableData);
      this.orders.forEach(e => {
        if (Date.parse(e.dispatchEndTime) < new Date()) {
          this.danqishijian = true;
        }
        if (e.type == 1) {
          e.type = "数据采集服务";
        } else if (e.type == 2) {
          e.type = "数据处理服务";
        } else if (e.type == 3) {
          e.type = "一站式";
        }
        if ("dispatchEndTime" in e) {
          var time = e.dispatchEndTime
            .replace(/-/g, "/")
            .substring(0, e.dispatchEndTime.length - 2);

          if (e.dispatchStatus == 1 || e.dispatchStatus == 6) {
            console.log(time);
            if (Date.parse(time) > new Date()) {
              e.dispatchAgain = true;
            }
          }
        }
        if (
          Date.parse(e.dispatchEndTime) < new Date() &&
          e.dispatchStatus == 1
        ) {
          e.dispatchStatus = "已取消";
        }
        if (e.dispatchStatus == 1) {
          e.dispatchStatus = "可接单";
        } else if (e.dispatchStatus == 2) {
          e.dispatchStatus = "已报价";
        } else if (e.dispatchStatus == 3) {
          e.dispatchStatus = "报价失败";
        } else if (e.dispatchStatus == 4) {
          e.dispatchStatus = "已接单";
        } else if (e.dispatchStatus == 5) {
          e.dispatchStatus = "已取消";
        } else if (e.dispatchStatus == 6) {
          e.dispatchStatus = "已报价";
        }

        e.createTime = e.createTime.substring(0, 10);
      });

      // console.log(this.orders);
      // 加载状态结束
      this.loading = false;
      this.finished = true;
    }
  },
  created() {
    this.conter();
    sessionStorage.removeItem("orderData");
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.head {
  height: 186px;
  background: url("../assets/img/79fbefe2f5e1166997aaab0db7fdcda.png") no-repeat;
  background-position: 0% 100%;
  background-size: 100% 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 100px;
  .user_box {
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 10px;
    padding: 40px 20px;
  }
  .img_box {
    width: 160px;
    height: 160px;
    box-shadow: 0px 0px 6px #0d84eb;

    // border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .nei1 {
    font-size: 0.8em;
    margin-bottom: 10px;

    color: #414141;
  }
  .nei2 {
    font-size: 24px;
    color: #999999;
  }
}
.van-tabs {
  z-index: 0;
}
/deep/ .van-tabs__nav--line {
  width: 50%;
  background-color: transparent;
}
.title {
  font-size: 26px;
  padding: 20px;
  margin-right: auto;
  font-weight: bold;
  color: #666666;
}
.renzhen {
  margin-left: 20px;
  width: 436px;
  background: url("../assets/img/qiyeren.png") no-repeat center;
  padding: 40px;
  background-size: 100% 100%;
  .title1 {
    color: #ffffff;
    font-weight: 600;
    font-size: 32px;
    margin-right: auto;
  }
  .title2 {
    color: #ffffff;
    font-size: 22px;
  }
  .title3 {
    padding-top: 20px;
    color: #ffffff;
    font-size: 24px;
    i {
      font-size: 24px;
    }
  }
}
.van-button {
  width: 100%;
  background-color: #fff;
  border: 0;
}
.box {
  min-height: 100vh;
  background: #f2f2f2;
  overflow: auto;
}
.renzhen_box {
  display: -webkit-box;
  white-space: nowrap;
  overflow: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  -webkit-overflow-scrolling: touch;
  text-align: justify;
  overflow-x: initial;
}
.tuichu {
  background-color: #ee0a24;
  border: 1px solid #ee0a24;
}
.routerbox {
  background-color: #fff;
  text-align: center;
  padding: 30px;
  margin-bottom: 20px;
}
</style>
