<template>
  <div class="box">
    <div class="title">
      <div class="tiao"></div>
      <p>泰瑞学院</p>
    </div>
    <van-tabs color="#0086FD">
      <van-tab
        v-for="(item, i) in dataList"
        :key="i"
        :title="item.data.biaoqian"
      >
        <van-cell
          v-for="(item, i) in item.data.course"
          :key="i"
          :title="item.kechengming"
        >
          <template #label>
            <a
              v-if="item.wenjianleixing == 'pdf'"
              :href="item.xiazai"
              target="_blank"
              type="primary"
              class="right yulan"
              >在线预览</a
            >
            <a
              v-if="item.wenjianleixing == '视频'"
              type="primary"
              :href="item.yulan"
              class="right yulan"
              >在线预览</a
            >
          </template>
          <div class="neirong3">
            <p>
              <span class="right">{{ item.zhuti }}</span>
              <i
                class="right"
                style="padding-left: 80px;box-sizing: border-box;"
                >{{ item.wenjianleixing == "pdf" ? "文档" : "视频" }}</i
              >
            </p>
            <p>
              <a
                type="primary"
                :href="item.xiazai"
                target="_blank"
                class="right xiazai"
                >资料下载</a
              >
            </p>
          </div>
        </van-cell>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      dialogVisible: false,
      list: { function: "t_common" },
      dataList: [],
      shipin: ""
    };
  },
  computed: {
    reversedMessage: function() {
      return this.username.replace(/(^\d{3}|\d{4}\B)/g, "$1-");
    }
  },
  //方法集合
  methods: {
    async huoqu() {
      const { data: dt } = await this.$http.post("/general/query", this.list);
      if (dt.status !== 200) {
        return this.$toast.fail(dt.msg);
      }
      dt.data.forEach((element, i) => {
        element.data = JSON.parse(element.data);

        dt.data[i] = element;
      });

      this.dataList = dt.data;
      this.dataList.forEach(v => {
        v.data.course.forEach((e, i) => {
          e = JSON.parse(e);
          v.data.course[i] = e;
          // console.log(e);
        });
      });
      // console.log(this.dataList);
    }
  },
  created() {
    this.huoqu();
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.box {
  background: #f2f2f2;
  padding-bottom: 50px;
  a {
    color: #0086fd;
  }
}
.title {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  font-size: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .tiao {
    margin-right: 20px;
    width: 8px;
    height: 32px;
    background: #0086fd;
  }
}
/deep/ .van-tabs__nav--line {
  width: 50%;
  background-color: transparent;
}
</style>
